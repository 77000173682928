import {
  IUserViewsHistory,
  IVideo,
  IVideoEmbed,
  IVideoEmbedViewsHistory
} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalClose from "../ModalClose";
import MonetizationHistoryGraph from "../../components/Aside/AsideMonetization/MonetizationHistoryGraph";
import {download, getEmbedName, thumb} from "../../modules/utils";
import {ReactSVG} from "react-svg";
import video_stub from "../../assets/images/icons/video_stub_2.svg";
import edit_svg from "../../assets/images/icons/edit.svg";
import download_svg from "../../assets/images/icons/download.svg";
import font_svg from "../../assets/images/icons/font.svg";
import {openDownloadVideoModal} from "./DownloadVideoModal";
import plus_svg from "../../assets/images/icons/add_white.svg";
import video_svg from "../../assets/images/icons/youtube.svg";
import dollar_svg from "../../assets/images/icons/dollar_.svg";
import {Regions2} from "../../modules/directory";
import {addVideoModal} from "./AddVideoToTrackModal";
import geos from "../../translates/en/geos.json";
import {openTracksVideosModal} from "./VideosModal/TracksVideosModal";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import Button from "../../components/Button";
import {Input} from "../../components/FormControls";
import ModalHeader from "../ModalHeader";

interface Props {
  history?: (IUserViewsHistory|IVideoEmbedViewsHistory)&{ max: number }
  ve: IVideoEmbed;

  resolve(needUpdate?: boolean): void;
}

const VideoMoreModal: FC<Props> = ({ve, history, resolve}) => {
  const {t} = useTranslation();
  const [needUpdate, setNeedUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<'info'|'edit'|'countries'>();
  const [name, setName] = useState(getEmbedName(ve));
  const [tempName, setTempName] = useState('');

  const handleClose = () => {
    resolve(needUpdate)
  }

  const handleOpenAddVideoModal = (e: any) => {
    e.stopPropagation();
    addVideoModal(ve).then((res) => {
      if (res) resolve(true);
    });
  }

  const handleOpenVideosModal = (e: any) => {
    e.stopPropagation();
    openTracksVideosModal(ve).then((res) => {
      if (res) resolve(!!res);
    });
  }

  const handleSubmitName = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    try {
      setLoading(true);
      await API.VideoEmbeds.renameVideoEmbed(ve.id, {name: tempName});
      setName(tempName);
      setStep(undefined)
      setNeedUpdate(true)
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }
  }

  //render
  if (step === 'edit') return (
    <>
      <ModalHeader onClick={() => setStep(undefined)} title='EDIT_NAME'/>

      <form onSubmit={handleSubmitName}>
          <Input
            className='sm'
            value={tempName}
            onChange={(e) => setTempName(e.target.value)}
          />
          <div className="modal-footer-btns">
            <Button
              text='CANCEL'
              onClick={() => setStep(undefined)}
              color='outline-primary'
            />
            <Button
              text='SAVE'
              type='submit'
              loading={loading}
            />
          </div>
      </form>
    </>
  )
  if (step === 'countries') return (
    <>
      <ModalHeader onClick={() => setStep(undefined)}>
        <h2 className='modal-title d-flex mb-0'>
          <ReactSVG src={dollar_svg} className='react-icon me-1'/>
          <span>{t('MONETIZED_COUNTRIES')}: {Regions2.length}</span>
        </h2>
      </ModalHeader>
      <div className='d-flex flex-wrap video-more-modal-history'>
        {Regions2.map(ln => (
          <div className="monetized-countries-list-item" key={ln}>
            <span>{ln.toUpperCase()}</span>
            <div className="monetized-countries-list-item-hidden">
              {/*@ts-ignore*/}
              {geos[ln.toUpperCase()]?.name || ln}
            </div>
          </div>
        ))}
      </div>
    </>

  )

  return (
    <div className='video-more-modal'>
      <div className='d-flex align-items-start'>
        <div className='flex-1 d-flex'>
          <div className='my-video-preview'>
            {ve.preview?.id
              ?
              <img src={thumb(ve.preview?.id!, 300)} alt='preview'/>
              :
              <ReactSVG src={video_stub} className='react-icon'/>
            }
          </div>
          <div className='ps-2'>
            <div className='d-grid overflow-hidden'>
              <div className='text-truncate text-14'>{name}</div>
              <div className='text-lowercase text-muted-14 text-nowrap'>{ve.videoCount} {t('VIDEO')}</div>
            </div>
          </div>
        </div>
        <ModalClose onClick={handleClose} className='modal-close-relative'/>
      </div>
      <MonetizationHistoryGraph history={history} className='mt-3 video-more-modal-history'/>
      <div className='modal-content-type-edit-small mt-3'>
        <div className='modal-content-type-edit-small-item' onClick={() => download(ve.video!, ve.video?.name)}>
          <ReactSVG src={download_svg} className='react-icon me-1'/>
          {t('DOWNLOAD')}
        </div>
        <div className='modal-content-type-edit-small-item' onClick={() => {
          setTempName(name)
          setStep('edit')}
        }>
          <ReactSVG src={edit_svg} className='react-icon me-1'/>
          <span>{t('EDIT')}</span>
        </div>
        <div className='modal-content-type-edit-small-item' onClick={() => {
          resolve();
          openDownloadVideoModal(ve)
        }}>
          <ReactSVG src={font_svg} className='react-icon me-1'/>
          <span>{t('GET_TEXT')}</span>
        </div>
        <div className='modal-content-type-edit-small-item' onClick={handleOpenAddVideoModal}>
          <ReactSVG src={plus_svg} className='react-icon me-1'/>
          {t('ADD_LINKS')}
        </div>
        <div className='modal-content-type-edit-small-item' onClick={handleOpenVideosModal}>
          <ReactSVG src={video_svg} className='react-icon me-1'/>
          <span className='text-lowercase'>{ve.videoCount} {t('VIDEO')}</span>
        </div>
        <div className='modal-content-type-edit-small-item' onClick={() => setStep('countries')}>
          <ReactSVG src={dollar_svg} className='react-icon me-1'/>
          <span>{t('MONETIZED_COUNTRIES')}: {Regions2.length}</span>
        </div>
      </div>
    </div>
  );
};

const openVideoMoreModal = (ve: IVideoEmbed, history?: (IUserViewsHistory|IVideoEmbedViewsHistory)&{
  max: number
},): Promise<boolean> => asyncModal(VideoMoreModal, {ve, history}, {
  showCloseIcon: false,
  blockScroll: false,
  center: true
});

export {openVideoMoreModal};
