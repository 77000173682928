import React, {useEffect} from 'react';
import {observer, useLocalObservable} from "mobx-react";
import {runInAction} from "mobx";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import billing_stub from "../../assets/images/icons/billing_stub.svg";
import BillingHistory from "./BillingHistory";
import BillingCashOut from "./BillingCashOut";
import {API} from "../../modules/api";
import {
  EFieldGroup,
  IGetTransactionsHistoryRequest,
  IGetWithdrawHistoryRequest,
  IPagedData,
  ITransaction,
  IWithdraw,
  IWithdrawMethodInfo
} from "../../modules/rest";
import {toast} from "react-toastify";
import BillingRequests from "./BillingRequests";
import payoneer_img from "./assets/payments/payoneer.png";
import master_img from "./assets/payments/master.png";
import tether_img from "./assets/payments/tether.png";

export const systemsLogo = {
  payoneer: payoneer_img,
  platron: master_img,
  usdt_trc20: tether_img,
}

interface State {
  loading: boolean;
  ready: boolean;
  tab: number;
  paymentsRequest: IGetWithdrawHistoryRequest
  historyRequest: IGetTransactionsHistoryRequest
  withdraw: IWithdrawMethodInfo[]
  history?: IPagedData<ITransaction>
  payments?: IPagedData<IWithdraw>
}

const BillingPage = observer(() => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    ready: true,
    loading: false,
    tab: 0,
    withdraw: [],
    historyRequest: {page: 1, limit: 10},
    paymentsRequest: {page: 1, limit: 10},
  }));

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const [withdraw] = await Promise.all([
        API.Withdraw.getMethods(),
        getWithdrawHistory(),
        getWithdrawPayments(),
      ]);
      runInAction(() => {
        st.withdraw = withdraw;
      });
    } catch (e: any) {
      toast.error(e, {toastId: 'billing'});
    } finally {
      runInAction(() => {
        st.loading = false;
        st.ready = true;
      });
    }
  }

  const getWithdrawHistory = async () => {
    try {
      const history = await API.Billing.getTransactionsHistory(st.historyRequest);
      runInAction(() => {
        st.history = history;
      });
    } catch (e: any) {
      toast.error(e, {toastId: 'billing'});
    }
  }
  const getWithdrawPayments = async () => {
    try {
      const payments = await API.Withdraw.getHistory(st.paymentsRequest, [EFieldGroup.WithdrawDetails]);
      runInAction(() => {
        st.payments = payments;
      });
    } catch (e: any) {
      toast.error(e, {toastId: 'billing'});
    }
  }


  return (
    <div className='billing-page'>
      <div className='page-header'>
        <h1 className='page-title'>{t('BILLING')}</h1>
      </div>
      {!st.ready
        ?
        <div className='d-flex flex-column align-items-center'>
          <ReactSVG src={billing_stub} className='react-icon'/>
          <h4 className='mt-4 mb-3 text-muted text-center'>{t('BILLING_EMPTY')}</h4>
        </div>
        :
        <>
          <BillingCashOut data={st.withdraw} fetch={getWithdrawPayments}/>
          <div className='billing-cards'>
            <BillingRequests
              pager={st.payments}
              fetch={(page) =>
                runInAction(() => {
                  if (page) st.paymentsRequest.page = page;
                  getWithdrawPayments();
                })
              }
            />
            <BillingHistory
              pager={st.history}
              onPageChange={(page) =>
                runInAction(() => {
                  st.historyRequest.page = page;
                  getWithdrawHistory();
                })
              }
            />

          </div>
          {/*<BillingTabs active={st.tab} onClick={(idx) => runInAction(() => (st.tab = idx))}/>*/}
        </>
      }
    </div>
  );
});

export default BillingPage;
