import React, {FC} from 'react';
import {Regions} from "../../modules/directory";
import geos from "../../translates/en/geos.json";
import {Select} from "../FormControls";
import {useTranslation} from "react-i18next";
import session from "../../modules/session";
import {EContentPlanVideoOrigin} from "../../modules/rest";

interface Props {
  loading: boolean;
  value: any;
  type: EContentPlanVideoOrigin;
  onChange: (value: any) => void;
}

const TrendsAsideSelect: FC<Props> = ({loading, value, type, onChange}) => {
  const {t} = useTranslation();

  if (type === EContentPlanVideoOrigin.Trending) return (
    <div className='trends-aside-select'>
      <Select
        value={value}
        placeholder={'SELECT'}
        onChange={(e) => onChange(e.target.value)}
        className='sm'>
        {Regions.map(ln => (
          <option
            value={ln}
            key={ln}
          >
            {/*@ts-ignore*/}
            {geos[ln.toUpperCase()]?.name || ln}</option>
        ))}
      </Select>
    </div>
  )
  return (
    <div className='trends-aside-select'>
      <Select
        value={value}
        placeholder={'SELECT'}
        onChange={(e) => onChange(e.target.value)}
        className='sm'>
        {session.categories.map(genre => (
          <option
            value={genre.id}
            key={genre.id}
          >{genre.name}</option>
        ))}
      </Select>
    </div>
  );
}

export default TrendsAsideSelect;