import React, {FC, useEffect, useState} from 'react';
import {ReactSVG} from "react-svg";
import monetization_svg from "../../../assets/images/icons/monetization.svg";
import reject from "../../../assets/images/icons/reject2.svg";
import pending from "../../../assets/images/icons/pending.svg";
import {useTranslation} from "react-i18next";
import {openActiveMonetizationModal} from "./AsideMonetizationModal";
import {EReviewStatus} from "../../../modules/rest";
import {formatNumber} from "../../../modules/utils";
import {API} from "../../../modules/api";
import MonetizationHistoryGraph from "./MonetizationHistoryGraph";
import {observer} from "mobx-react";
import session from "../../../modules/session";
import {Link} from "react-router-dom";
import {monetizationSectionPath} from "../../../routes/AppRouter";

interface Props {
  closeMenu: () => void;
}

const AsideMonetization: FC<Props> = observer(({closeMenu}) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);
  const [history, setHistory] = useState<any>();

  useEffect(() => {
    getHistory()
  }, [])

  const getHistory = async () => {
    try {
      const res = await API.Stats.getUserViewsHistory();
      const max = Math.max(...res.history.map((item) => item.value));
      setHistory({...res, max});
    } catch (e) {

    } finally {
    }
  }

  const toggleVisible = () => {
    setVisible(prevState => !prevState);
  }

  const activateMonetization = () => {
    //locationKey activateMonetization = 1
    openActiveMonetizationModal().then()
  }

  if (session.user?.flagYoutuberMonetizationEnabled) return (
    <div className={`aside-monetization_stat${visible ? ' visible' : ''}`}>
      <div className='d-flex'>
        <Link to={monetizationSectionPath} className="aside-monetization_stat-logo" onClick={closeMenu}>
          <span>{t('MONETIZE')}</span>
        </Link>
        <div className="aside-monetization_stat-right" onClick={toggleVisible}>
          <div className="aside-monetization_stat-right-content">
            <div className="aside-monetization_stat-right-item">
              <div className='text-bold-14'>{formatNumber(history?.views)}</div>
              <div className='text-muted-12'>{t('VIEWS')}</div>
            </div>
            <div className="aside-monetization_stat-right-item">
              <div className='text-bold-14'>${formatNumber(history?.balance,2)}</div>
              <div className='text-muted-12'>{t('BALANCE')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="aside-monetization_stat-hidden">
        <MonetizationHistoryGraph className='p-3' history={history}/>
        <div className="aside-monetization_stat-hidden-separator"/>
        <div className='p-3'>
          <h4>${formatNumber(history?.balance, 2)}</h4>
          <div className='text-muted-12'>{t('BALANCE')}</div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="aside-monetization">
      <div className="aside-monetization-content">
        <div className='aside-monetization-head'>
          <ReactSVG src={monetization_svg} className='react-icon'/>
          <div className='ps-2 text-tint'>{t('MONETIZE')}</div>
        </div>
        <div className='p-3 pt-2'>
          {/*{status === EReviewStatus.Review && (*/}
          {session.monetization?.status === EReviewStatus.Review && (
            <div className='d-flex align-items-center pt-2'>
              <ReactSVG src={pending} className='react-icon'/>
              <div className='text-bold text-12 ps-2'>{t('PENDING_REQUEST')}</div>
            </div>
          )}
          {session.monetization?.status === EReviewStatus.Reject && (
            <div className='pt-2'>
              <div className='d-flex align-items-center mb-2  text-medium text-12'>
                <ReactSVG src={reject} className='react-icon'/>
                <span className='ms-2 text-danger'>{t('REQUEST_REJECTED')}</span>
                <Link to={monetizationSectionPath} className='ms-1 text-tint-active' onClick={closeMenu}>{t('MORE')}</Link>
              </div>
              <div className='text-bold text-12 ps-2'>{t('REQUEST_REJECTED_TEXT')}</div>
              <button type='button' className='btn btn-outline btn-sm mt-3 w-100' onClick={activateMonetization}>
                {t('ACTIVATE_MONETIZATION')}
              </button>
            </div>
          )}
          {!session.monetization?.status && (
            <>
              <div>{t('MONETIZATION_TEXT')}</div>
              <button type='button' className='btn btn-outline btn-sm mt-3 w-100' onClick={activateMonetization}>
                {t('ACTIVATE_MONETIZATION')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
})

export default AsideMonetization;