import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import close from "../../../assets/images/icons/close_small.svg";

interface Props {
  len?: number;
  accent?: boolean;
  onClick: () => void;
}

const ReportsFiltersCount: FC<Props> = ({len, onClick, accent}) => {
  if (!len) return null;
  return (
    <div className={`report-filters-count${accent ? ' accent' : ''}`}>
      <span>{len}</span>
      <ReactSVG src={close} className='react-icon cursor-pointer' onClick={onClick}/>
    </div>
  );
}

export default ReportsFiltersCount;