import React, {FC} from 'react';
import {IResearchCountryReaction} from "../../modules/rest";
import BestReactionsEmptyList from "./BestReactionsEmptyList";
import BestReactionsListItem from "./BestReactionsListItem";
import BestReactionsEmptySkeleton from "./BestReactionsEmptySkeleton";

interface Props {
  data?: IResearchCountryReaction[];
  loading: boolean;
}

const BestReactionsList: FC<Props> = ({data, loading }) => {
  if (!data || loading) return <BestReactionsEmptySkeleton />;
  if (!data.length) return  <BestReactionsEmptyList/>
  return (
    <div className='best-reactions-modal-list'>
      {data.map((item, i) => (
        <BestReactionsListItem
          key={item.id}
          item={item}
        />
      ))}
    </div>
  );
}

export default BestReactionsList;