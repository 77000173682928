import React, {useCallback, useEffect} from 'react';
import './Notifications.scss';
import {observer, useLocalObservable} from 'mobx-react';
import {runInAction} from 'mobx';
import {toast} from 'react-toastify';
import {Icon} from './fontawesome';
import moment from 'moment';
import {thumb} from '../modules/utils';
import {INotification} from '../modules/rest';
import {API} from '../modules/api';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import OutsideClick from "./OutsideClick";
import logo from '../assets/images/favicon.png';
import tg_logo from '../assets/images/tg_logo.png';
import logo_white from '../assets/images/logo_white.png';
import bell_svg from '../assets/images/icons/bell.svg';
import TgBtn from "./TgBtn";
import session from "../modules/session";
import {ReactSVG} from "react-svg";

interface State {
  isOpened: boolean;
  notifications: INotification[];
  counter: number;
}

const Notifications = observer(() => {
  const {t} = useTranslation();
  const state = useLocalObservable<State>(() => ({
    isOpened: false,
    notifications: [],
    counter: 0,
  }));

  const navigate = useNavigate();

  const toggle = useCallback(() => {
    runInAction(() => (state.isOpened = !state.isOpened));
  }, [state.isOpened]);

  // const open = useCallback(() => {
  //   runInAction(() => (state.isOpened = true));
  // }, [state]);

  const close = useCallback(() => {
    runInAction(() => (state.isOpened = false));
  }, []);

  const fetchList = useCallback(() => {
    API.Notifications.getList()
      .then((notifications) =>
        runInAction(() => {
          state.notifications = notifications;
          state.counter = notifications.filter((n) => n.isNew).length
          // + (session.user?.telegramId ? 0 : 1);
        })
      )
      .catch(toast.error);
  }, []);

  const clickItem = useCallback((n: INotification) => (e: any) => {
      e.preventDefault();
      if (n.extra?.navigate) {
        navigate(n.extra?.navigate as string);
        close();
      }
      API.Notifications.read(n.id).then(fetchList).catch(toast.error);
    },
    [fetchList, close, navigate]
  );

  const deleteItem = useCallback(
    (n: INotification, e: any): boolean => {
      e.stopPropagation();
      API.Notifications.delete(n.id).then(fetchList).catch(toast.error);
      return false;
    },
    [fetchList]
  );

  // const readAll = useCallback(() => {
  //   API.Notifications.readAllNotifications().then(fetchList).catch(toast.error);
  //   close();
  // }, [fetchList, close]);

  const deleteAll = useCallback(() => {
    API.Notifications.deleteAll().then(fetchList).catch(toast.error);
    close();
  }, [fetchList, close]);

  useEffect(() => {
    fetchList();
    let interval = setInterval(fetchList, 10000);
    return () => clearInterval(interval);
  }, [fetchList]);

  return (
    <OutsideClick
      outsideClickEvent={() => runInAction(() => (state.isOpened = false))}
      className={`notifications header-popover ms-2${state.isOpened ? ' active' : ''}`}
    >
      <button
        className={`nav-btn notifications__bell notifications__bell-${state.counter > 0 ? 'active' : ''} `}
        onClick={toggle}
      >
        <ReactSVG src={bell_svg} className='react-icon' />
        {/*<Icon icon={['fas', 'bell']} fixedWidth/>*/}
        {state.counter > 0 && (
          <span className="notifications__bell__badge">{state.counter < 100 ? state.counter : 99}</span>
        )}
      </button>
      <div className="header-popover-content">
        <div className="notifications__container__header">
          <div className="title">{t('NOTIFICATIONS')}</div>
          {state.notifications.length > 0 && (
            <div className="delete-all" onClick={deleteAll}>
              {t('REMOVE_ALL')}
            </div>
          )}
        </div>

        <div className="notifications__container__items">
          {!session.user?.isBotConnected && (
            <div className="notifications-tg">
              <div className='d-flex justify-content-center align-items-center'>
                <img src={logo_white} alt="logo" className='notifications-tg-logo-reactrino'/>
                <span className='mx-2'>{t('IN')}</span>
                <img src={tg_logo} alt="tg" className='notifications-tg-logo-tg'/>
              </div>
              <TgBtn primary/>
            </div>
          )}
          {state.notifications.map((n) => (
            <div
              className={`notifications__container__items__item ${n.isNew ? 'new' : ''}`}
              key={n.id}
              onClick={clickItem(n)}
            >
              <div className='d-flex'>
                {n.icon
                  ?
                  <div className="icon">
                    <img alt="cover" src={thumb(n.icon.id, 80)}/>
                  </div>
                  :
                  <div className="icon icon-stub">
                    <img alt="logo" src={logo}/>
                  </div>
                }

                <div className="text">
                  <div className="message">{n.message}</div>
                  <div className="date">{moment(n.createdAt).from(moment())}</div>
                </div>

                <button className="delete-button" onClick={(e) => deleteItem(n, e)}>
                  <Icon icon={['fas', 'times']} fixedWidth/>
                </button>
              </div>

              {n.image
                ?
                <img src={thumb(n.image.id, 300)} alt="img" className='notifications__item-image' onClick={e => {
                  if (n.url) window.open(n.url)
                }}/>
                :
                null
              }
            </div>
          ))}
        </div>

      </div>

    </OutsideClick>
  );
});

export default Notifications;
