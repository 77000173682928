import React, {FC, useState} from 'react';
import ContentPlanItem from "./ContentPlanItem";
import {EContentPlanVideoStatus, IContentPlanVideo} from "../../modules/rest";
import Loadable from "../../components/Loadable";

interface Props {
  item: IContentPlanVideo;
  loading: boolean;
  onStatusChange: (status: EContentPlanVideoStatus) => void;
  onRemove: () => void;
  onEdit: () => void;
}

const ContentPlanListItem: FC<Props> = ({
                                          item,
                                          // loading,
                                          ...methods
                                        }) => {

  const [loading, setLoading] = useState(false);

  const handleEdit = async () => {
    setLoading(true);
    await methods.onEdit();
    setLoading(false);
  }
  const handleRemove = async () => {
    setLoading(true);
    await methods.onRemove();
    setLoading(false);
  }
  const handleStatusChange = async (status: EContentPlanVideoStatus) => {
    setLoading(true);
    await methods.onStatusChange(status);
    setLoading(false);
  }

  return (
    <Loadable loading={loading} className='content-plan-line' key={item.id}>
      <ContentPlanItem
        defaultStatus={EContentPlanVideoStatus.VideoPending}
        item={item}
        placeholder={'FILM_VIDEO'}
        onEdit={handleEdit}
        onRemove={handleRemove}
        onStatusChange={handleStatusChange}
      />
      <ContentPlanItem
        defaultStatus={EContentPlanVideoStatus.VideoReady}
        item={item}
        placeholder={'VIDEO_TAKEN'}
        onEdit={handleEdit}
        onRemove={handleRemove}
        onStatusChange={handleStatusChange}
      />
      <ContentPlanItem
        defaultStatus={EContentPlanVideoStatus.VideoUploaded}
        item={item}
        placeholder={'UPLOADED'}
        onEdit={handleEdit}
        onRemove={handleRemove}
        onStatusChange={handleStatusChange}
      />
    </Loadable>
  );
}

export default ContentPlanListItem;