import React, {FC} from 'react';
import {IContentPlanVideo} from "../../modules/rest";
import {thumb} from "../../modules/utils";

interface Props {
  item: IContentPlanVideo;
}

const ContentPlanItemBody: FC<Props> = ({item}) => {
  if(item.origin === 'comments') return (
    <div className='comment-body'>
      <div className='text-bold-14'>{item.title}</div>
      <div className='text-muted-14'>{item.subtitle}</div>
    </div>
  )
  return (
    <>
      <a href={item.originVideoUrl || ''} target='_blank' className='d-block'>
        <img src={thumb(item.icon?.id!, 180)} alt="img" className='content-plan-item-img'/>
      </a>
      <div className='text-bold-14 text-muted text-truncate-2'>{item.title}</div>
    </>
  );
}

export default ContentPlanItemBody;