import asyncModal from 'react-async-modal';
import React, {FC, useState} from 'react';
import {IGetYoutuberStatsRequest} from "../../modules/rest";
import ReportsFilters from "../../routes/report/ReportsFilters";
import Button from "../../components/Button";

interface Props {
  request: IGetYoutuberStatsRequest;

  resolve(request?: IGetYoutuberStatsRequest): void;
}

const ReportFilterModal: FC<Props> = ({request, resolve}) => {
  const [tempRequest, setTempRequest] = useState<IGetYoutuberStatsRequest>(request);

  return (
    <div className='report-filter-modal'>
      {/*<ModalHeader onClick={() => resolve()}>*/}
      {/*  <ReactSVG src={filter_svg} className='react-icon me-2'/>*/}
      {/*  <h2>{t('FILTER')}</h2>*/}
      {/*</ModalHeader>*/}
      {tempRequest
        ?
        <ReportsFilters
          visible
          request={tempRequest}
          updateState={(request) => setTempRequest(prevState => ({...prevState, ...request}))}
        />
        :
        null
      }
      <div className="modal-footer-btns">
        <Button
          text='CANCEL'
          onClick={() => resolve()}
          color='outline-primary'
        />
        <Button
          text='APPLY'
          onClick={() => resolve(tempRequest)}
        />
      </div>
    </div>
  );
};

const openReportFilterModal = (request: IGetYoutuberStatsRequest): Promise<IGetYoutuberStatsRequest> => asyncModal(ReportFilterModal, {request}, {
  showCloseIcon: false,
  blockScroll: false,
  classNames: {modalContainer: 'bottom-modal'},
  center: true
});

export {openReportFilterModal};
