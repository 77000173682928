import React, {FC, useEffect, useRef} from 'react';
import {
  EContentPlanVideoOrigin,
  EFieldGroup,
  IGetMonitoringChannelsRequest,
  IPagedData, IReactorComment
} from "../../../modules/rest";
import {API} from "../../../modules/api";
import {openComposeChannelToContentPlanModal} from "../../../modals";
import {observer, useLocalObservable} from "mobx-react";
import {runInAction} from "mobx";
import session from "../../../modules/session";
import ListItemComments from "./ListItemComments";
import ContentTypeWrapper from "../ContentTypeWrapper";
import {toast} from "react-toastify";
import {ReactSVG} from "react-svg";
import refresh from "../../../assets/images/icons/refresh.svg";
import comment_stub from '../assets/images/comment_stub.svg';
import {useTranslation} from "react-i18next";
import CommentsUpdateStatus from "./CommentsUpdateStatus";


interface Props {
  onChangeActiveContentType?: () => void;
  updateContentPlan?: () => void;
  className?: string;
  active?: boolean;
  aside?: boolean;
  seeAll?: boolean;
  onHeaderClick?: () => void;
}

interface State {
  request: IGetMonitoringChannelsRequest;
  pager?: IPagedData<IReactorComment>;
  ready: boolean;
  pendingUpdate: boolean;
  refreshEnabled?: boolean;
  updatedAt: string|null;
}

const ContentTypeComments: FC<Props> = observer(({
                                                   className,
                                                   active,
                                                   onChangeActiveContentType,
                                                   updateContentPlan,
                                                   aside,
                                                   seeAll,
                                                   onHeaderClick,
                                                 }) => {
  const {t} = useTranslation();
  const _ping: any = useRef(null);
  const st = useLocalObservable<State>(() => ({
    ready: false,
    pendingUpdate: false,
    updatedAt: null,
    request: {
      search: '',
      page: 1,
      limit: 10,
    },
  }));

  const channelId = session.activeChannel?.id;

  useEffect(() => {
    if (channelId) fetch()
    return () => {
      clearPing();
    }
  }, [channelId]);

  const fetch = async () => {
    try {
      const res = await API.ReactorChannels.getComments(channelId!, st.request, [EFieldGroup.MonitoringChannel, EFieldGroup.MonitoringCategories]);
      runInAction(() => {
        st.pager = res.pager;
        st.pendingUpdate = res.pendingUpdate;
        st.refreshEnabled = res.refreshEnabled;
        st.updatedAt = res.updatedAt;
      })
      if (res.pendingUpdate) {
        _ping.current = setTimeout(fetch, 3000)
      } else {
        clearPing();
      }
    } catch (e: any) {
      clearPing();
    } finally {
      runInAction(() => {
        st.ready = true;
      })
    }
  }

  const clearPing = () => {
    clearTimeout(_ping.current);
  }

  const handleAddToContentPlan = (item: IReactorComment) => () => {
    openComposeChannelToContentPlanModal(item, EContentPlanVideoOrigin.Comments).then((res) => {
      if (res && updateContentPlan) updateContentPlan();
    })
  }

  const handleUpdate = async () => {
    if (st.pendingUpdate) return;
    await API.ReactorChannels.refreshComments(channelId!);
    runInAction(() => {
      st.pendingUpdate = true;
      _ping.current = setTimeout(fetch, 3000);
    })
  }

  const handleRemoveComment = (id: number) => async () => {
    try {
      await API.ReactorChannels.deleteComment(channelId!, id);
      fetch();
    } catch (e: any) {
      toast.error(e);
    }
  }

  return (
    <>
      <ContentTypeWrapper
        onHeaderClick={onHeaderClick}
        seeAll={seeAll}
        active={active}
        className={className}
        type={EContentPlanVideoOrigin.Comments}
        onChangeActiveContentType={onChangeActiveContentType}
        loading={!st.ready}
        commentHeadComponent={<CommentsUpdateStatus
          onClick={handleUpdate}
          ready={st.ready}
          pendingUpdate={st.pendingUpdate}
          refreshEnabled={st.refreshEnabled}
          updatedAt={st.updatedAt}
        />}
        onPageChange={(page: number) => runInAction(() => {
          st.request.page = page;
          fetch();
        })}
        onLimitChange={(limit: number) => runInAction(() => {
          st.request.page = 1;
          st.request.limit = limit;
          fetch();
        })}
        pager={st.pager}
      >
        {st.pager?.data?.length
          ? st.pager.data.map((item, i) => (
            <ListItemComments
              idx={(st.pager?.page! - 1) * st.pager?.limit! + i + 1}
              key={item.id}
              item={item}
              onAddToContentPlan={handleAddToContentPlan}
              onRemoveComment={handleRemoveComment(item.id)}
            />
          ))
          :
          st.ready
            ?
            <div className='content-type-empty-list'>
              <ReactSVG src={comment_stub}/>
              <div className='text-muted-14 mt-2'>{t('COMMENTS_EMPTY')}</div>
            </div>
            :
            null
        }
      </ContentTypeWrapper>
      {aside &&
        <div className='trends-aside-wrap'>
          <div className='trends-aside'>
            <div className={`trends-aside-head`}>
              <ReactSVG src={refresh} className='react-icon'/>
              <h4>{t(`UPDATE`)}</h4>
            </div>
            <div className='p-2'>
              <CommentsUpdateStatus
                aside
                ready={st.ready}
                pendingUpdate={st.pendingUpdate}
                updatedAt={st.updatedAt}
              />

              <div className='text-muted-14 pt-2 mt-1 text-center'>{t('UPDATE_ONCE_DAY')}</div>
              <div className='p-3 mt-2'>
                <button
                  type='button'
                  className='btn btn-outline-primary btn-sm w-100'
                  onClick={handleUpdate}
                  disabled={st.pendingUpdate || !st.refreshEnabled}
                >
                  {t('UPDATE')}
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
})

export default ContentTypeComments;