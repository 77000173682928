import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import arrow from './assets/icons/arrow.svg';
import {IWithdrawMethodInfo} from "../../modules/rest";
import {systemsLogo} from "./BillingPage";


interface Props {
  onClick: () => void;
  item: IWithdrawMethodInfo;
}

const BillingCashOutItem: FC<Props> = ({item, onClick}) => {


  return (
    <div className='billing-cashout-item-wrap'>
      <div className='billing-cashout-item' onClick={onClick}>
        <h4>{item.name}</h4>
        {/*<div className='text-muted-14'>{text}</div>*/}
        <div className="billing-cashout-item-separator">
          <ReactSVG src={arrow} className='react-icon'/>
        </div>
        <div className='billing-cashout-item-systems'>
          <img src={systemsLogo[item.method]} alt="system"/>
          {/*{payments?.map(system => <img src={systems[system]} alt="system" key={system}/>)}*/}
        </div>
      </div>
    </div>
  );
}

export default BillingCashOutItem;