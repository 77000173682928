import React, {FC} from 'react';
import moment from "moment";
import {useTranslation} from "react-i18next";
import {EContentPlanVideoOrigin, IMonitoringVideo,   ITrendingItem} from "../../modules/rest";
import {formatViews} from "../../modules/utils";

interface Props {
  video: IMonitoringVideo|ITrendingItem;
  videoType: EContentPlanVideoOrigin;
}

const BestReactionsHeader: FC<Props> = ({video, videoType}) => {
  const {t} = useTranslation();

  return (
    <div className='best-reactions-modal-head-wrap '>
      <div className='text-bold-24 text-nowrap'>{t('BEST_REACTION')}</div>
      <div className={`best-reactions-modal-head ${videoType}`}>
        <div className='d-grid overflow-hidden'>
          <a href={video.video?.url} target='_blank' className='text-medium-14 text-truncate text-muted'>{video.video?.title}</a>
        </div>
        <div className='text-muted-12 d-flex align-items-center text-nowrap text-truncate'>
          <div className='text-lowercase'>{formatViews(video.video?.viewCount!)} {t('VIEWS')}</div>
          <div className="best-reactions-modal-item-separator"/>
          <div className='text-lowercase'>{moment(video.video.publishedAt).calendar()}</div>
        </div>
      </div>
    </div>
  );
}

export default BestReactionsHeader;