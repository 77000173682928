import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ECountry, IVideoEmbed} from "../../modules/rest";
import geos from "../../translates/en/geos.json";
import {Regions2} from "../../modules/directory";
import CloseBtn from "../../components/CloseBtn";

interface Props {
  countries: ECountry[];
  className?: string;
  itemHover: boolean;
}

const VideosListItemMonetizedCountries: FC<Props> = ({className, countries, itemHover}) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if(!itemHover) setVisible(false)
  }, [itemHover]);

  const toggleOpen = () => {
    setVisible(prevState => !prevState)
  }

  if (!countries.length) return null;
  return (
    <div
      className={`monetized-countries${visible ? ' visible' : ''} ${className || ''}`}
      onClick={(e) => e.stopPropagation()}
      onBlur={() => setVisible(false)}
    >
      <div className='text-success text-14' onClick={toggleOpen}>$ {t('MONETIZED_COUNTRIES')}: {Regions2.length}</div>
      <div className='monetized-countries-hidden-wrap'>
        {visible &&
        <div className='monetized-countries-hidden'>
          <h4 className='pt-3 pb-2 px-4'>{t('MONETIZED_COUNTRIES')}</h4>
          <CloseBtn onClick={() => setVisible(false)}/>
          <div className='monetized-countries-list-wrap'>
            <div className='monetized-countries-list'>
              {Regions2.map(ln => (
                <div className="monetized-countries-list-item" key={ln}>
                  <span>{ln.toUpperCase()}</span>
                  <div className="monetized-countries-list-item-hidden">
                    {/*@ts-ignore*/}
                    {geos[ln.toUpperCase()]?.name || ln}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
}

export default VideosListItemMonetizedCountries;