import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import info from '../assets/images/icons/info.svg';
import {useTranslation} from "react-i18next";

interface Props {
  title?: string;
  text: string;
  className?: string;
}

const ImportantBlock: FC<Props> = ({title, text, className}) => {
  const {t} = useTranslation();
  return (
    <div className={`important-block ${className || ''}`}>
      <div className='d-flex align-items-center mb-2'>
        <ReactSVG src={info} />
        <h4 className='text-tint ps-2 ms-1'>{t(title || 'IMPORTANT')}</h4>
      </div>
      <div className='text-14 text-muted'>{t(text)}</div>
    </div>
  );
}

export default ImportantBlock;