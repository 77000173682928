import React, {FC} from 'react';
import asyncModal from "react-async-modal";
import {ModalClose} from "../../modals";
import {useTranslation} from "react-i18next";
import {TSection} from "../../routes/AppRouter";
import check_circle from '../../assets/images/icons/check_circle.svg';
import {ReactSVG} from "react-svg";
import ModalHeader from "../../modals/ModalHeader";

interface Props {
  resolve(res?: TSection): void;

  sectionName: TSection
}

const SectionsNavModal: FC<Props> = ({resolve, sectionName}) => {
  const {t} = useTranslation();
  return (
    <>
      <ModalHeader onClick={() => resolve()} title={'SECTIONS'} />
      <div className='modal-content-type-edit-small'>
        <div className='modal-content-type-edit-small-item justify-content-between' onClick={() => resolve('scanner')}>
          <span className='ps-1'>{t('SCANNER')}</span>
          {sectionName === 'scanner' && <ReactSVG src={check_circle} className='react-icon' /> }
        </div>
        <div className='modal-content-type-edit-small-item justify-content-between' onClick={() => resolve('monetization')}>
          <span className='ps-1'>{t('MONETIZE')}</span>
          {sectionName === 'monetization' && <ReactSVG src={check_circle} className='react-icon' /> }
        </div>
      </div>
    </>
  );
}

const openSectionsNavModal = (sectionName?: TSection) =>
  asyncModal(SectionsNavModal, {sectionName}, {
    classNames: {modalContainer: 'bottom-modal'},
    blockScroll: false,
    showCloseIcon: false,
    center: true,
    // closeOnOverlayClick: true,
  });

export {openSectionsNavModal};