import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import ContentTypeHeaderControls from "./ContentTypeHeaderControls";
import ContentTypeTags from "./ContentTypeTags";
import Loader from "../Loader";
import {EContentPlanVideoOrigin} from "../../modules/rest";

interface Props {
  type: EContentPlanVideoOrigin;
  onChangeActiveContentType?: () => void;
  commentHeadComponent?: any;
  maxTagsLength?: number;
  onHeaderClick?: () => void;
}


const ContentTypeHeader: FC<Props> = ({
                                        type,
                                        onChangeActiveContentType,
                                        commentHeadComponent,
                                        maxTagsLength,
                                        onHeaderClick,
                                      }) => {
  const {t} = useTranslation();

  return (
    <div className='content-type-header' onClick={onHeaderClick}>
      <div className='d-flex align-items-center'>
        <div className='d-flex align-items-center flex-grow-1 ' >
          <div className="content-type-header-title">
            {t(type.toUpperCase())}
          </div>
          {commentHeadComponent ? commentHeadComponent : null}
          <ContentTypeTags type={type} maxLength={maxTagsLength}/>
        </div>
        <ContentTypeHeaderControls
          type={type}
          onChangeActiveContentType={onChangeActiveContentType}
        />
      </div>
      <div className="content-type-header-text text-muted-14">
        {t(type.toUpperCase() + '_TEXT')}
      </div>
    </div>
  );
}

export default ContentTypeHeader;