import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {ContentPlan} from "../contentPlan";
import session from "../../modules/session";
import {openComposeChannelToContentPlanModal} from "../../modals";
import {useNavigate} from "react-router-dom";
import DashboardContent from "./DashboardContent";
import useFetchContentPlan from "../contentPlan/useFetchContentPlan";

export type TContentPosition = 'slider'|'accordion';

interface Props {
}


const DashboardPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [contentPosition, setContentPosition] = useState<TContentPosition>('slider');

  const {ready, loading, contentPlan, contentPlanSmall, getContentPlan} = useFetchContentPlan({
    channelId: session.activeChannel?.id!,
  })


  const handleAdd = () => {
    openComposeChannelToContentPlanModal().then((success) => {
      if (success) getContentPlan();
    });
  }

  return (
    <div className='dashboard'>
      <div className="dashboard-videos">
        <div className='page-header flex-column align-items-start'>
          <div className='d-flex align-items-start justify-content-between w-100'>
            <h1 className='page-title'>{t('DASHBOARD_TITLE')}</h1>
            <div className='dashboard-menu'>
              {['slider', 'accordion'].map(item => (
                <div
                  key={item}
                  onClick={() => setContentPosition(item as TContentPosition)}
                  className={`dashboard-menu-item ${item}${contentPosition === item ? ' active' : ''}`}
                />
              ))}
            </div>
          </div>
          <div className='text-muted mt-1'>{t('DASHBOARD_TEXT')}</div>
        </div>
        <DashboardContent getContentPlan={getContentPlan} contentPosition={contentPosition}/>
      </div>
      <div className='dashboard-content-plan'>
        <div className='d-flex align-items-center justify-content-between'>
          <h1>{t('CONTENT_PLAN')}</h1>
          <div className='content-plan-add-text' onClick={handleAdd}>
            {t('ADD')}
          </div>
        </div>
        <div className='text-muted mt-1'>{t('CONTENT_PLAN_TEXT')}</div>
        <div className='content-plan-header'>
          <div className="content-plan-header-tabs-wrapper">
            <div className='content-plan-header-tabs'>
              <div className="my-tabs-item-wrap">
                <div className={`my-tabs-item active`}>{t('PLANNED')}</div>
              </div>
              <div className="my-tabs-item-wrap">
                <div className={`my-tabs-item`} onClick={() => navigate('/content-plan/archive')}>{t('ARCHIVE')}</div>
              </div>
            </div>
          </div>
          <button className='btn btn-outline-primary btn-lg btn-add' onClick={handleAdd}>
            {t('ADD')}
          </button>
        </div>
        <ContentPlan
          dashboard
          ready={ready}
          data={contentPlan}
          smallData={contentPlanSmall}
          fetch={getContentPlan}
        />
      </div>
    </div>
  );
})

export default DashboardPage;