import {EFieldGroup, IGetVideoRequest, IPagedData, ITrack, IVideo, IVideoEmbed} from '../../../modules/rest';
import React, {FC, useCallback, useEffect, useRef} from 'react';
import Loadable from '../../../components/Loadable';
import {API} from '../../../modules/api';
import {toast} from 'react-toastify';
import asyncModal from 'react-async-modal';
import {useTranslation} from "react-i18next";
import {addVideoModal} from "../AddVideoToTrackModal";
import {runInAction} from "mobx";
import {observer, useLocalObservable} from "mobx-react";
import {SearchInput} from "../../../components/FormControls";
import Pagination from "../../../components/Pagination";
import Empty from "../../../components/Empty";
import TrackVideoItem from "./TrackVideoItem";
import ModalClose from "../../ModalClose";
import {ReactSVG} from "react-svg";
import views_svg from "../../../assets/images/icons/views_big.svg";
import youtube_svg from "../../../assets/images/icons/youtube_big.svg";
import plus_svg from "../../../assets/images/icons/plus_black.svg";
import {formatViews, getEmbedName} from "../../../modules/utils";
import {confirmDialog} from "../../../modules/confirm";
import trash_svg from "../../../assets/images/icons/trash.svg";
import Video from "../../../components/Video";

interface Props {
  ve: IVideoEmbed;
  free?: boolean;

  resolve(): void;
}

interface State {
  videos: IVideo[];
  selectedVideosIds: number[];
  loading: boolean;
  trackPlaying?: boolean;
  request: IGetVideoRequest;
  ve: IVideoEmbed;
  pager?: IPagedData<IVideo>;
}

const TracksVideosModal: FC<Props> = ({ve, resolve}) => {
  const videoRef: any = useRef(null);
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    loading: false,
    query: '',
    ve: ve,
    videos: [],
    selectedVideosIds: [],
    request: {
      query: '',
      page: 1,
      limit: 6,
    },
  }));

  const fetch = useCallback(async () => {
    runInAction(() => (st.loading = true));
    try {
      const [pager, resVe] = await Promise.all([
        API.VideoEmbeds.getYoutubeVideos(ve.id, st.request),
        API.VideoEmbeds.getVideoEmbed(ve.id, [EFieldGroup.VideoEmbedStatus, EFieldGroup.VideoEmbedStats])
      ]);
      runInAction(() => {
        st.pager = pager;
        st.request.page = pager.page;
        st.request.limit = pager.limit;
        st.videos = pager.data;
        st.ve = resVe;
      })
    } catch (e: any) {
      toast.error(e)
    } finally {
      runInAction(() => (st.loading = false))
    }
  }, [st, ve?.id]);

  useEffect(() => {
    if (ve?.id) fetch();
  }, [])

  const handleSelectVideo = useCallback((id: number) => {
    runInAction(() => {
      if (st.selectedVideosIds.includes(id)) {
        st.selectedVideosIds = st.selectedVideosIds.filter(v => v !== id);
      } else {
        st.selectedVideosIds = [...st.selectedVideosIds, id];
      }
    })
  }, [st.selectedVideosIds]);

  const handleSelectAllVideo = useCallback(() => {
    runInAction(() => {
      const videosIds = st.videos.map(v => v.id);
      st.selectedVideosIds = videosIds;
    })
  }, [st.videos, st.selectedVideosIds]);

  const openAllStudio = useCallback(() => {
    st.videos.forEach(video => {
      if (st.selectedVideosIds.includes(video.id)) {
        window.open(`https://studio.youtube.com/video/${video.extId}/edit`, '_blank')
      }
    })
  }, [st.videos, st.selectedVideosIds]);

  const cutAllTracks = useCallback(() => {
    confirmDialog(t('CUT_TRACK_CONFIRM_SELECTED'), {
      title: t('CUTTING_TRACKS') || '',
      danger: true,
      confirmText: t('TRACK_CUT') || '',
    }).then(async (confirmed) => {
      if (!confirmed) return;
      st.loading = true;
      try {
        await API.VideoEmbeds.bulkOperation({operation: 'cut', ids: st.selectedVideosIds});
        toast.success(t('TRACKS_CUT_SUCCESS'));
      } catch (e: any) {
        toast.error(e);
      } finally {
        fetch()
        st.loading = false;
      }
    });
  }, [st.videos, st.selectedVideosIds]);

  const deleteAllVideos = useCallback(() => {
    confirmDialog(t('DELETE_CLIPS_CONFIRM_TITLE'), {
      title: t('DELETING_CLIPS') || '',
      danger: true,
      confirmText: t('REMOVE') || '',
    }).then(async (confirmed) => {
      if (!confirmed) return;
      st.loading = true;
      try {
        await API.VideoEmbeds.bulkOperation({operation: 'delete', ids: st.selectedVideosIds});
        toast.success(t('CLIPS_REMOVED'));
      } catch (e: any) {
        toast.error(e);
      } finally {
        fetch();
        st.loading = false;
      }
    });
  }, [st.videos, st.selectedVideosIds, fetch]);


  if (!ve) return null;

  return (
    <>
      <Loadable loading={st.loading} className='modal-without-padding video-modal'>
        <div className='videos-modal-header-title-small'>
          <div className='d-flex flex-1 align-items-center'>
            <div className='d-grid overflow-hidden'>
              <div className='text-bold text-truncate-2 word-break'>{getEmbedName(ve)}</div>
            </div>
            <div className='videos-modal-header-title-small-item'>
              <ReactSVG src={youtube_svg} className='react-icon'/>
              <span className='ps-1 text-lowercase text-nowrap'>{st.ve.videoCount} {t('VIDEO')}</span>
            </div>
            <div className='videos-modal-header-title-small-item'>
              <ReactSVG src={views_svg} className='react-icon'/>
              <span className='ps-1'>{formatViews(st.ve.views!)}</span>
            </div>
          </div>
          <ModalClose onClick={resolve} className='modal-close-relative'/>
        </div>
        <div className='videos-modal-header'>
          <Video
            local
            ref={videoRef}
            className='w-auto'
            ve={ve}
          />
          <div className='flex-grow-1 w-100'>
            <div className='videos-modal-header-title'>
              <ModalClose onClick={resolve}/>
              <div className='d-flex align-items-center'>
                <ReactSVG src={youtube_svg} className='react-icon'/>
                <span className='ps-2 text-lowercase'>{st.ve.videoCount} {t('VIDEO')}</span>
              </div>
              <div className='d-flex align-items-center mt-2'>
                <ReactSVG src={views_svg} className='react-icon'/>
                <span className='ps-2'>{formatViews(st.ve.views!)}</span>
              </div>
            </div>
            <div className='d-flex mt-3'>
              <button onClick={() => addVideoModal(ve).then(fetch)}
                      className="btn btn-primary btn-lg pe-12 videos-modal-add-btn">
                {t('ADD_VIDEO')}
                <ReactSVG src={plus_svg} className='react-icon'/>
              </button>
              {!st.request.query && !st.videos.length
                ?
                null
                : <SearchInput
                  inputClassName={'w-100'}
                  className='flex-grow-1'
                  small
                  onStartTyping={() => {
                    runInAction(() => {
                      st.loading = true;
                    });
                  }}
                  value={st.request.query || ''}
                  onSearch={(value) => {
                    runInAction(() => {
                      st.request.page = 1;
                      st.request.query = value;
                      fetch();
                    });
                  }}
                />
              }
            </div>
          </div>
        </div>
        {st.videos.length > 0 && (
          <>
            <div style={{height: 62}}
                 className=' px-12 d-flex flex-column justify-content-end my__tracks__modal__video__head'>
              {st.selectedVideosIds?.length
                ?
                <div className='d-flex justify-content-between text-14 align-items-center mt-2'>
                  <div className='d-flex align-items-end'>
                    <div className='text-muted'>
                      {t('SELECTED')}: <span className='d-inline-block'
                                             style={{minWidth: 15}}> {st.selectedVideosIds?.length}</span>
                    </div>
                    <div className='cursor-pointer text-tint' onClick={handleSelectAllVideo}>{t('SELECT_ALL')}</div>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='cursor-pointer text-tint' onClick={() => runInAction(() => {
                      st.selectedVideosIds = [];
                    })}>
                      {t('DESELECTED')}
                    </div>
                    {/*<button className='btn btn-third btn-sm p-0 ms-3' onClick={cutAllTracks}>*/}
                    {/*  <ReactSVG src={cut_svg} className='react-icon'/>*/}
                    {/*</button>*/}
                    <button className='btn btn-danger btn-sm ms-3 p-0' onClick={deleteAllVideos}>
                      <ReactSVG src={trash_svg} className='react-icon'/>
                    </button>
                  </div>

                </div>
                :
                <div className='d-flex text-muted-14 ps-2'>
                  <div className='my__tracks__modal__video__list__item-id'>#</div>
                  <div className='flex-grow-1'>{t('NAME')}</div>
                  <div className='my__tracks__modal__video__list__item-status ps-1'>{t('STATUS')}</div>
                  <div className='my__tracks__modal__video__list__item-views'>{t('VIEWS')}</div>
                </div>
              }
              <hr className='my-2'/>
            </div>
            <div className={`my__tracks__modal__video__list`}>
              {st.videos.map((video) => (
                <TrackVideoItem
                  video={video}
                  key={video.id}
                  onChanged={fetch}
                  onSelectVideo={handleSelectVideo}
                  selectedVideosIds={st.selectedVideosIds}
                />
              ))}
            </div>
          </>
        )}
        <Empty show={!st.loading && !st.pager?.count} text='' className='my-5'/>

        <Pagination
          className={'m-1'}
          onPageChange={(page) =>
            runInAction(() => {
              st.selectedVideosIds = [];
              st.request.page = page;
              fetch();
            })
          }
          pager={st.pager}
        />
        <button onClick={() => addVideoModal(ve).then(fetch)}
                className="btn btn-primary btn-lg videos-modal-add-btn-small mt-3">
          {t('ADD_VIDEO')}
        </button>
      </Loadable>
    </>
  );
};

const openTracksVideosModal = (ve: IVideoEmbed): Promise<ITrack|null> =>
  asyncModal(observer(TracksVideosModal), {ve}, {
    showCloseIcon: false,
    center: true,
    blockScroll:false,
    styles: {modal: {width: 750}}
  });

export {openTracksVideosModal};
