import React, {useCallback, useEffect, useState} from 'react';
import muted_svg from '../../assets/images/icons/mute.svg';
import unmuted_svg from '../../assets/images/icons/unmute.svg';
import play_svg from '../../assets/images/icons/video_play.svg';
import pause_svg from '../../assets/images/icons/video_pause.svg';
import VideoTimeLine from './VideoTimeLine';
import {ReactSVG} from 'react-svg';
import {IVideoEmbed,} from "../../modules/rest";
import expand_svg from "../../assets/images/icons/expand.svg";
import download_svg from "../../assets/images/icons/download.svg";
import video_stub from "../../assets/images/icons/video_stub_2.svg";
import {download, thumb} from "../../modules/utils";

const addZeroToTime = (time: number) => {
  if (time < 10) return '0' + time;
  return time
};

const getTime = (totalSeconds: number) => {
  const hours = +Math.floor(totalSeconds / 3600).toFixed();
  totalSeconds %= 3600;
  const minutes = +Math.floor(totalSeconds / 60).toFixed();
  const seconds = +(totalSeconds % 60).toFixed();
  return `${addZeroToTime(minutes)}:${addZeroToTime(seconds)}`;
};

interface Props {
  className?: string;
  ve: IVideoEmbed;
  showControls?: boolean;
  onChangeActiveVideo?: () => void;
  active?: boolean;
  local?: boolean;
  bigControls?: boolean;
  downloadModal?: boolean;
  showDownload?: boolean;
  onClick?: () => void;
}

const Video = React.forwardRef<any, Props>(({
                                              ve,
                                              showControls,
                                              onChangeActiveVideo,
                                              active,
                                              local,
                                              className,
                                              onClick,
                                              bigControls,
                                              showDownload,
                                              downloadModal = true,
                                            }, ref: any) => {
  const [muted, setMuted] = useState(true);
  const [loading, setLoading] = useState(true);
  const [paused, setPaused] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const handleLoadedMetadata = useCallback(() => {
    const video = ref?.current;
    if (!video) return;
    setLoading(false);
    setDuration(video.duration);
  }, [ref?.current]);

  useEffect(() => {
    setPaused(ref.current?.paused);
  }, [ref.current?.paused]);

  useEffect(() => {
    if (!local) togglePause(!active);
  }, [active])

  const togglePause = useCallback((pause: boolean) => {
    if (pause) {
      ref?.current.pause();
    } else {
      ref?.current.play();
    }
  }, []);

  const handleTimeUpdate = useCallback((e: any) => {
    setCurrentTime(e.target.currentTime);
  }, []);

  const toggleMuted = useCallback((e: any) => {
    e.stopPropagation();
    setMuted(muted => !muted);
  }, []);

  const activeVideo = (active || (local && !paused))
  const video = ve?.video!;

  return (
    <div className={`my-video-wrap${bigControls ? ' big-controls' : ''} ${className || ''}`}>
      <div className={`my-video${paused ? ' paused' : ' playing'} `}
           onClick={onClick || ((e) => onClick || e.stopPropagation())}>
        {(paused && !currentTime)
          ?
          <div className='my-video-preview'>
            {ve.preview?.id
              ?
              <img src={thumb(ve.preview?.id!, 300)} alt='preview'/>
              :
              <ReactSVG src={video_stub} className='react-icon'/>
            }
          </div>
          :
          null
        }
        <video
          onLoadedMetadata={handleLoadedMetadata}
          loop
          onTimeUpdate={handleTimeUpdate}
          ref={ref}
          width='100%'
          height='100%'
          controls={false}
          muted={muted}
        >
          <source src={video?.url} type='video/mp4'/>
        </video>
        {(activeVideo && false) &&
        <div className='video-timeline'>
          <div className='video-muted' onClick={toggleMuted}>
            <ReactSVG className='react-icon' src={muted ? muted_svg : unmuted_svg}/>
          </div>
          <div className='video-timer'>
            {getTime(currentTime)}/{getTime(duration)}
          </div>
          <VideoTimeLine
            duration={duration}
            currentTime={currentTime}
            togglePause={togglePause}
            onChangeTime={setCurrentTime}
            onChangeVideoTime={(time) => {
              ref.current.currentTime = time;
            }}
          />
        </div>
        }
      </div>
      {video
        ?
        <>
          <div className='video-controls'>
            {ref?.current?.requestFullscreen
              ?
              <div className='video-controls-hidden video-controls-expand' onClick={(e) => {
                e.stopPropagation();
                ref.current.requestFullscreen();
              }}>
                <ReactSVG src={expand_svg} className='react-icon'/>
              </div>
              :
              null
            }
            <div className='video-play' onClick={(e) => {
              e.stopPropagation();
              if (onChangeActiveVideo) onChangeActiveVideo();
              else togglePause(!paused);
            }}>
              <ReactSVG className='react-icon' src={activeVideo ? pause_svg : play_svg}/>
            </div>
            {showDownload &&
            <div className='video-controls-hidden video-controls-download' onClick={(e) => {
              e.stopPropagation();
              download(video, video?.name)
            }}>
              <ReactSVG src={download_svg} className='react-icon'/>
            </div>
            }
          </div>
        </>
        :
        null
      }
    </div>

  );
});

export default Video;