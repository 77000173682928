import React, {FC} from 'react';
import session from "../../../modules/session";
import {IReactorChannel} from "../../../modules/rest";
import Select, {components, SingleValue} from "react-select";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {confirmDialog} from "../../../modules/confirm";
import {toast} from "react-toastify";
import {thumb} from "../../../modules/utils";
import {ReactSVG} from "react-svg";
import trash from "../../../assets/images/icons/trash.svg";
import goTo from "../../../assets/images/icons/go_to.svg";

interface Props {
  value?: IReactorChannel;
  onChange: (channel: SingleValue<IReactorChannel>) => void;
  deletable?: boolean;
  canAdd?: boolean;
  className?: string;
}

const ChannelSelect: FC<Props> = observer(({value, onChange, deletable, canAdd, className}) => {
  const {t} = useTranslation();
  return (
    <div className={className || ''}>
      <Select
        isSearchable={false}
        placeholder={t('SELECT_CHANNEL')}
        className="react-select-container aside-channel-select"
        classNamePrefix="react-select"
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        value={value}
        onChange={onChange}
        components={{
          Menu: (props) => <CustomOption {...props} value={value} deletable={deletable} canAdd={canAdd}/>,
          // MenuList: (props) => <CustomOption {...props} value={value} deletable={deletable} canAdd={canAdd}/>,
          Control: (props) => <CustomInput {...props} value={value}/>,
        }}
        options={session.channels}
        getOptionValue={option => option.id + ''}
        getOptionLabel={option => ''}
        noOptionsMessage={() => t('EMPTY_LIST')}
        // menuIsOpen
      />
    </div>
  );
})

export default ChannelSelect;


const CustomOption = observer(({children, deletable, canAdd, value, ...props}: any) => {

  const {t} = useTranslation();


  const handleClick = (channel: IReactorChannel) => () => {
    props.setValue(channel, 'set-value');
  }
  const handleRemove = (channel: IReactorChannel) => (e: any) => {
    e.stopPropagation();
    confirmDialog(t('DELETE_CHANNEL_CONFIRM_TITLE', {title: channel.channel?.name}), {
      title: t('DELETING_CHANNEL') || '',
      danger: true,
    }).then(async (confirmed) => {
      if (!confirmed) return;
      try {
        await session.removeChannel(channel.id);
        toast.success(t('CHANNEL_DELETE_SUCCESS'));
      } catch (e: any) {
        toast.error(e);
      } finally {
      }
    });
  }

  return (
    <components.Menu {...props}>
      <div className="aside-channel-select-menu">
        {props.options.map((item: IReactorChannel) => (
          <div className={`aside-channel-select-item ${item.id === value?.id ? ' active' : ''}`}
               onClick={handleClick(item)} key={item.id}>
            <img src={thumb(item.channel?.icon?.id!, 64)} alt=""/>
            <div className='d-grid overflow-hidden flex-grow-1'>
              <div className='text-truncate'>
                {item?.channel?.name}
              </div>
            </div>
            <div className='aside-channel-select-item-controls'>
              <a href={item.channel.url} target='_blank' className='aside-channel-select-item-url'
                 onClick={e => e.stopPropagation()}>
                <ReactSVG src={goTo} className='react-icon'/>
              </a>
              {(props.options.length > 1 && deletable) &&
                <ReactSVG src={trash} className='react-icon icon-trash' onClick={handleRemove(item)}/>}
            </div>
          </div>
        ))}
      </div>
      {canAdd &&
        <div className='px-2 pt-3 pb-1'>
          <button onClick={() => session.composeChannel()} className='btn btn-outline-primary btn-sm w-100 mb-1'>
            {t('ADD_CHANNEL')}
          </button>
        </div>
      }
    </components.Menu>
  );
});


const CustomInput = ({children, value, ...props}: any) => {
  return (
    <components.Control {...props}>
      {!value
        ?
        // <Loader className='ms-3'/>
        <div className='aside-channel-select-stub'></div>
        :
        <div className='aside-channel-select-item input-value'>
          <img src={thumb(value.channel.icon?.id!, 64)} alt=""/>
          <div className='d-grid overflow-hidden flex-grow-1'>
            <div className='text-truncate'>
              {value?.channel.name}
            </div>
          </div>
        </div>
      }
      {children}
    </components.Control>
  );
};