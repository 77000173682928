import React, {useCallback, useEffect, useState} from 'react';
import './faq.scss'
import {useTranslation} from "react-i18next";
import {API} from "../../modules/api";
import {EFieldGroup, IDoc, IDocItem} from "../../modules/rest";
import {toast} from "react-toastify";
import Loader from "../../components/Loader";
import {Link, useParams} from "react-router-dom";
import Loadable from "../../components/Loadable";


const HelpPage = () => {
  const {t} = useTranslation();
  const params = useParams<{ id?: string }>()
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState<IDoc>();
  const [docs, setDocs] = useState<IDoc[]>();
  const [docsPath, setDocsPath] = useState<IDocItem[]>();


  useEffect(() => {
    fetch();
  }, [params?.id]);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      let doc;
      if (params?.id) {
        doc = await API.Docs.getById(params.id, [EFieldGroup.DocContent]);
      } else {
        doc = await API.Docs.getByTag('reactrino', [EFieldGroup.DocContent]);
      }
      const docs = await API.Docs.index({parentId: doc.id});
      const docsPath = await API.Docs.getPath(doc.id);
      setDocsPath(docsPath);
      setDoc(doc);
      setDocs(docs);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }, [params?.id]);

  if (!doc) return <Loader/>

  return (
    <>
      <div className='page-header'>
        <h1 className='page-title'>{doc.title}</h1>
      </div>
      <Loadable loading={loading}>
        {docsPath && docsPath?.length > 1
          ?
          <ul className="breadcrumbs">
            {docsPath?.map((d, i) => {
                if (i === docsPath.length - 1) {
                  return <li className="text-tint" key={i}>{d.title}</li>
                }
                return (
                  <li className="" key={i}>
                    <Link to={`/monetization/help/${d.id}`}>{d.title}</Link>
                    <span className='px-1'>/</span>
                  </li>
                )
              }
            )}
          </ul>
          :
          null
        }
        {doc.content && <div className='mb-4' dangerouslySetInnerHTML={{__html: doc.content}}/>}

        {docs?.map(d => (
          <div key={d.id}>
            <Link to={`/monetization/help/${d.id}`}>{d.title}</Link>
          </div>
        ))}
      </Loadable>
    </>
  );
};

export default HelpPage;
