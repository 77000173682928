import asyncModal from 'react-async-modal';
import React, {FC, FormEvent,   useState} from 'react';
import Loadable from '../Loadable';
import {useTranslation} from "react-i18next";
import Button from "../Button";
import {Select} from "../FormControls";
import {Regions} from "../../modules/directory";
import geos from "../../translates/en/geos.json";
import Tag from "../Tag";
import {EContentPlanVideoOrigin, ECountry, IMonitoringCategory} from "../../modules/rest";
import {observer} from "mobx-react";
import session from "../../modules/session";
import {toast} from "react-toastify";
import ContentTypeTags from "./ContentTypeTags";
import {ReactSVG} from "react-svg";
import edit_svg from '../../assets/images/icons/edit.svg';
import info_svg from '../../assets/images/icons/info_white.svg';
import ModalHeader from "../../modals/ModalHeader";

interface Props {
  resolve(updated: boolean): void;

  small?: boolean;
}


const FilterContentCountryModal: FC<Props> = observer(({
                                                         resolve,
                                                         small,
                                                       }) => {
  const {t} = useTranslation();

  const channel = session.activeChannel! || {};

  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<'info'|'edit'>();
  const [countries, setCountries] = useState<ECountry[]>(channel?.countries || []);

  const handleClose =  () => {
    resolve(false);
  }

  // const handleChange = (key: string) => (value: any) => {
  //   setTempFilters(prevState => ({...prevState, [key]: value}))
  // }


  const submit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await session.updateChannel(channel.id, {countries});
      toast(t('UPDATE_CHANNEL_SUCCESS'));
      resolve(true);
    } catch (e: any) {
      toast.error(e || {message: 'Error'})
    } finally {
      setLoading(false);
    }
  }

  if (small && !step) return (
    <>
      <div className={`content-type trending`}>
        <ModalHeader onClick={handleClose}>
          <div className='d-flex align-items-center'>
            <div className="content-type-header-title">{t(`TRENDS`)}</div>
            <ContentTypeTags type={EContentPlanVideoOrigin.Trending} maxLength={100} countriesKeys/>
          </div>
        </ModalHeader>
        <div className='modal-content-type-edit-small'>
          <div className='modal-content-type-edit-small-item' onClick={() => setStep('edit')}>
            <ReactSVG src={edit_svg} className='react-icon me-1'/>
            <span>{t('EDIT')}</span>
          </div>
          <div className='modal-content-type-edit-small-item' onClick={() => setStep('info')}>
            <ReactSVG src={info_svg} className='react-icon me-1'/>
            <span>{t('DETAIL_INFO')}</span>
          </div>
        </div>
      </div>
    </>
  )

  if (step === 'info') return (
    <>
      <div className={`content-type trending`}>
        <ModalHeader onClick={handleClose}>
          <div className='d-flex align-items-center'>
            <div className="content-type-header-title">{t(`TRENDS`)}</div>
            <ContentTypeTags type={EContentPlanVideoOrigin.Trending} maxLength={100} countriesKeys/>
          </div>
        </ModalHeader>
        <div className='text-muted text-pre-wrap'>{t(`TRENDING_TEXT`)}</div>
        <div className='text-muted text-pre-wrap mt-3'>{t(`trending_INFO_TEXT`)}</div>
        <div className='modal-content-type-edit-small mt-4'>
          <div className='modal-content-type-edit-small-item' onClick={() => setStep('edit')}>
            <ReactSVG src={edit_svg} className='react-icon me-1'/>
            <span>{t('EDIT')}</span>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <Loadable loading={loading}>
        <form onSubmit={submit} className={`content-type trending`}>
          <ModalHeader onClick={handleClose} title='MODAL_CONTENT_TITLE_trends' />
          <div className='text-muted text-pre-wrap'>{t(`TRENDING_TEXT`)}</div>
          <CountrySelect filter={countries || []} onChange={(countries) => setCountries(countries as ECountry[])}/>
          {/*{type === 'novelties' &&*/}
          {/*  <GenresSelect filter={tempFilters.categories || []} onChange={handleChange('categories')}/>}*/}
          <div className="modal-footer-btns">
            <Button
              text='CANCEL'
              onClick={handleClose}
              color='outline-primary'
            />
            <Button
              text='SAVE'
              type='submit'
            />
          </div>
        </form>
      </Loadable>
    </>
  );
});

const openFilterContentCountryModal = (): Promise<boolean> => asyncModal(FilterContentCountryModal, {small: window.innerWidth < 1280}, {
  classNames: {modal: 'filters-channel-modal', modalContainer: window.innerWidth < 1280 ? 'bottom-modal' : ''},
  showCloseIcon: false,
  blockScroll: false,
  center: true
});

export {openFilterContentCountryModal};


interface PropsFilterSelect {
  filter: ECountry[]|IMonitoringCategory[];
  onChange: (data: (IMonitoringCategory|ECountry)[]) => void;
}

export const CountrySelect: FC<PropsFilterSelect> = ({filter, onChange}) => {
  const {t} = useTranslation();

  const handleChange = (value: ECountry) => {
    if (!value) return;
    const idx = (filter as ECountry[]).findIndex((ln: string) => ln === value);
    if (idx !== -1) {
      onChange([...filter.slice(0, idx), ...filter.slice(idx + 1)])
    } else {
      onChange([...filter, value])
    }
  };

  return (
    <>
      {filter?.length
        ?
        <div className='modal-filter-list'>
          {filter.map((ln, i) => (
            //@ts-ignore
            <Tag text={geos[ln.toUpperCase()]?.name} onClick={() => handleChange(ln as ECountry)}
                 key={i}
                 className='my-1 mx-1'/>
          ))}
        </div>
        :
        null
      }
      <h4 className='pb-1 mb-2'>{t('ADD_COUNTRY')}</h4>
      <Select
        placeholder={'SELECT'}
        onChange={(e) => handleChange(e.target.value)}
        className='sm'>
        {Regions.map(ln => (
          <option
            value={ln}
            key={ln}
            className={(filter as ECountry[]).includes(ln) ? ' active' : ''}
          >
            {/*@ts-ignore*/}
            {geos[ln.toUpperCase()]?.name || ln}</option>
        ))}
      </Select>
    </>
  )
}
