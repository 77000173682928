import React, {FC, useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import {SearchInput} from "../../components/FormControls";
import ChannelsListItem from "./ChannelsListItem";
import {IGetMonitoringChannelsRequest, IMonitoringChannel, IPagedData} from "../../modules/rest";
import Pagination from "../../components/Pagination";
import Loadable from "../../components/Loadable";
import {observer} from "mobx-react";
import {openAddChannelModal} from "./AddChannelModal";

interface Props {
  request: IGetMonitoringChannelsRequest;
  onChangeFilters: (request: IGetMonitoringChannelsRequest) => void;
  onRemove: (id: number) => void;
  fetch: () => void;
  pager?: IPagedData<IMonitoringChannel>
}

const MyChannels: FC<Props> = observer(({
                                          fetch,
                                          request,
                                          onChangeFilters,
                                          pager,
                                          onRemove,
                                        }) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleAddChannel = useCallback(() => {
    openAddChannelModal().then(async channel => {
      if (channel) {
        setLoading(true);
        await fetch();
        setLoading(false);
      }
    })
  }, [fetch]);

  return (
    <>
      <h4 className='mt-4'>{t('PERSONAL_LIST')}</h4>
      <div className='text-muted mt-2' style={{maxWidth: 730}}>{t('MY_CHANNELS_TEXT')}</div>
      <div className='d-flex mt-4'>
        <div className='flex-1 pe-2'>
          <button type='button' className='btn btn-outline-primary btn-lg w-100' onClick={handleAddChannel}>
            {t('ADD_CHANNEL')}
          </button>
        </div>
        <SearchInput
          onStartTyping={() => setLoading(true)}
          value={request.query}
          onSearch={async (query) => {
            await onChangeFilters({...request, query})
            setLoading(false);
          }}
          small
          className='ps-2 flex-1'
          inputClassName='w-100'
        />


      </div>

      {pager?.data
        ?
        pager.data?.length
          ? <div className='pt-4'>
            <div className='table-head'>
              <div style={{width: 40}}>#</div>
              <div className='flex-1'>{t('CHANNEL')}</div>
              <div style={{width: 150}} className='text-right'>{t('CATEGORY')}</div>
              <div style={{width: 88}}/>
            </div>
            <Loadable loading={loading} className='channel-monitoring-list'>
              {pager.data.map((item) => (
                <ChannelsListItem
                  key={item.id}
                  item={item}
                  onRemove={onRemove}
                />
              ))
              }
              {pager.data.map((item) => (
                <ChannelsListItem
                  key={item.id}
                  item={item}
                  onRemove={onRemove}
                />
              ))
              }
              <Pagination
                className='mt-4'
                onPageChange={(page) => onChangeFilters({...request, page})}
                onLimitChange={(limit) => onChangeFilters({...request, limit, page: 1})}
                pager={pager}
              />
            </Loadable>
          </div>
          :
          <Loadable loading={loading} className='text-muted-14 mt-3'>{t('EMPTY_LIST')}</Loadable>
        :
        null
      }

    </>
  );
})

export default MyChannels;