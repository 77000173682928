import { useState} from 'react';
import {
  EContentPlanVideoStatus,
  IContentPlanVideo,
} from "../../modules/rest";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import {confirmDialog} from "../../modules/confirm";
import {openContentPlanUploadedModal} from "./ContentPlanUploadedModal";
import {openComposeChannelToContentPlanModal} from "../../modals";
import {useTranslation} from "react-i18next";



type TMethod = 'add' | 'remove' | 'edit' | 'status'

export default function useMethodsContentPlan(onSuccess: (method: TMethod, item?: IContentPlanVideo) => void) {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const onAdd = () => {
    openComposeChannelToContentPlanModal().then((success) => {
      if (success) onSuccess('add');
    });
  }

  const onRemove = (item: IContentPlanVideo) =>  {
    confirmDialog(t('DELETE_CONFIRM_TITLE', {title: item.title}), {
      title: t('DELETING')!,
      danger: true,
      confirmText: t('REMOVE')!,
    }).then(async (confirmed) => {
      if (!confirmed) return;
      try {
        setLoading(true);
        await API.ContentPlan.deleteVideo(item.id);
        await onSuccess('remove');
      } catch (e: any) {
        toast.error(e);
      } finally {
        setLoading(false);
      }
    })
  }

  const onStatusChange =  async (item: IContentPlanVideo, status: EContentPlanVideoStatus) => {
    if (status === item.status) return;
    if (status === EContentPlanVideoStatus.VideoUploaded) {
      openContentPlanUploadedModal(item).then(async success => {
        if (success) {
          setLoading(true);
          await onSuccess('status');
          setLoading(false);
        }
      })
      return;
    }
    try {
      setLoading(true);
      await API.ContentPlan.updateVideo(item.id, {status});
      await onSuccess('status');
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }

  const onEdit = (item: IContentPlanVideo) => {
    openComposeChannelToContentPlanModal(undefined, undefined, item).then((item) => {
      if (item) onSuccess('edit', item)
    });
  }

  return {onEdit, onStatusChange, onRemove, onAdd, loading};
}
