import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {IWithdraw} from "../../modules/rest";
import {ReactSVG} from "react-svg";
import bg from './assets/icons/message-bg.svg';
import icon from './assets/icons/message_icon.svg';

interface Props {
  item: IWithdraw;
  className?: string;
  hideIcon?: boolean;
}

const BillingWithdrawStatus: FC<Props> = ({item, className, hideIcon}) => {
  const {t} = useTranslation();
  return (
    <div className={`d-flex align-items-center ${className || ''}`}>
      {item.comment && !hideIcon
        ?
        <div className={`billing-status-message ${item.status}`}>
          <ReactSVG src={bg} className='react-icon billing-status-message-bg' />
          <ReactSVG src={icon} className='react-icon billing-status-message-icon' />
        </div>
        :
        null
      }
      <div className={`billing-status ${item.status}`}>
        {t(item.status.toUpperCase())}
      </div>
    </div>
  );
}

export default BillingWithdrawStatus;