import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Button from "../../components/Button";
import get_video_svg from "../../assets/images/icons/get_video.svg";
import video_1 from '../../assets/video/1stepp.mp4';
import video_2 from '../../assets/video/2step.mp4';
import video_3 from '../../assets/video/3step.mp4';
import video_4 from '../../assets/video/4step.mp4';
import {Icon} from "../../components/fontawesome";

const videos = [video_1, video_2, video_3, video_4];

interface Props {
  onClick: () => void;
  className?: string;
}

const VideosListEmpty: FC<Props> = ({onClick, className}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 0)
  },[step])

  const handleClick = (dir: 1|-1) => () => {
      setStep(prevState => prevState + dir);
  }

  return (
    <div className={`videos-list-empty ${className || ''}`}>
      <div className="w-100">
        <div className="videos-list-empty-content">
          <div className='videos-list-empty-header'>
            <button type='button' className='btn btn-third' disabled={step === 0} onClick={handleClick(-1)}>
              <Icon icon={['fas', 'arrow-left']} fixedWidth/>
            </button>
            <h2 className='text-24'>{t('HOW_IT_WORK')}</h2>
            <div className='videos-list-empty-step'>{t('STEP')} {step + 1}/5</div>
          </div>
          {videos[step]
            ?
            <div className='videos-list-empty-video'>
              {!loading &&
                <video width="320" height="240" controls loop autoPlay muted>
                  <source src={videos[step]} type="video/mp4"/>
                </video>
              }
            </div>
            :
            null
          }
          <h4 className='mt-4'>{t(`VIDEOS_EMPTY_TITLE_${step + 1}`)}</h4>
          <div className='videos-list-empty-text'>{t(`VIDEOS_EMPTY_TEXT_${step + 1}`)}</div>
          <div className="videos-list-empty-pagination">
            {Array(5).fill({}).map((_, i) => (
              <div className={`videos-list-empty-pagination-item${step === i ? ' active' : ''}`}
                   onClick={() => setStep(i)}/>
            ))}
          </div>
          {/*{Array(5).fill({}).map((_, i) => (*/}
          {/*  <div className="videos-list-empty-content-item" key={i}>*/}
          {/*    <div className='videos-list-empty-content-item-number'>{i + 1}</div>*/}
          {/*    <div className='text-pre-wrap'>{t(`VIDEOS_EMPTY_TEXT_${i + 1}`)}</div>*/}
          {/*  </div>*/}
          {/*))}*/}

          <div className='videos-list-empty-footer'>
            {step === 4
              ?
              <Button
                className='btn-circle-icon'
                text='REQUEST_VIDEO'
                iconSvg={get_video_svg}
                onClick={onClick}
              />
              :
              <Button
                color='outline-primary'
                icon={['fas', 'arrow-right']}
                text='NEXT'
                onClick={handleClick(1)}
              />
            }
          </div>

        </div>
      </div>
    </div>
  );
}

export default VideosListEmpty;