import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import eye from '../../assets/images/icons/eye_pass.svg';
import eyelashes from '../../assets/images/icons/eyelashes.svg';
import geos from "../../translates/en/geos.json";

interface Props {
  className?: string;
  details?: Record<string, any>;
}

const BillingPaymentDetailsModal: FC<Props> = ({details, className}) => {
  const {t} = useTranslation();
  const [cardNumberVisible, setCardNumberVisible] = useState(false);

  const toggleVisible = () => {
    setCardNumberVisible(prevState => !prevState);
  }
  const renderValue = (key: string, value: string) => {
    if (key === 'cardnumber') {
      const str = value.replace(/(\d{1,4}(?=(?:\d\d\d\d)+(?!\d)))/g, "$1" + ' ');
      if (!cardNumberVisible) return str.slice(0, 14) + ' ****'
      return str
    }
    if (key === 'country') {
      //@ts-ignore
      return geos[value.toUpperCase()]?.name || value
    }
    return value;
  }

  const data = Object.entries(details || {});

  if (!data.length) return null;

  return <div className={className || ''}>
    {data.map(([key, value]) => (
      <div className={`billing-payment-modal-more-item-wrap ${data.length > 1 ? ' half' : ''}`} key={key}>
        <div className="billing-payment-modal-more-item">
          <div className='flex-1'>
            <div className='text-muted' style={{marginBottom: 2}}>{t(key.toUpperCase())}</div>
            <div>{renderValue(key, value)}</div>
          </div>
          {key === 'cardnumber' &&
            <ReactSVG src={!cardNumberVisible ? eye : eyelashes} className='react-icon cursor-pointer'
                      onClick={toggleVisible}/>}
        </div>
      </div>
    ))}
  </div>
}

export default BillingPaymentDetailsModal;