import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import copy_svg from '../assets/images/icons/copy.svg';
import check from '../assets/images/icons/check.svg';
import copy from 'copy-to-clipboard';

interface Props {
  className?: string;
  text?: string;
}


const CopyToClipboard: FC<Props> = ({text,className}) => {
  const {t} = useTranslation();
  const _timer: any = useRef(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(_timer?.current);
    }
  }, []);

  const handleClick = (e: any) => {
    e.stopPropagation()
    clearTimeout(_timer?.current);
    setCopied(true);
    copy(text || '')
    _timer.current = setTimeout(() => {
      setCopied(false);
    }, 1500)
  }


  return (
    <button type='button' className={`btn btn-third btn-sm p-0 btn-copy ${className || ''}`} onClick={handleClick}>
      <ReactSVG src={copied ? check : copy_svg} className='react-icon'/>
    </button>
  );
}

export default CopyToClipboard;