import {ELanguage, IGenerateVideoEmbedTextsTask, IVideoEmbed,} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import TextGenerate from "../../components/TextGenerate";
import {ModalClose} from "../index";
import ModalHeader from "../ModalHeader";

interface Props {
  ve: IVideoEmbed;

  resolve(): void;
}

const TextGenerateModal: FC<Props> = ({ve, resolve}) => {
  const {t} = useTranslation();
  const [generateTexts, setGenerateTexts] = useState<any>({});

  const handleClose = () => {
    resolve()
  }

  const handleUpdateGenTexts =  (text: IGenerateVideoEmbedTextsTask, language: ELanguage) => {
    setGenerateTexts((prevState: any) => ({...prevState, [language]: text}));
  }

  return (
    <div className='text-generate-modal'>
      <ModalHeader onClick={() => resolve()}  title={'TEXT_TO_INSERT'}/>
      <TextGenerate
        videoEmbedId={ve.id}
        onUpdate={handleUpdateGenTexts}
        generateTexts={generateTexts}
      />
    </div>
  );
};

const openTextGenerateModal = (ve: IVideoEmbed): Promise<boolean> => asyncModal(TextGenerateModal, {ve}, {
  showCloseIcon: false,
  center: true
});

export {openTextGenerateModal};
