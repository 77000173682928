import {useEffect, useState} from 'react';
import {
  EContentPlanVideoStatus,
  ESortOrder,
  IContentPlanVideo,
  IGetContentPlanRequest,
  TIdentifier
} from "../../modules/rest";
import moment from "moment/moment";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import session from "../../modules/session";

export type TContentPlanData = { [DATE in string]: IContentPlanVideo[] }
export type TContentPlanSmallData = { [DATE in string]: { [STATUS in EContentPlanVideoStatus]: IContentPlanVideo[] } }

const dateFormat = 'YYYY-MM-DD';
const yesterday = moment().subtract(1, "days").format(dateFormat);

interface Props {
  channelId: TIdentifier;
  isArchive?: boolean;
  dependencies?: any[];
}

export default function useFetchContentPlan({
                                              channelId,
                                              isArchive = false,
                                              dependencies = [],
                                            }: Props) {
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contentPlan, setContentPlan] = useState<TContentPlanData>();
  const [contentPlanSmall, setContentPlanSmall] = useState<TContentPlanSmallData>();
  const [date, setDate] = useState<IGetContentPlanRequest>({
    dateFrom: moment().subtract(10, "days").format(dateFormat),
    dateTill: yesterday
  });

  useEffect(() => {
    getContentPlan();
  }, [channelId, date, ...dependencies]);


  const getContentPlan = async () => {
    if (loading || !session.activeChannel?.id) return;
    setLoading(true);
    try {
      let req: IGetContentPlanRequest = {};
      if (!isArchive) {
        req = {dateFrom: moment().format(dateFormat), dateTill: moment().add(30, 'days').format(dateFormat)}
      }
      const res = await API.ContentPlan.get(session.activeChannel?.id!, {
        ...date, ...req,
        order: !isArchive ? ESortOrder.ASC : ESortOrder.DESC
      });
      if (res?.length) {
        const data: TContentPlanData = {};
        const smallData: TContentPlanSmallData = {};
        res.forEach(item => {
          const date = moment(item.scheduledAt).format('YYYY-MM-DD');
          data[date] = data[date] ? [...data[date], item] : [item];
          if (!smallData[date]) {
            smallData[date] = {
              [EContentPlanVideoStatus.VideoPending]: [],
              [EContentPlanVideoStatus.VideoReady]: [],
              [EContentPlanVideoStatus.VideoUploaded]: [],
            }
          }
          smallData[date][item.status] = [...smallData[date][item.status], item];
        });
        setContentPlan(data);
        setContentPlanSmall(smallData);
      } else {
        setContentPlan(undefined)
      }
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
      setReady(true);
    }
  }

  const onDateChange = (key: string) => (e: any) => {
    let value = e.target.value;
    if (key === 'dateTill') {
      const yesterday = moment().subtract(1, "days").format('YYYY-MM-DD');
      const end = moment(value);
      const diff = moment.duration(end.diff(yesterday)).asDays();
      if (diff > 0) return setDate(prevState => ({...prevState, [key]: yesterday}));
    }
    setDate(prevState => ({...prevState, [key]: e.target.value}));
  }

  return {contentPlan, contentPlanSmall, date, ready, loading, getContentPlan, onDateChange};
}
