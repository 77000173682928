import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import arrow_text from "../../assets/images/icons/arrow_text.svg";
import {useTranslation} from "react-i18next";

interface Props {
  visible?: boolean;
  className?: string;
}

const TextGenerateSeparator: FC<Props> = ({visible, className }) => {
  const {t} = useTranslation();
  if(!visible) return null
  return (
    <div className={`text-generate-separator ${className || ''}`}>
      <div className='text-generate-separator-content'>
        <ReactSVG src={arrow_text} className='react-icon'/>
        <div className='text-tint text-bold ms-2'>{t('TEXT_TO_INSERT')}</div>
      </div>
    </div>
  );
}

export default TextGenerateSeparator;