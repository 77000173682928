import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Input, Select} from "../../../components/FormControls";
import {ReactSVG} from "react-svg";
import filter_svg from '../assets/icons/filter.svg';
import {
  ECountry,
  EFieldGroup,
  EReviewStatus,
  IGetYoutuberStatsRequest,
  IVideoEmbed,
} from "../../../modules/rest";
import VideoEmbedSelect from "../../../components/VideoEmbedSelect";
import {API} from "../../../modules/api";
import {getEmbedName} from "../../../modules/utils";
import FilterSelectedItem from "./FilterSelectedItem";
import FilterSelectedList from "./FilterSelectedList";
import {Regions} from "../../../modules/directory";
import geos from "../../../translates/en/geos.json";
import ReportsFiltersHead from "./ReportsFiltersHead";
import ReportsFiltersCount from "./ReportsFiltersCount";
import moment from "moment";


interface Props {
  request: IGetYoutuberStatsRequest;
  updateState: (request: Partial<IGetYoutuberStatsRequest>) => void;
  visible?: boolean;
}

const ReportsFilters: FC<Props> = ({request, updateState, visible}) => {
  const {t} = useTranslation();
  const [visibleType, setVisibleType] = useState<IGetYoutuberStatsRequest['group']>();
  const [veData, setVeData] = useState<IVideoEmbed[]>();
  const [embedVideos, setEmbedVideos] = useState<IVideoEmbed[]>([]);

  useEffect(() => {
    getVideoEmbed();
  }, []);

  const getVideoEmbed = async () => {
    try {
      const res = await API.VideoEmbeds.getList({
        limit: 100,
        status: EReviewStatus.Approve
      }, [EFieldGroup.VideoEmbedVideo]);
      setVeData(res.data);
      if (Array.isArray(request.embed) && request.embed?.length) {
        const embed:IVideoEmbed[] = [];
        request.embed.forEach(embedId => {
          const item = res.data?.find(item => item.id === embedId);
          if (item) embed.push(item);
        })
        setEmbedVideos(embed)
      }
    } catch (e) {

    }
  }

  const handleChangeEmbedVideos = (ve: IVideoEmbed) => {
    setEmbedVideos((prevState) => {
      const idx = prevState?.findIndex(item => item.id === ve.id);
      let nextState;
      if (idx !== -1) {
        nextState = prevState?.filter(item => item.id !== ve.id);
      } else {
        nextState = [...(prevState || []), ve];
      }
      updateState({embed: nextState.map(item => item.id)})
      return nextState;
    });
  };

  const handleChangeGeo = (geo: ECountry, remove?: boolean) => {
    const countries = request.country as ECountry[];
    if (!countries.includes(geo)) return updateState({country: [...countries, geo]});
    if (remove) updateState({country: countries.filter(g => g !== geo)})
  };

  const handleChangeDate = (period: 'periodBegin' | 'periodEnd') => (e: any) => {
    const date = e.target.value
    if (moment(date).isValid()) {
      updateState({[period]: date});
    }
  };

  const handleClearAll = () => {
    updateState({country: [], embed: [], periodEnd: '', periodBegin: ''});
    setEmbedVideos([]);
  };

  const handleClick = (type: IGetYoutuberStatsRequest['group']) => () => {
    setVisibleType(prevState => {
      if (prevState !== type) return type;
      return;
    })
  }

  return (
    <div className={`report-filters${visible ? ' visible' : ''}`}>
      <div className="report-filters-block">
        <div className='report-filters-block-head'>
          <ReactSVG src={filter_svg} className='react-icon'/>
          <span className='ps-2'>{t('FILTER')}</span>
          <ReportsFiltersCount
            accent
            len={(embedVideos?.length || 0) + (request.country?.length || 0) + ((request?.periodBegin || request?.periodEnd) ? 1 : 0)}
            onClick={handleClearAll}
          />
        </div>

        <div className="report-filters-block-body">
          <div className={`report-filters-block-body-item${visibleType === "embed" ? ' visible' : ''}`}>
            <ReportsFiltersHead
              title='PROMO_CLIP'
              len={embedVideos?.length}
              onClear={() => {
                updateState({embed: []});
                setEmbedVideos([])
              }}
              onClick={handleClick("embed")}
            />
            <div className="report-filters-block-body-content">
              <FilterSelectedList>
                {embedVideos?.map(item => (
                  <FilterSelectedItem
                    key={item.id}
                    name={getEmbedName(item)}
                    onClick={() => handleChangeEmbedVideos(item)}
                  />
                ))}
              </FilterSelectedList>
              <VideoEmbedSelect
                value={embedVideos}
                singleLine
                placeholder='SELECT_PROMO'
                onChange={handleChangeEmbedVideos}
                options={veData}
              />
            </div>
          </div>
          <div className={`report-filters-block-body-item${visibleType === "country" ? ' visible' : ''}`}>
            <ReportsFiltersHead
              title='COUNTRIES'
              len={request.country?.length}
              onClear={() => updateState({country: []})}
              onClick={handleClick("country")}
            />
            <div className="report-filters-block-body-content">
              <FilterSelectedList>
                {(request.country as ECountry[])?.map(geo => (
                  <FilterSelectedItem
                    key={geo}
                    //@ts-ignore
                    name={geos[geo.toUpperCase()]?.name || geo}
                    onClick={() => handleChangeGeo(geo, true)}
                  />
                ))}
              </FilterSelectedList>
              <Select
                onChange={(e) => handleChangeGeo(e.target.value)}
                className='sm'
                placeholder={'SELECT_COUNTRY'}
              >
                {Regions.map(ln => (
                  <option
                    value={ln}
                    key={ln}
                  >
                    {/*@ts-ignore*/}
                    {geos[ln.toUpperCase()]?.name || ln}</option>
                ))}
              </Select>
            </div>
          </div>
          <div className={`report-filters-block-body-item${visibleType === "period" ? ' visible' : ''}`}>
            <ReportsFiltersHead
              title='PERIOD'
              len={request.periodBegin || request.periodEnd ? 1 : 0}
              onClear={() => updateState({periodBegin: '', periodEnd: ''})}
              onClick={handleClick("period")}
            />
            <div className="report-filters-block-body-content">
              <FilterSelectedList>
                {request.periodBegin
                  ?
                  <FilterSelectedItem
                    name={`${t('FROM')} ${request.periodBegin}`}
                    onClick={() => updateState({periodBegin: ''})}
                  />
                  :
                  null
                }
                {request.periodEnd
                  ?
                  <FilterSelectedItem
                    name={`${t('TILL')} ${request.periodEnd}`}
                    onClick={() => updateState({periodEnd: ''})}
                  />
                  :
                  null
                }
              </FilterSelectedList>
              <div className='input-group'>
                <Input
                  max={(!request.periodBegin ? (request?.periodEnd) : '') || ''}
                  onKeyDown={(e) => e.preventDefault()}
                  required
                  type={'date'}
                  className='sm w-50'
                  placeholder={t('FROM') || ''}
                  value={request.periodBegin || ''}
                  onChange={handleChangeDate('periodBegin')}
                />
                <Input
                  min={(!request.periodEnd ? (request?.periodBegin) : '') || ''}
                  onKeyDown={(e) => e.preventDefault()}
                  type='date'
                  className={'sm w-50'}
                  placeholder={t('TILL') || ''}
                  value={request.periodEnd || ''}
                  onChange={handleChangeDate('periodEnd')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsFilters;