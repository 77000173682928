import React, {FC, useState} from 'react';
import Button from "../../Button";
import {Input} from "../../FormControls";
import ReactSlider from 'react-slider'
import {useTranslation} from "react-i18next";
import ChannelSelect from "../ChannelSelect";
import {SingleValue} from "react-select";
import {IContacts, IReactorChannel, ISubmitMonetizationApplicationRequest} from "../../../modules/rest";
import {toast} from "react-toastify";
import tg_svg from '../../../assets/images/icons/tg_white.svg';
import whatsapp_svg from '../../../assets/images/icons/whatsapp.svg';
import viber_svg from '../../../assets/images/icons/viber.svg';
import {API} from "../../../modules/api";
import {runInAction} from "mobx";
import session from "../../../modules/session";

interface Props {
  onClose: () => void;
}

const AsideMonetizationModalForm: FC<Props> = ({onClose}) => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState<IReactorChannel>();
  const [views, setViews] = useState(20);
  const [inputViews, setInputViews] = useState(20000);
  const [contacts, setContacts] = useState<IContacts>({whatsapp: '', telegram: '', viber: '', tel: ''});
  const [contactsError, setContactsError] = useState(false);

  const handleChange = (e: any) => {
    let value = e.target.value;
    if (value < 1000) value = 1000;
    else if (value > 500000) value = 500000;
    setInputViews(e.target.value);
    setViews(value / 1000);
  }

  const handleChangeContacts = (contact: string) => (e: any) => {
    setContactsError(false);
    setContacts(prevState => {
      return {...prevState, [contact]: e.target.value}
    })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    let contactError = true;
    Object.values(contacts).forEach((value) => {
      if (!value) return;
      contactError = false;
    });
    if (contactError) return setContactsError(true);
    setLoading(true);
    const viewsRes = views * 1000;
    try {
      const monetization = await API.Reactrino.submitApplication({contacts, channel: channel?.id!, views: viewsRes});
      runInAction(() => {
        session.monetization = monetization;
        onClose();
      })
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='text-muted mb-2 pb-1 mt-4'>{t('CHANNEL_FOR_MONETIZATION')}</div>
      <ChannelSelect
        value={channel}
        onChange={(ch: SingleValue<IReactorChannel>) => setChannel(ch as IReactorChannel)}
      />
      <div className='text-muted mb-2 pb-1 mt-4'>{t('COUNT_VIEWS')}</div>
      <div className='section-2-slider-wrap'>
        <Input
          value={inputViews}
          onChange={handleChange}
          type='number'
          className='section-2-form-input sm'
        />
        <div className='section-2-slider'>
          <ReactSlider
            min={1}
            max={500}
            value={views}
            onChange={(views: any) => {
              setViews(views);
              setInputViews(views * 1000)
            }}
            renderThumb={(props: any, state: any) => (
              <div {...props} className='section-2-slider-track'>
                <div className="section-2-slider-track-value">{state.value}.000</div>
                <div className="section-2-slider-track-circle"/>
              </div>
            )}
          />
          <div className='section-2-slider-range'>
            <div>1000</div>
            <div>500.000</div>
          </div>
        </div>

      </div>
      <h4 className='mb-3 mt-4'>{t('MESSENGERS')}</h4>
      <div className="row">
        <div className="col-4">
          <Input
            icon={tg_svg}
            className='sm'
            error={contactsError}
            type='text'
            placeholder={'Telegram'}
            label='Telegram'
            value={contacts.telegram || ''}
            onChange={handleChangeContacts('telegram')}
          />
        </div>
        <div className="col-4">
          <Input
            icon={whatsapp_svg}
            className='sm'
            error={contactsError}
            type='text'
            placeholder={'WatsApp'}
            label='WhatsApp'
            value={contacts.whatsapp || ''}
            onChange={handleChangeContacts('whatsapp')}
          />
        </div>

        <div className="col-4">
          <Input
            icon={viber_svg}
            className='sm'
            error={contactsError}
            type='text'
            placeholder={'Viber'}
            label='Viber'
            value={contacts.viber || ''}
            onChange={handleChangeContacts('viber')}
          />
        </div>
      </div>
      <div className="modal-footer-btns">
        <Button
          text='CANCEL'
          onClick={onClose}
          color='outline-primary'
        />
        <Button
          disabled={!channel}
          text='REQUEST_ACCESS'
          type='submit'
          loading={loading}
        />
      </div>
    </form>
  );
}

export default AsideMonetizationModalForm;