import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import close from '../../assets/images/icons/close.svg';

interface Props {
  onClick?: () => void;
  text: string | number;
  className?: string;
  small?: boolean;
}

const Tag: FC<Props> = ({text, onClick, className, small}) => {
  return (
    <div className={`custom-tag${small ? ' small' : ''} ${className || ''}`}>
      <span className='pe-2'>{text}</span>
      {onClick
        ?
        <ReactSVG src={close} className='react-icon me-2' onClick={onClick}/>
        :
        null
      }

    </div>
  );
}

export default Tag;