import React, {FC, useState} from 'react';
import TrendsAside from "../../components/TrendsAside";
import {useTranslation} from "react-i18next";
import ContentTypeNovelties from "../../components/ContentType/ContentTypeNovelties";
import {observer} from "mobx-react";
import session from "../../modules/session";
import {ReactSVG} from "react-svg";
import tg_svg from "../../assets/images/icons/tg.svg";
import TgBtn from "../../components/TgBtn";
import CloseBtn from "../../components/CloseBtn";
import {openMonitoringGenresModal} from "../../components/ContentType/MonitoringGenresModal";
import {EContentPlanVideoOrigin} from "../../modules/rest";

interface Props {
}

const NoveltiesPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const [tgPanelVisible, setTgPanelVisible] = useState(true);
  const [asideVisible, setAsideVisible] = useState(true);

  const handleModalOpen = () => {
    openMonitoringGenresModal(1).then();
  }

  return (
    <div className='content-type-page novelties-page'>
      <h1>{t('NOVELTIES')}</h1>
      <div className="text-muted mb-3">
        {t('NEW-VIDEOS_TEXT')}
      </div>

      <div className='content-type-page__content pt-2'>
        <div className='novelties-aside'>
          {asideVisible &&
            <div className='novelties-aside-top'>
              <div className='novelties-aside-top-content'>
                <div className='d-flex'>
                  <div className='text-medium-14 pe-2'>{t('NOVELTIES_ASIDE_TEXT')}</div>
                  <CloseBtn onClick={() => setAsideVisible(false)}/>
                </div>
                <button type='button' className='btn btn-outline-primary btn-lg w-100 mt-4' onClick={handleModalOpen}>
                  {t('GO_TO')}
                </button>
              </div>
            </div>
          }
          <TrendsAside type={EContentPlanVideoOrigin.NewVideos}/>
        </div>
        {(tgPanelVisible && !session.user?.isBotConnected) &&
          <div className="tg-panel">
            <ReactSVG src={tg_svg} className='react-icon tg-icon'/>
            <div className='text-medium-14 flex-grow-1 pe-3 py-1'>{t('TG_PANEL_TEXT')}</div>
            <TgBtn primary icon={false}/>
            <CloseBtn className='ms-3' onClick={() => setTgPanelVisible(false)}/>
          </div>
        }
        <ContentTypeNovelties maxTagsLength={7} />
      </div>
    </div>
  );
})

export default NoveltiesPage;