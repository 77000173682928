import React, {FC, useEffect, useState} from 'react';
import {EContentPlanVideoOrigin, ECountry, IMonitoringCategory} from "../../modules/rest";
import {observer} from "mobx-react";
import session from "../../modules/session";
import geos from "../../translates/en/geos.json";
import OutsideClick from "../OutsideClick";

interface Props {
  type: EContentPlanVideoOrigin;
  maxLength?: number;
  countriesKeys?: boolean;
  small?: boolean;
  data?: IMonitoringCategory[]
}


const ContentTypeTags: FC<Props> = observer(({type, maxLength = 3, data, countriesKeys, small}) => {
  const [list, setList] = useState<string[]>([]);
  const [moreList, setMoreList] = useState<string[]>([]);
  const [visible, setVisible] = useState(false);
  const channel = session.activeChannel;

  useEffect(() => {
    let list: string[] = [];
    if (channel) {
      if (type === EContentPlanVideoOrigin.Trending) {
        (data || channel.countries as ECountry[])?.forEach(item => {
          //@ts-ignore
          list.push(countriesKeys ? item.toUpperCase() : (geos[item.toUpperCase()]?.name || item.toUpperCase()))
        })
      } else if (type === EContentPlanVideoOrigin.NewVideos) {
        (data ||channel.categories)?.forEach(item => {
          list.push(item.name)
        })
      }
      if (list.length > maxLength) {
        setList(list.slice(0, maxLength - 1));
        setMoreList(list.slice(maxLength - 1))
      } else {
        setList(list);
        setMoreList([])
      }
    }
  }, [channel, data]);

  if (!channel || type === EContentPlanVideoOrigin.Comments) return null;

  return (
    <div className='content-type-tags'>
      {list.map((item, i) => (
        <div className='content-type-tags-item' key={i}>
          <div className='text-truncate'>
            {item}
          </div>
        </div>
      ))}
      {moreList?.length
        ?
        <div className='position-relative d-flex justify-content-center'>
          <div className='content-type-tags-item content-type-tags-item-more' title={moreList.join(', ')}
               onClick={(e) => {
                 e.stopPropagation();
                 setVisible(prevState => !prevState)}}>
            +{moreList.length}
          </div>
          <OutsideClick outsideClickEvent={() => setVisible(false)}
                        className={`content-type-tags-hidden${visible ? ' visible' : ''}`}>
            {/*<CloseBtn onClick={() => setVisible(false)}/>*/}
            <div className='content-type-tags-hidden-list'>
              {moreList.map((item, i) => (
                <div className='content-type-tags-item' key={i}>
                  <div className='text-truncate'>
                    {item}
                  </div>
                </div>
              ))}
            </div>
          </OutsideClick>
        </div>
        :
        null
      }
    </div>
  );
})

export default ContentTypeTags;