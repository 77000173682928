import asyncModal from 'react-async-modal';
import React, {FC, useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ModalClose} from "../../modals";
import {IMonitoringCategory, IMonitoringChannel} from "../../modules/rest";
import {Input, Select} from "../../components/FormControls";
import {toast} from "react-toastify";
import {API} from "../../modules/api";
import close from "../../assets/images/icons/close_small.svg";
import {ReactSVG} from "react-svg";
import {toJS} from "mobx";
import Loadable from "../../components/Loadable";
import {observer} from "mobx-react";
import session from "../../modules/session";
import ModalHeader from "../../modals/ModalHeader";

interface Props {
  resolve(req: IMonitoringChannel|null): void;
}

const AddChannelModal: FC<Props> = observer(({resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<IMonitoringCategory[]>([]);

  const handleClose = useCallback(() => {
    resolve(null)
  }, [resolve]);

  const handleChangeCategory =  (id: number) => {
    if (!id) return;
    // @ts-ignore
    setSelectedCategories((prevState: IMonitoringCategory[]) => {
      const idx = prevState.findIndex(c => c.id == id);
      if (idx === -1) {
        const item = toJS(session.categories)?.find(c => c.id == id);
        return [...prevState, item];
      }
      return prevState
    })
  }

  const handleRemoveCategory = useCallback((id: number) => () => {
    setSelectedCategories(prevState => {
      return prevState.filter(c => c.id !== id);
    })
  }, []);


  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true)
    try {
      const category = selectedCategories.map(c => c.id);
      const res = await API.Monitoring.createChannel({url, category});
      resolve(res);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false)
    }
  }, [loading, url, selectedCategories]);


  return (
    <>
      <ModalHeader onClick={handleClose} title={'ADD_CHANNEL'} />
      <form onSubmit={handleSubmit}>
        <Input
          label='CHANNEL_LINK'
          className='sm mb-3'
          type='url'
          placeholder='https://'
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <Select
          label='CATEGORY'
          placeholder='SELECT_CATEGORY'
          className='sm w-50'
          onChange={(e) => handleChangeCategory(e.target.value)}
        >
          {session.categories?.map(c => (
            <option value={c.id} key={c.id}>{c.name}</option>
          ))}

        </Select>
        {selectedCategories?.length
          ?
          <div className="modal-categories-list">
            {selectedCategories.map(c => (
              <div className='modal-categories-item' key={c.id}>
                {c.name}
                <ReactSVG src={close} className='react-icon ms-1 cursor-pointer' onClick={handleRemoveCategory(c.id)}/>
              </div>
            ))}
          </div>
          :
          null
        }
        <div className='d-flex pt-4 mt-4'>
          <button type='button' className='btn btn-outline-primary flex-grow-1 me-2' onClick={handleClose}>
            {t('CLOSE')}
          </button>
          <Loadable loading={loading} className='flex-grow-1 ms-2'>
            <button type='submit' className='btn btn-primary w-100'>
              {t('SEND')}
            </button>
          </Loadable>
        </div>
      </form>
    </>
  );
})

const openAddChannelModal = (): Promise<IMonitoringCategory|null> => asyncModal(AddChannelModal, {}, {
  showCloseIcon: false,
  center: true
});

export {openAddChannelModal};
