import React, { FC } from 'react';
import Loader from './Loader';
import './Loadable.scss';
import {SizeProp} from "@fortawesome/fontawesome-svg-core";

interface Props {
  loading: boolean;
  className?: string;
  children?: any;
  size?: SizeProp;
  onClick?: () => void;
}

const Loadable: FC<Props> = ({ loading, className, children, size = '2x', onClick }) => {
  return (
    <div className={`loadable${loading ? ' loading' : ''} ${className || ''}`} onClick={onClick}>
      {children || null}
      {loading && (
        <div className="spinner">
          <Loader size={size} />
        </div>
      )}
    </div>
  );
};

export default Loadable;
