import asyncModal from 'react-async-modal';
import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ModalClose} from "../../modals";
import {
  EContentPlanVideoStatus,
  IContentPlanVideo,
} from "../../modules/rest";
import {ReactSVG} from "react-svg";
import {formatDate} from "../../modules/utils";
import ContentPlanItemSmall from "./ContentPlanItemSmall";
import camera from "../../assets/images/icons/camera.svg";
import rocket from "../../assets/images/icons/rocket.svg";
import trash from "../../assets/images/icons/trash.svg";
import edit from "../../assets/images/icons/edit.svg";
import useMethodsContentPlan from "./useMethodsContentPlan";
import card_svg from "./assets/images/card.svg";
import Loadable from "../../components/Loadable";
import ModalHeader from "../../modals/ModalHeader";

interface Props {
  item: IContentPlanVideo;

  resolve(updated: boolean): void;

  fetch: () => void;
}


const ContentPlanModalMore: FC<Props> = ({
                                           resolve,
                                           fetch,
                                           item,
                                         }) => {
  const {t} = useTranslation();
  const [contentPlan, setContentPlan] = useState(item);

  const handleSuccess = (method: any, item?: IContentPlanVideo) => {
    if (item) setContentPlan(item);
    if (method !== 'edit') {
      resolve(true);
    } else {
      fetch()
    }
  }

  const {loading, onEdit, onStatusChange, onRemove} = useMethodsContentPlan(handleSuccess);

  const handleClose = () => {
    resolve(false);
  };

  return (
    <>
      <ModalHeader onClick={handleClose} title={formatDate(contentPlan.scheduledAt!)} />
      <div className='content-plan-modal-more'>
        <ContentPlanItemSmall item={contentPlan} more={false}/>
      </div>
      <Loadable loading={loading} className='modal-content-type-edit-small'>
        {item.status !== EContentPlanVideoStatus.VideoPending && (
          <div className='modal-content-type-edit-small-item'
               onClick={() => onStatusChange(contentPlan, EContentPlanVideoStatus.VideoPending)}>
            <ReactSVG src={card_svg} className='react-icon me-1'/>
            <div>{t('FILM_VIDEO')}</div>
          </div>
        )}
        {item.status !== EContentPlanVideoStatus.VideoReady && (
          <div className='modal-content-type-edit-small-item'
               onClick={() => onStatusChange(contentPlan, EContentPlanVideoStatus.VideoReady)}>
            <ReactSVG src={camera} className='react-icon me-1'/>
            <div>{t('VIDEO_TAKEN')}</div>
          </div>
        )}
        {item.status !== EContentPlanVideoStatus.VideoUploaded && (
          <>
            <div className='modal-content-type-edit-small-item'
                 onClick={() => onStatusChange(contentPlan, EContentPlanVideoStatus.VideoUploaded)}>
              <ReactSVG src={rocket} className='react-icon'/>
              <span>{t('UPLOADED')}</span>
            </div>
            <div className='modal-content-type-edit-small-item'
                 onClick={() => onEdit(contentPlan)}>
              <ReactSVG src={edit} className='react-icon'/>
              <span>{t('EDIT')}</span>
            </div>
            <div className='modal-content-type-edit-small-item'
                 onClick={() => onRemove(contentPlan)}>
              <ReactSVG src={trash} className='react-icon'/>
              <span className='text-danger'>{t('REMOVE')}</span>
            </div>
          </>
        )}

      </Loadable>

    </>
  );
};

const openContentPlanModalMore = (item: IContentPlanVideo, fetch: () => void): Promise<boolean> => asyncModal(ContentPlanModalMore, {
  item,
  fetch
}, {
  showCloseIcon: false,
  center: true,
  blockScroll: false,
  classNames: {modal: 'add-channel-modal', modalContainer: 'bottom-modal'},
});

export {openContentPlanModalMore};
