import React, {FC, InputHTMLAttributes, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import search_svg from '../../assets/images/icons/search.svg';

interface Props extends InputHTMLAttributes<any> {
  className?: string;
  inputClassName?: string;
  small?: boolean;
  onSearch: (value: string) => void;
  onStartTyping?: () => void;
  label?: string;
}

const SearchInput: FC<Props> = ({
                                  small,
                                  className,
                                  inputClassName,
                                  onSearch,
                                  onStartTyping,
                                  label,
                                  ...props
                                }) => {
  const {t} = useTranslation();
  const _timer: any = useRef(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState(props.value || '');

  useEffect(() => {
    return () => clearTimeout(_timer?.current);
  }, []);

  const handleChange = useCallback((e: any) => {
    clearTimeout(_timer?.current);
    if (onStartTyping) onStartTyping();
    setSearchTerm(e.target.value);
    _timer.current = setTimeout(() => {
      onSearch(e.target.value);
    }, 300);
  }, [onStartTyping, onSearch]);

  return (
    <div className={`form-group ${className || ''}`}>
      {label ?
        <label className={`muted mb-2`}>
          <span>{t(label)}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        : null
      }
      <div className={`search-input${small ? ' small' : ''}${inputVisible || searchTerm ? ' visible' : ''}`}>
      <span className='input-icon'>
        <ReactSVG src={search_svg} className='react-icon'/>
      </span>
        <input
          onFocus={() => setInputVisible(true)}
          onBlur={() => setInputVisible(false)}
          type='text'
          className={`form-control ${inputClassName || ''}`}
          placeholder={t('SEARCH') || ''}
          value={searchTerm || ''}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default SearchInput;