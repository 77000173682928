import React, {FC, InputHTMLAttributes} from 'react';

import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import CloseBtn from "../CloseBtn";

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  className?: string;
  errorText?: string;
  error?: boolean;
  clearable?: boolean;
  icon?: any;
}

const Input: FC<Props> = ({
                            label,
                            className,
                            icon,
                            error,
                            errorText,
                            clearable,
                            ...props
                          }) => {
  const {t} = useTranslation();
  return (
    <div className={`form-group${error ? ' error' : ''}${clearable ? ' clearable' : ''} ${className || ''}`}>
      {label ?
        <label className={`muted`}>
          <span>{t(label)}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        : null
      }
      {errorText && error ? <div className='form-group-error'>{t(errorText)}</div> : null}
      <div className='form-control-wrap'>
        <input
          className={`form-control${icon ? ' form-control-icon' : ''}`}
          {...props} name={String(props?.value)}
          autoComplete='new-input'
        />
        <div className='form-control-border'/>
        <div className='form-control-gradient'/>
        {icon ? <div className='input-icon'><ReactSVG src={icon} className='react-icon'/></div> : null}

        {(clearable && props.value) &&
          //@ts-ignore
          <CloseBtn className='input-clear' onClick={(e) => props.onChange({...e, target: {...e.target, value: ''}})}/>
        }

        {props.placeholder && (props.type === 'date' || props.type === 'time') && !props.value
          ?
          <div className="form-control-placeholder">{props.placeholder}</div>
          :
          null
        }
      </div>
    </div>
  );
};

export default Input;