import React, {FormEvent, useCallback, useState} from 'react';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Input} from "../../components/FormControls";
import AuthWrap from "./AuthWrap";
import Button from "../../components/Button";

const PasswordRoute = () => {
  const {t} = useTranslation();
  const [email, setEmail] = useState(localStorage.getItem('email') ?? '');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Users.beginPasswordReset({email})
        .then(() => {
          toast.success(t('auth:CHECK_EMAIL'));
          navigate('/login', {replace: true});
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [email, setLoading, navigate]
  );

  return (
    <AuthWrap loading={loading} onSubmit={submit}>
      <Input
        className="mb-4"
        label={'auth:EMAIL'}
        placeholder={t('auth:EMAIL_PLACEHOLDER') || ''}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoFocus
        type="email"
        required
      />
      <div className="d-flex justify-content-between">
        <Button type="submit" text='auth:RESET_PASSWORD' className='flex-grow-1 me-2'/>
        <Button
          text='BACK'
          className='flex-grow-1 ms-2'
          onClick={() => navigate('/login', {replace: true})}
          color='secondary'
        />
      </div>
    </AuthWrap>

  );
};

export default PasswordRoute;
