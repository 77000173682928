import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  show: boolean;
  text?: string;
  className?: string
  style?: any;
}

const Empty: FC<Props> = ({show, text = 'NO_DATA', className, style}) => {
  const {t} = useTranslation();
  if (!show) return null;
  return (
    <div className={`p-3 text-center ${className || ''}`} style={style}>
      <span className="text-center text-muted-14 text-medium">{t(text || 'EMPTY_LIST')}</span>
    </div>
  );
};

export default Empty;
