import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import get_video_svg from "../../assets/images/icons/get_video.svg";
import Button from "../../components/Button";
import HowInsertVideoAnimated from "../../components/HowInsertVideoAnimated";
import {openActiveMonetizationModal} from "../../components/Aside/AsideMonetization/AsideMonetizationModal";

interface Props {
  onRequestVideoEmbed: () => void;
  fetch: (page: number) => void;
}

const VideosTopPromo: FC<Props> = ({onRequestVideoEmbed, fetch}) => {
  const {t} = useTranslation();

  const handleHowWorksClick = () => {
    openActiveMonetizationModal(true).then(res => {
      if (res) {
        fetch(1);
      }
    });
  }

  return (
    <div className='videos-promo'>
      <div className='videos-promo-content'>
        <div className='videos-promo-left'>
          <div className='videos-promo-left-text'>
            <h1>{t('PROMOVIDEO')}</h1>
            <div className='text-muted mt-1 text-pre-wrap text-center pb-2'>{t('PROMOVIDEO_TEXT')}</div>
          </div>
          <Button
            className='pe-1 mt-4 btn-circle-icon'
            text='REQUEST_VIDEO'
            iconSvg={get_video_svg}
            onClick={onRequestVideoEmbed}
          />
        </div>
        <div className='videos-promo-right'>
          <div className='videos-promo-right-head'>
            <div className='text-muted-14'>{t('HOW_INSERT_VIDEO')}</div>
            <div className='text-tint-active text-14' onClick={handleHowWorksClick}>{t('HOW_IT_WORK_2')}</div>
          </div>
          <HowInsertVideoAnimated className='videos-promo-right-bottom'/>
        </div>
      </div>
    </div>
  );
}

export default VideosTopPromo;