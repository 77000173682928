import React, {FC} from 'react';
import ContentTypeTrends from "../../components/ContentType/ContentTypeTrends";
import {useTranslation} from "react-i18next";
import TrendsAside from "../../components/TrendsAside";
import {EContentPlanVideoOrigin} from "../../modules/rest";

interface Props {
}

const TrendsPage: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <div className='content-type-page trends-page'>
      <h1>{t('TRENDS')}</h1>
      <div className="text-muted mb-4">
        {t('TRENDING_TEXT')}
      </div>
      <div className='content-type-page__content'>
        <TrendsAside type={EContentPlanVideoOrigin.Trending} />
        <ContentTypeTrends maxTagsLength={7}/>
      </div>
    </div>
  );
}

export default TrendsPage;