import React, {FC, useEffect, useRef, useState} from 'react';
import geos from "../../translates/en/geos.json";
import OutsideClick from "../../components/OutsideClick";
import {ECountry} from "../../modules/rest";

interface Props {
  tabs?: ECountry[];
  onClick: (country: ECountry) => void;
  active?: string;
  disabled?: boolean
}

const BestReactionsTabs: FC<Props> = ({tabs, onClick, active, disabled}) => {
  const _scroll: any = useRef(null);
  const [visible, setVisible] = useState(false);

  const handleClick = (country: ECountry) => () => {
    if(disabled) return;
    onClick(country);
    setVisible(false);
  }

  useEffect(() => {
    const idx = tabs?.findIndex(item => item === active);
    if(idx !== -1) {
      const elementsNode = _scroll.current?.childNodes;
      let nodeArr:any = []
      for (let i = 0; i < elementsNode.length; i++) {
        nodeArr.push(elementsNode[i])
      }
      let width = (nodeArr?.slice(0, idx! + 1).reduce((accumulator: number, currentValue: any) => accumulator + currentValue.getBoundingClientRect().width, 0) || 0) - 24;
      if (width < 400) width = 0
        _scroll.current?.scrollTo(width, 0)
    }
  }, [active]);

  if (!tabs?.length) return null;

  return (
    <OutsideClick
      outsideClickEvent={() => setVisible(false)}
      className={`aside-submenu best-reactions-modal-tabs${visible ? ' visible' : ''}${disabled ? ' disabled' : ''}`}
    >
      {/*<div className="aside-submenu-bg"/>*/}
      <div className='aside-submenu best-reactions-modal-tabs-head'>
        <div className='best-reactions-modal-tabs-content' ref={_scroll}>
          {tabs.map(item => (
            <div
              className={`aside-submenu-item${item === active ? ' active' : ''}`}
              onClick={handleClick(item)}
              key={item}
              id={`best-reactions-modal-tab_${item}`}
            >
              {/*@ts-ignore*/}
              {geos[item.toUpperCase()]?.name || item}
            </div>
          ))}
        </div>
        {tabs.length > 4 && <div className="best-reactions-modal-tabs-arrow" onClick={() => setVisible(prevState => !prevState)}/>}
      </div>
      <div className="best-reactions-modal-tabs-hidden">
        {tabs.map(item => (
          <div className={`best-reactions-modal-tabs-hidden-item${item === active ? ' active' : ''}`}
               onClick={handleClick(item)}>
            {/*@ts-ignore*/}
            {geos[item.toUpperCase()]?.name || item}
          </div>
        ))}
      </div>
    </OutsideClick>
  );
}

export default BestReactionsTabs;