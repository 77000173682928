import asyncModal from 'react-async-modal';
import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import ModalHeader from "../ModalHeader";
import moment from "moment";
import {ITransaction} from "../../modules/rest";
import BillingWithdrawStatus from "../../routes/billing/BillingWithdrawStatus";

interface Props {
  item: ITransaction

  resolve(): void;
}

const BillingHistoryItemModal: FC<Props> = ({item, resolve}) => {
  const {t} = useTranslation();

  return (
    <div className='billing-history-item-modal'>
      <ModalHeader onClick={() => resolve()} title={moment(item.createdAt).format('YYYY.MM.DD, HH:mm')}
                   needTitleTranslate={false}/>
      <div className='d-flex mt-2'>
        <div className='text-muted-14 pe-2'>{t('AMOUNT')}:</div>
        <div className={`text-14 ${item.amount < 0 ? ' text-danger' : ''}`}>{item.amount < 0 ? '- ' : ''}${item.amount}</div>
      </div>
      <div className='d-flex mt-2'>
        <div className='text-muted-14 pe-2'>{t('NOTE')}:</div>
        <div className={`text-14`}>{item.comment}</div>
      </div>
    </div>
  );
};

const openBillingHistoryItemModal = (item: ITransaction): Promise<void> => asyncModal(BillingHistoryItemModal, {
  item,
}, {
  showCloseIcon: false,
  center: true
});

export {openBillingHistoryItemModal};
