import React, {FC, useState} from 'react';
import ContentPlan from "./ContentPlan";
import {EContentPlanVideoStatus, IContentPlanVideo} from "../../modules/rest";
import session from "../../modules/session";
import {useTranslation} from "react-i18next";
import {openComposeChannelToContentPlanModal} from "../../modals";
import {Input} from "../../components/FormControls";
import Loadable from "../../components/Loadable";
import {useLocation, useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import useFetchContentPlan from "./useFetchContentPlan";

interface Props {
}


const ContentPlanPage: FC<Props> = () => {
  const {t} = useTranslation();
  const location = useLocation()
  const navigate = useNavigate();
  const [activeTabIdx, setActiveTabIdx] = useState(location.pathname.includes('archive') ? 1 : 0);

  const {ready, loading, contentPlan, contentPlanSmall, date, getContentPlan, onDateChange} = useFetchContentPlan({
    channelId: session.activeChannel?.id!,
    isArchive: activeTabIdx === 1,
    dependencies: [activeTabIdx]
  })


  const handleTabChange = (idx: number) => () => {
    if (idx === activeTabIdx) return;
    if (idx === 0 && location.pathname.includes('archive')) navigate('/content-plan', {replace: true});
    setActiveTabIdx(idx);
  }

  const handleAdd = () => {
    openComposeChannelToContentPlanModal().then((success) => {
      if (success) getContentPlan();
    });
  }

  return (
    <Loadable loading={loading && ready} className='content-plan-page'>
      <div className='d-flex align-items-center justify-content-between'>
        <h1>{t('CONTENT_PLAN')}</h1>
        <div className='content-plan-add-text' onClick={handleAdd}>
          {t('ADD')}
        </div>
      </div>
      <div className='text-muted mt-1'>{t('CONTENT_PLAN_TEXT')}</div>
      <div className='content-plan-header'>
        <div className="content-plan-header-tabs-wrapper">
          <div className='content-plan-header-tabs'>
            <div className='my-tabs-item-wrap'>
              <div className={`my-tabs-item${activeTabIdx === 0 ? ' active' : ''}`}
                   onClick={handleTabChange(0)}>{t('PLANNED')}</div>
            </div>
            <div className='my-tabs-item-wrap'>
              <div className={`my-tabs-item${activeTabIdx === 1 ? ' active' : ''}`}
                   onClick={handleTabChange(1)}>{t('ARCHIVE')}</div>
            </div>
          </div>
        </div>
        {activeTabIdx === 0
          ?
          <button className='btn btn-outline-primary btn-lg btn-add' onClick={handleAdd}>
            {t('ADD')}
          </button>
          :
          <div className='content-plan-page-range'>
            <div className='text-muted content-plan-page-range-text'>{t('FROM')}</div>
            <Input
              value={date.dateFrom || ''}
              onChange={onDateChange('dateFrom')}
              className='sm me-3 content-plan-date-from'
              type='date'
              onKeyDown={(e) => e.preventDefault()}
            />
            <div className='ms-3 text-muted content-plan-page-range-text'>{t('TILL')}</div>
            <Input
              onKeyDown={(e) => e.preventDefault()}
              value={date.dateTill || ''}
              onChange={onDateChange('dateTill')}
              className='sm content-plan-date-till'
              type='date'
              // max={yesterday}
              required
            />
          </div>
        }
      </div>
      <ContentPlan
        isArchive={Boolean(activeTabIdx === 1)}
        ready={ready}
        className={activeTabIdx === 0 ? 'first-tab-active' : ''}
        fetch={getContentPlan}
        data={contentPlan}
        smallData={contentPlanSmall}
      />
    </Loadable>
  );
}


export default observer(ContentPlanPage);