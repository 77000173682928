import {useState, useLayoutEffect} from 'react';
import {smallestScreen} from "../modules/utils";

const useScreenSize = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
  const [isSmallestScreen, setIsSmallestScreen] = useState(window.innerWidth < 1280);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1280)
      setIsSmallestScreen(smallestScreen())
    };
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {isSmallScreen, isSmallestScreen};
};

export default useScreenSize;