import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import BillingCashOutItem from "./BillingCashOutItem";
import cashout from './assets/icons/cashout.svg'
import {ReactSVG} from "react-svg";
import {IWithdrawMethodInfo} from "../../modules/rest";
import {openCreateWithdrawModal} from "../../modals";

interface Props {
  data: IWithdrawMethodInfo[];
  fetch: () => void;
}


const BillingCashOut: FC<Props> = ({data, fetch}) => {
  const {t} = useTranslation();

  const handleClick = (item: IWithdrawMethodInfo) => () => {
    openCreateWithdrawModal(item).then(success => {
      if(success) fetch()
    })
  }

  return (
    <div className='billing-cashout'>
      <div className='ps-0 ps-lg-3 mb-3 mb-lg-4'>
        <div className='d-flex align-items-center'>
          <ReactSVG src={cashout} className='react-icon me-2'/>
          <h2>{t('CASHOUT')}</h2>
        </div>
        <div className='text-muted mt-1 mt-lg-2'>{t('CHOOSE_CASHOUT')}</div>
      </div>
      <div className='billing-cashout-list'>
        {data.map((item, i) => (
          <BillingCashOutItem item={item} key={i} onClick={handleClick(item)}/>
        ))}
      </div>

    </div>
  );
}

export default BillingCashOut;