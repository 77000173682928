import React, {FC} from 'react';
import Loader from "../../components/Loader";
import {ReactSVG} from "react-svg";
import refresh from "../../assets/images/icons/refresh_2.svg";
import {useTranslation} from "react-i18next";
import {IResearchCountry} from "../../modules/rest";
import moment from "moment";

interface Props {
  loading: boolean;
  data?: IResearchCountry;
  onClick: () => void;
}

const BestReactionsUpdate: FC<Props> = ({loading, onClick, data}) => {
  const {t} = useTranslation();

  if (!data) return null;

  return (
    <div className='best-reactions-modal-update'>
      {data.updatedAt && (
        <div className='text-muted-14'>
          {t('LAST_UPDATE')}:<span
          className='text-lowercase ps-1'>{moment(data.updatedAt).calendar()}</span>
        </div>
      )}
      <BestReactionsUpdateContent canUpdate={data.canUpdate} loading={loading} onClick={onClick} />
    </div>
  )
}

export default BestReactionsUpdate;

const BestReactionsUpdateContent = ({canUpdate, loading, onClick}: any) => {
  const {t} = useTranslation();

  if (!canUpdate) return null;

  if (loading) return (
    <div className='d-flex align-items-center ms-2'>
      <Loader size={'sm'}/>
      <span className='text-muted-14 ms-1' style={{color: 'rgba(255, 255, 255, 0.2)'}}>{t('UPDATING')}</span>
    </div>
  )

  return (
    <div onClick={onClick} className='d-flex align-items-center ms-2 text-14 cursor-pointer'>
      <ReactSVG src={refresh} className='react-icon'/>
      {t('UPDATE')}
    </div>
  );

}