import asyncModal from 'react-async-modal';
import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {EWithdrawStatus, IWithdraw} from "../../modules/rest";
import {systemsLogo} from "../../routes/billing/BillingPage";
import BillingWithdrawStatus from "../../routes/billing/BillingWithdrawStatus";
import moment from "moment/moment";
import {ModalClose} from "../index";
import {ReactSVG} from "react-svg";
import chevron_sm from '../../assets/images/icons/chevron_sm.svg';
import message_icon from '../../assets/images/icons/error_message.svg';
import BillingPaymentDetailsModal from "./BillingPaymentDetailsModal";

interface Props {
  item: IWithdraw

  resolve(): void;
}

const BillingPaymentItemModal: FC<Props> = ({item, resolve}) => {
  const {t} = useTranslation();
  const [infoVisible, setInfoVisible] = useState(false);

  const toggleVisible = () => {
    setInfoVisible(prevState => !prevState);
  }

  const success = item.status === EWithdrawStatus.Finished;

  return (
    <div className='modal-without-padding billing-payment-modal'>
      <ModalClose onClick={resolve}/>
      <div className='billing-payment-modal-top'>
        <div className='modal-title'>
          <h2>{t('BILLING_REQUESTS')}</h2>
        </div>
        <div className={`billing-payment-modal-top-card ${item.status}`}>
          <div className='billing-payment-modal-top-card-top'>
            <div className='flex-1 pe-3'>
              <img src={systemsLogo[item.method]} alt="system"/>
            </div>
            <BillingWithdrawStatus item={item} hideIcon/>
          </div>
          <div className='d-flex p-3 align-items-center justify-content-between'>
            <div className='text-muted'>{moment(item.createdAt).format('YYYY.MM.DD, HH:mm')}</div>
            <div>${item.amount}</div>
          </div>
        </div>

        <div className={`billing-payment-modal-more${infoVisible ? ' visible' : ''}`} onClick={toggleVisible}>
          <div className="billing-payment-modal-more-line"/>
          <div className='billing-payment-modal-more-text'>
            <span>{t('MORE')}</span>
            <ReactSVG src={chevron_sm} className='react-icon'/>
          </div>
          <div className="billing-payment-modal-more-line"/>
        </div>
        {infoVisible &&
          <BillingPaymentDetailsModal details={item.details} className='billing-payment-modal-more-info'/>
        }
      </div>
      {item.comment
        ?
        <>
          <div className={`billing-payment-modal-comment ${item.status}`}>
            <div className='d-flex align-items-center mb-2'>
              <ReactSVG src={message_icon} className='react-icon me-2'/>
              <div className={`text-bold`}>{t('DETAILS')}</div>
            </div>
            <p className='text-muted-14'>{item.comment}</p>
          </div>
        </>
        :
        null
      }
    </div>
  );
};

const openBillingPaymentItemModal = (item: IWithdraw): Promise<void> => asyncModal(BillingPaymentItemModal, {
  item,
}, {
  showCloseIcon: false,
  center: true,
  blockScroll: false,
});

export {openBillingPaymentItemModal};
