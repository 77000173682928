import React, {FC, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {mainMenu, monetizationMenu, monetizationSectionPath, TSection} from "../../routes/AppRouter";
import {ReactSVG} from "react-svg";
import chevron from '../../assets/images/icons/chevron_sm.svg';
import {openSectionsNavModal} from "./SectionsNavModal";

interface Props {
  sectionName?: TSection
}

const HeaderBreadcrumbs: FC<Props> = ({sectionName}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const name = useMemo(() => {
    const menuList = sectionName === 'monetization' ? monetizationMenu : mainMenu;
    if (location.pathname === '/') return "DASHBOARD"
    const menu = menuList.find(item => {
      if (item.path !== '/') return location.pathname.includes(item.path)
    })
    return menu?.title || '';
  }, [location.pathname, sectionName]);

  const handleSectionClick = () => {
    openSectionsNavModal(sectionName).then((section: TSection) => {
      if(section) {
        let to = '/';
        if(section === 'monetization') to = monetizationSectionPath;
        navigate(to);
      }
    })
  }

  return (
    <div className='header-breadcrumbs'>
      <div className='text-muted cursor-pointer d-flex align-items-center' onClick={handleSectionClick}>
        <span>{t(sectionName === 'monetization' ? 'MONETIZE' : 'SCANNER')}</span>
        <ReactSVG src={chevron} className='react-icon ms-1 opacity-50' />
      </div>
      <div className='text-bold'>{t(name)}</div>
    </div>
  );
}

export default HeaderBreadcrumbs;