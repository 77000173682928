import asyncModal from 'react-async-modal';
import React, {FC, useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";

import {ModalClose, openRequestVideoModal} from "../../../modals";
import AsideMonetizationModalForm from "./AsideMonetizationModalForm";
import VideosListEmpty from "../../../routes/videos/VideosListEmpty";
import ModalHeader from "../../../modals/ModalHeader";

interface Props {
  onlyHowWorks?: boolean;

  resolve(channelId: boolean): void;
}


const AsideMonetizationModal: FC<Props> = ({resolve, onlyHowWorks}) => {
  const {t} = useTranslation();
  const [step, setStep] = useState<number>(onlyHowWorks ? 1 : 0);

  const handleClose = useCallback(() => {
    resolve(false);
  }, [resolve]);

  const handleRequestVideoEmbed = () => {
    openRequestVideoModal().then(res => {
      if (res) {
        resolve(true);
      }
    })
  };

  return (
    <>
      <div className='aside-monetization-modal'>
        <ModalHeader onClick={handleClose} title={'ACTIVATE_MONETIZATION'} />
        {!onlyHowWorks &&
          <div className='aside-submenu m-0 justify-content-around mb-4'>
            <div className="aside-submenu-bg"/>
            <div className={`aside-submenu-item${step === 0 ? ' active' : ''}`}
                 onClick={() => setStep(0)}>{t('REQUEST_ACCESS')}</div>
            <div className={`aside-submenu-item${step === 1 ? ' active' : ''}`}
                 onClick={() => setStep(1)}>{t('HOW_IT_WORK')}</div>
          </div>
        }
        {step === 0 && <AsideMonetizationModalForm onClose={handleClose}/>}
        {/*{step === 1 && <AsideMonetizationModalHowWorks />}*/}
        {step === 1 && <VideosListEmpty onClick={handleRequestVideoEmbed}/>}
      </div>
    </>
  );
};

const openActiveMonetizationModal = (onlyHowWorks?: boolean): Promise<boolean> => asyncModal(AsideMonetizationModal, {onlyHowWorks}, {
  classNames: {modal: 'add-channel-modal'},
  showCloseIcon: false,
  center: true,
  closeOnOverlayClick: false,
  blockScroll: false,
});

export {openActiveMonetizationModal};
