import React, {FC} from 'react';

interface Props {
  onClick: (e: any) => void;
  className?: string;
}

const CloseBtn: FC<Props> = ({onClick, className}) => {

  return (
    <div className={`close-btn ${className || ''}`} onClick={onClick} />
  );
}

export default CloseBtn;