import React, {FC, useCallback, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {observer, useLocalObservable} from "mobx-react";
import {runInAction} from "mobx";
import {toast} from "react-toastify";
import {API} from "../../modules/api";
import {EFieldGroup, IGetVideoEmbedsRequest, IPagedData, IVideoEmbed} from "../../modules/rest";
import VideosList from "./VideosList";
import {openRequestVideoModal} from "../../modals";
import Loadable from "../../components/Loadable";
import Pagination from "../../components/Pagination";

import './assets/videos.scss'
import VideosListEmpty from "./VideosListEmpty";
import VideosTopPromo from "./VideosTopPromo";
import Loader from "../../components/Loader";

interface State {
  pager?: IPagedData<IVideoEmbed>;
  request: IGetVideoEmbedsRequest;
  loading: boolean;
  activeVideo?: number|null;
}

const VideosPage: FC = () => {
  const {t} = useTranslation();

  const st = useLocalObservable<State>(() => ({
    loading: true,
    request: {
      page: 1,
      limit: 20
    },
    author: null,
  }));

  useEffect(() => {
    fetch();
  }, [])

  const fetch = useCallback((page?: number) => {
    runInAction(() => (st.loading = true));
    API.VideoEmbeds.getList(st.request, [
      EFieldGroup.VideoEmbedStatus,
      EFieldGroup.VideoEmbedStats,
      EFieldGroup.VideoEmbedVideo,
      EFieldGroup.AlbumLinks,
      EFieldGroup.VideoEmbedLanguage,
    ])
      .then((pager) => {
        runInAction(() => {
          st.pager = pager;
          st.request.page = pager.page;
          st.request.limit = pager.limit;
        });
      })
      .catch(toast.error)
      .finally(() => runInAction(() => (st.loading = false)));
  }, [st.request]);


  const handleRequestVideoEmbed = useCallback(() => {
    runInAction(() => {
      st.activeVideo = null;
    })
    openRequestVideoModal().then(res => {
      if (res) {
        fetch();
      }
    })
  }, [fetch]);

  // return <VideosListEmpty onClick={handleRequestVideoEmbed}/>
  if (!st.pager?.data) return <Loader />;
  if (!st.pager?.data?.length) return <VideosListEmpty onClick={handleRequestVideoEmbed}/>
  // return <VideosListEmpty onClick={handleRequestVideoEmbed}/>

  return (
    <>
      <VideosTopPromo onRequestVideoEmbed={handleRequestVideoEmbed} fetch={fetch}/>
      {/*<div className='page-header'>*/}
      {/*  <Button*/}
      {/*    className='pe-1'*/}
      {/*    text='REQUEST_VIDEO'*/}
      {/*    iconSvg={get_video_svg}*/}
      {/*    onClick={handleRequestVideoEmbed}*/}
      {/*  />*/}
      {/*</div>*/}
      <hr className='my-3 my-lg-4'/>
      <Loadable loading={st.loading}>
        <div className='d-flex align-items-center mb-3 mb-lg-4'>
          <div className='text-muted-14 tracks-list-item-id' >#</div>
          <h2 className='flex-1'>{t('YOUR_EMBED_VIDEO')}</h2>
          <button className='btn btn-outline-primary btn-lg btn-add videos-page-add-btn' onClick={handleRequestVideoEmbed}>
            {t('ADD')}
          </button>
          <div className='videos-page-add-text' onClick={handleRequestVideoEmbed}>
            {t('ADD')}
          </div>
        </div>
        <VideosList
          onChangeActiveVideo={(id) => {
            runInAction(() => {
              st.activeVideo = st.activeVideo === id ? null : id;
            })
          }}
          activeVideo={st.activeVideo}
          //@ts-ignore
          videos={st.pager?.data}
          fetch={fetch}
        />
        <Pagination
          className='mt-3'
          onPageChange={(page) =>
            runInAction(() => {
              st.request.page = page;
              fetch();
            })
          }
          onLimitChange={(limit) =>
            runInAction(() => {
              st.request.page = 1;
              st.request.limit = limit;
              fetch();
            })
          }
          pager={st.pager}
        />
      </Loadable>
    </>
  );
}

export default observer(VideosPage);