import React, {FC, useState} from 'react';
import {IVideoEmbed} from "../../modules/rest";
import {ReactSVG} from "react-svg";
import video_play from '../../assets/images/icons/add_video.svg';
import caret_top from '../../assets/images/icons/caret_top.svg';
import {useTranslation} from "react-i18next";
import VideoEmbedSelectItem from "./VideoEmbedSelectItem";
import OutsideClick from "../OutsideClick";
import {getEmbedName, thumb} from "../../modules/utils";

interface Props {
  value?: IVideoEmbed|IVideoEmbed[];
  options?: IVideoEmbed[];
  onChange?: (ve: IVideoEmbed) => void;
  className?: string;
  placeholder?: string;
  error?: boolean;
  singleLine?: boolean;
}

const VideoEmbedSelect: FC<Props> = ({
                                       value,
                                       onChange,
                                       className,
                                       error,
                                       options,
                                       placeholder,
                                       singleLine,
                                     }) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);
  const [activeVideo, setActiveVideo] = useState<number|null>();

  const handleChangeActiveVideo = (id: number) => () => {
    setActiveVideo(prevState => (activeVideo === id ? null : id));
  }

  const handleClick = (ve: IVideoEmbed) => () => {
    if (onChange) onChange(ve);
    if (singleLine) setVisible(false);
    setActiveVideo(null);
  }

  const valueIsArray = Array.isArray(value);

  return (
    <OutsideClick outsideClickEvent={() => setVisible(false)}
                  className={`video-embed-select${visible ? ' visible' : ''}${error ? ' error' : ''}${singleLine ? ' single-line' : ''} ${className || ''}`}>
      <div className="video-embed-select-input" onClick={() => setVisible(prevState => !prevState)}>
        {value && !valueIsArray
          ?
          <>
            <img src={thumb(value.preview?.id!, 128)} alt='preview'/>
            <div className='text-muted flex-1 ps-1 text-truncate'>{getEmbedName(value)}</div>
          </>
          :
          <>
            <ReactSVG src={video_play} className='react-icon px-2 ms-1'/>
            <div className='text-muted flex-1 ps-1'>{t(placeholder || 'SELECT_VIDEO_EMBED')}</div>
          </>
        }
        <ReactSVG src={caret_top} className='react-icon video-embed-select-caret'/>
      </div>
      <div className="video-embed-select-options">
        {options?.map(ve => (
          <VideoEmbedSelectItem
            checked={valueIsArray ? Boolean(value.find(v => v.id === ve.id)) : value?.id === ve.id}
            activeVideo={activeVideo}
            ve={ve}
            key={ve.id}
            onChangeActiveVideo={handleChangeActiveVideo(ve.id)}
            onClick={handleClick(ve)}
            singleLine={singleLine}
          />
        ))}
      </div>

    </OutsideClick>
  );
}

export default VideoEmbedSelect;