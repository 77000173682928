import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import copy_svg from "../assets/images/icons/copy_gray.svg";
import check from "../assets/images/icons/done_white.svg";
import copy from 'copy-to-clipboard';

interface Props {
  className?: string;
  text?: string;
  children?: any;
}


const CopyToClipboard: FC<Props> = ({text, className, children}) => {
  const {t} = useTranslation();
  const _timer: any = useRef(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(_timer?.current);
    }
  }, []);

  const handleClick = (e: any) => {
    e.stopPropagation()
    clearTimeout(_timer?.current);
    setCopied(true);
    copy(text || '')
    // navigator?.clipboard?.writeText(text || '');
    _timer.current = setTimeout(() => {
      setCopied(false);
    }, 1500)
  }


  if (children) return <div onClick={handleClick}>{children(copied)}</div>
  return (
    <div className={`copy-to-clipboard ${className || ''}`} onClick={handleClick} title={t('COPY') || ''}>
      <ReactSVG src={copy_svg} className={`react-icon${!copied ? ' visible' : ''}`}/>
      <ReactSVG src={check} className={`react-icon position-absolute${copied ? ' visible' : ''}`}/>
      {/*<div className={`copy-to-clipboard-title${copied ? ' visible' : ''}`}>{t('COPIED')}</div>*/}
    </div>
  );
}

export default CopyToClipboard;