import {ELanguage, IGenerateVideoEmbedTextsTask, IVideo, IVideoEmbed} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC, FormEvent, useCallback, useState} from 'react';
import Loadable from '../../components/Loadable';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import {Checkbox, Input} from '../../components/FormControls';
import {useTranslation} from "react-i18next";
import ModalClose from "../ModalClose";
import Button from "../../components/Button";
import link_svg from '../../assets/images/icons/link.svg';
import ImportantBlock from "../../components/ImportantBlock";
import TextGenerate from "../../components/TextGenerate";
import ModalHeader from "../ModalHeader";

interface Props {
  ve: IVideoEmbed;

  resolve(video: IVideo|null): void;
}

const AddVideoToTrackModal: FC<Props> = ({ve, resolve}) => {
  const {t} = useTranslation();
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showForce, setShowForce] = useState<boolean>(false);
  const [force, setForce] = useState<boolean>(false);
  const [generateTexts, setGenerateTexts] = useState<any>({});

  const handleUpdateGenTexts = useCallback((text: IGenerateVideoEmbedTextsTask, language: ELanguage) => {
    setGenerateTexts((prevState: any) => ({...prevState, [language]: text}));
  }, []);

  const handleClose = () => {
    resolve(null)
  }

  const submit = useCallback((e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    API.VideoEmbeds.addYoutubeVideo(ve.id, {url, force})
      .then((video) => {
        toast.success(t('VIDEO_ADDED_SUCCESS'));
        resolve(video);
      })
      .catch((error) => {
        toast.error(error);
        setShowForce(true);
      })
      .finally(() => setLoading(false));
  }, [url, ve.id, resolve, force]);

  return (
    <>
      <Loadable loading={loading}>
        <form onSubmit={submit}>
          <ModalHeader onClick={handleClose} title={'ADD_VIDEO'} />

          <div className='text-muted mb-2 pb-1'>{t('ADD_VIDEO_TEXT')}</div>
          <div className='d-flex flex-column-reverse'>
            {showForce &&
              <div className={`video-exist${force ? ' active' : ''}`}>
                <Checkbox checked={force} onChange={e => setForce(e.target.checked)} label='VIDEO_EXIST'/>
                <div className='text-muted-14 mt-2 ps-4 ms-3'>{t('VIDEO_EXIST_TEXT')}</div>
              </div>
            }
            <Input
              icon={link_svg}
              className='mb-3 sm'
              required
              type='url'
              placeholder={t('LINK_TO_VIDEO') || ''}
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
                setShowForce(false);
                setForce(false);
              }}
            />
          </div>
          {/*<TextGenerateResultItem label={'CLIPBOARD_TITLE'} text={dt.track?.album?.links?.array} />*/}
          {/*<CopyToClipboard links={dt.track?.album?.links?.array}/>*/}
          <div className='text-medium mb-2'>{t('CLIPBOARD_TITLE')}</div>
          <TextGenerate
            videoEmbedId={ve.id}
            onUpdate={handleUpdateGenTexts}
            generateTexts={generateTexts}
          />
          <ImportantBlock text={'VIDEO_EMBED_IMPORTANT_TEXT'} title='VIDEO_EMBED_IMPORTANT_TITLE'
                          className='add-video-modal-important'/>
          <div className="modal-footer-btns">
            <Button
              text='CANCEL'
              onClick={handleClose}
              color='outline-primary'
            />
            <Button
              disabled={showForce && !force}
              text='ADD'
              type='submit'
            />
          </div>
        </form>
      </Loadable>
    </>
  );
};

const addVideoModal = (ve: IVideoEmbed): Promise<IVideo|null> => asyncModal(AddVideoToTrackModal, {ve}, {
  showCloseIcon: false,
  blockScroll: false,
  center: true,
});

export {addVideoModal};
