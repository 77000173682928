import asyncModal from 'react-async-modal';
import React, { FC, FormEvent, useCallback, useState} from 'react';
import Loadable from '../Loadable';
import {useTranslation} from "react-i18next";
import {ModalClose} from "../../modals";
import {EContentPlanVideoOrigin, ECountry} from "../../modules/rest";
import {observer} from "mobx-react";
import session from "../../modules/session";
import {toast} from "react-toastify";
import ChannelMonitoringPage from "../../routes/channelMonitoring";
import ContentTypeTags from "./ContentTypeTags";
import {ReactSVG} from "react-svg";
import edit_svg from "../../assets/images/icons/edit.svg";
import info_svg from "../../assets/images/icons/info_white.svg";
import ModalHeader from "../../modals/ModalHeader";

interface Props {
  resolve(updated: boolean): void;

  defaultTab?: number
  small?: number
}


const MonitoringGenresModal: FC<Props> = observer(({
                                                     resolve,
                                                     small,
                                                     defaultTab,
                                                   }) => {
  const {t} = useTranslation();

  const channel = session.activeChannel! || {};

  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<'info' | 'edit' | string>(defaultTab ? 'edit' : '');
  const [countries, setCountries] = useState<ECountry[]>([]);

  const handleClose = useCallback((status: boolean) => {
    resolve(status);
  }, [resolve]);

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await session.updateChannel(channel.id, {countries});
      toast(t('UPDATE_CHANNEL_SUCCESS'));
      resolve(true);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }
  }

  if (small && !step) return (
    <>
      <div className={`content-type new-videos`}>
        <ModalHeader onClick={() => handleClose(false)}>
          <div className='d-flex align-items-center'>
            <div className="content-type-header-title">{t(`NOVELTIES`)}</div>
            <ContentTypeTags type={EContentPlanVideoOrigin.NewVideos} maxLength={100} />
          </div>
        </ModalHeader>
        <div className='modal-content-type-edit-small'>
          <div className='modal-content-type-edit-small-item' onClick={() => setStep('edit')}>
            <ReactSVG src={edit_svg} className='react-icon me-1' />
            <span>{t('EDIT')}</span>
          </div>
          <div className='modal-content-type-edit-small-item' onClick={() => setStep('info')}>
            <ReactSVG src={info_svg} className='react-icon me-1' />
            <span>{t('DETAIL_INFO')}</span>
          </div>
        </div>
      </div>
    </>
  )

  if(step === 'info') return (
    <>
      <div className={`content-type new-videos`}>
        <ModalHeader onClick={() => handleClose(false)}>
          <div className='d-flex align-items-center'>
            <div className="content-type-header-title">{t(`NOVELTIES`)}</div>
            <ContentTypeTags type={EContentPlanVideoOrigin.NewVideos} maxLength={100} />
          </div>
        </ModalHeader>
        <div className='text-muted text-pre-wrap'>{t(`MODAL_CONTENT_TEXT_novelties`)}</div>
        <div className='text-muted text-pre-wrap mt-3'>{t(`new-videos_INFO_TEXT`)}</div>
        <div className='modal-content-type-edit-small mt-4'>
          <div className='modal-content-type-edit-small-item' onClick={() => setStep('edit')}>
            <ReactSVG src={edit_svg} className='react-icon me-1' />
            <span>{t('EDIT')}</span>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <Loadable loading={loading}>
        <div className={`content-type new-videos`}>
          <ModalHeader
            onClick={() => handleClose(false)}
            title='MODAL_CONTENT_TITLE_novelties'
            titleClassName='content-type-header-title'
          />
          <div className='text-muted text-pre-wrap'>{t(`MODAL_CONTENT_TEXT_novelties`)}</div>
          <ChannelMonitoringPage handleClose={handleClose} defaultTab={defaultTab}/>
          {/*{type === 'novelties' &&*/}
          {/*  <GenresSelect filter={tempFilters.categories || []} onChange={handleChange('categories')}/>}*/}
        </div>
      </Loadable>
    </>
  );
});

const openMonitoringGenresModal = (defaultTab?: number): Promise<boolean> => asyncModal(MonitoringGenresModal, {defaultTab, small: window.innerWidth < 1280}, {
  classNames: {modal: 'filters-channel-modal', modalContainer: window.innerWidth < 1280 ? 'bottom-modal' : ''},
  showCloseIcon: false,
  blockScroll: false,
  center: true
});

export {openMonitoringGenresModal};
