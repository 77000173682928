import React, {FC, useState} from 'react';
import './header.scss';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import HeaderProfile from "./HeaderProfile";
import Notifications from "../Notifications";
import {IReferralRecord} from "../../modules/rest";
import {ExtensionBtn} from "../Extension";
import {ReactSVG} from "react-svg";
import burger from "../../assets/images/icons/burger.svg";
import HeaderBreadcrumbs from "./HeaderBreadcrumbs";
import {TSection} from "../../routes/AppRouter";

interface Props {
  openMenu: () => void;
  sectionName?: TSection
}


const Header: FC<Props> = ({openMenu, sectionName}) => {
  const {t} = useTranslation();

  return (
    <header>
      <div className='header'>
        <div className='d-flex align-items-center'>
          <button className={`nav-btn header-burger`} onClick={openMenu}>
            <ReactSVG src={burger} className='react-icon'/>
          </button>
          <HeaderBreadcrumbs sectionName={sectionName}/>
        </div>
        <div className='d-flex'>
          {/*<div className='balance text-muted'>{t('BALANCE')}</div>*/}
          {/*<div className='ms-2 text-bold me-5 text-nowrap'>$ {formatNumber(session.user?.balance || 0, 2)}</div>*/}
          {/*<ExtensionBtn />*/}
          <Notifications/>
          <HeaderProfile/>
        </div>
      </div>
    </header>
  );
};


export default observer(Header);
