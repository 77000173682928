import React, {FC, useRef, useState} from 'react';
import useScreenSize from "../../hooks/useScreenSize";
import ContentType from "../../components/ContentType";
import {EContentPlanVideoOrigin} from "../../modules/rest";
import Slider from "react-slick";
import ContentTypeTrends from "../../components/ContentType/ContentTypeTrends";
import ContentTypeNovelties from "../../components/ContentType/ContentTypeNovelties";
import ContentTypeComments from "../../components/ContentType/ContentTypeComments";
import {TContentPosition} from "./DashboardPage";


interface Props {
  contentPosition:TContentPosition
  getContentPlan: () => void;
}

const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '12px',
  padding: '4px'
  // autoplay: true,
  // autoplaySpeed: 6000
};

const DashboardContent: FC<Props> = ({getContentPlan, contentPosition}) => {
  const _slider: any = useRef(null);
  const {isSmallScreen} = useScreenSize();

  const [activeContentType, setActiveContentType] = useState<EContentPlanVideoOrigin>(EContentPlanVideoOrigin.Trending);
  const [showAnimation, setShowAnimation] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleChangeActiveContentType = (contentType: EContentPlanVideoOrigin) => {
    if (contentType === activeContentType) {
      if (contentType === EContentPlanVideoOrigin.Trending) {
        contentType = EContentPlanVideoOrigin.NewVideos
      } else {
        contentType = EContentPlanVideoOrigin.Trending
      }
    }
    setActiveContentType(contentType);
    setShowAnimation(true);
  }

  if (!isSmallScreen || contentPosition === 'accordion') return (
    <div className='position-relative'>
      <div className={`dashboard-content${contentPosition === 'accordion' && isSmallScreen ? ' accordion' : ''} ${activeContentType}`}>
        <ContentType
          onHeaderClick={contentPosition === 'accordion' && isSmallScreen ? (type) => setActiveContentType(type) : undefined}
          activeType={activeContentType}
          onChangeActiveContentType={handleChangeActiveContentType}
          data={[EContentPlanVideoOrigin.Trending, EContentPlanVideoOrigin.NewVideos, EContentPlanVideoOrigin.Comments]}
          className={`dashboard-content-item${showAnimation && !isSmallScreen ? ' with-animation' : ''}`}
          updateContentPlan={getContentPlan}
        />
      </div>
    </div>
  )
  return (
    <div className='dashboard-slider-wrap'>
      <Slider {...settings} className='dashboard-slider' ref={_slider}
              beforeChange={(currentSlide, nextSlide) => setActiveSlide(nextSlide)}
      >
        <ContentTypeTrends
          seeAll
          updateContentPlan={getContentPlan}
          className={'dashboard-content-item'}
          active={activeContentType === EContentPlanVideoOrigin.Trending}
          onChangeActiveContentType={() => handleChangeActiveContentType(EContentPlanVideoOrigin.Trending)}
        />
        <ContentTypeNovelties
          seeAll
          updateContentPlan={getContentPlan}
          className={'dashboard-content-item'}
          active={activeContentType === EContentPlanVideoOrigin.NewVideos}
          onChangeActiveContentType={() => handleChangeActiveContentType(EContentPlanVideoOrigin.NewVideos)}
        />
        <ContentTypeComments
          seeAll
          updateContentPlan={getContentPlan}
          className={'dashboard-content-item'}
          active={activeContentType === EContentPlanVideoOrigin.Comments}
          onChangeActiveContentType={() => handleChangeActiveContentType(EContentPlanVideoOrigin.Comments)}
        />
      </Slider>
      <div className="dashboard-slider-pagination">
        {Array(3).fill({}).map((_, i) =>
          <div
            key={i}
            onClick={() => _slider.current?.slickGoTo(i)}
            className={`dashboard-slider-pagination-item${activeSlide === i ? " active" : ''}`} />
        )}
      </div>
    </div>
  );
}

export default DashboardContent;