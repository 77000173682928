import asyncModal from 'react-async-modal';
import React, {FC, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IGetYoutuberStatsRequest, IVideoEmbed, IYoutuberStatsItem} from "../../modules/rest";
import ModalHeader from "../ModalHeader";
import geos from "../../translates/en/geos.json";
import {formatNumber, getEmbedName} from "../../modules/utils";
import Video from "../../components/Video";

interface Props {
  item: IYoutuberStatsItem
  group: IGetYoutuberStatsRequest['group'];

  resolve(request?: IGetYoutuberStatsRequest): void;
}

const ReportItemModal: FC<Props> = ({item, group, resolve}) => {

  const {t} = useTranslation();
  const videoRef: any = useRef(null);
  const [activeVideo, setActiveVideo] = useState(false)

  const renderTitle = () => {
    //@ts-ignore
    if (group === 'country') return geos[item.item?.toUpperCase()]?.name || item.item?.toUpperCase();
    if (group === "embed") return getEmbedName(item.item as IVideoEmbed);
    if (group === 'period') return item.item;
  }

  return (
    <div className='report-filter-modal'>
      <ModalHeader onClick={() => resolve()} title={renderTitle()}/>
      {group === 'embed' &&
      <div>
        <Video
          bigControls
          showDownload
          active={activeVideo}
          onChangeActiveVideo={() => setActiveVideo(prevState => !prevState)}
          ref={videoRef}
          ve={item.item as IVideoEmbed}
          className='mb-3'
        />
      </div>
      }
      <div className="report-filter-modal-stat">
        <div className="row g-0">
          {group !== 'country' &&
          <div className="col-6">
            <div className="report-filter-modal-stat-item">
              <div className='text-muted-14 mb-1'>{t('VIEWS')}</div>
              <div className='text-bold-14'>{formatNumber(item.views!) || '-'}</div>
            </div>
          </div>
          }
          <div className="col-6">
            <div className="report-filter-modal-stat-item">
              <div className='text-muted-14 mb-1'>{t('COM_views')}</div>
              <div className='text-bold-14'>{formatNumber(item.paidViews)}</div>
            </div>
          </div>
          <div className="col-6">
            <div className="report-filter-modal-stat-item">
              <div className='text-muted-14 mb-1'>{t('CPM')}</div>
              <div className='text-bold-14'>{formatNumber(item.cpm || 0, 2)}</div>
            </div>
          </div>
          <div className="col-6">
            <div className="report-filter-modal-stat-item">
              <div className='text-muted-14 mb-1'>{t('INCOME')}</div>
              <div className='text-bold-14'>{formatNumber(item.profit, 2)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const openReportItemModal = (item: IYoutuberStatsItem, group: IGetYoutuberStatsRequest['group']): Promise<void> => asyncModal(ReportItemModal, {
  item,
  group
}, {
  showCloseIcon: false,
  classNames: {modalContainer: 'bottom-modal'},
  center: true
});

export {openReportItemModal};
