import React, {FC } from 'react';
import {useTranslation} from "react-i18next";
import ContentTypeComments from "../../components/ContentType/ContentTypeComments";
import useScreenSize from "../../hooks/useScreenSize";

interface Props {
}

const CommentsPage: FC<Props> = () => {
  const {t} = useTranslation();
  const {isSmallScreen} =useScreenSize()

  return (
    <div className='content-type-page comments-page'>
      <h1>{t('COMMENTS')}</h1>
      <div className="text-muted mb-4">
        {t('COMMENTS_TEXT')}
      </div>
      <div className='content-type-page__content'>
        <ContentTypeComments aside/>
      </div>
    </div>
  );
}

export default CommentsPage;