import React, {FC, ReactNode, useRef} from 'react';
import {formatNumber, getEmbedName } from "../../modules/utils";
import {IGetYoutuberStatsRequest, IVideoEmbed, IYoutuberStatsItem} from "../../modules/rest";
import {ReactSVG} from "react-svg";
import period_svg from './assets/icons/month.svg';
import land_svg from './assets/icons/land.svg';
import geos from "../../translates/en/geos.json";
import Video from "../../components/Video";
import eye from '../../assets/images/icons/eye.svg'
import {openReportItemModal} from "../../modals";

interface Props {
  item: IYoutuberStatsItem;
  index: number;
  activeVideo?: number|null;
  onChangeActiveVideo: () => void;
  group: IGetYoutuberStatsRequest['group'];
  activeCol: string;
  // sort: IGetYoutuberStatsRequest['sort'];
}

const ReportsListItem: FC<Props> = ({
                                      item,
                                      index,
                                      group,
                                      activeVideo,
                                      onChangeActiveVideo,
                                      activeCol,
                                    }) => {
  const videoRef: any = useRef(null);
  const renderItem = (): ReactNode => {
    if (group === 'country') return (
      <div className='report__list__item-left'>
        <div className="report__list__item-icon">
          <ReactSVG src={land_svg} className='react-icon'/>
        </div>
        <div className='report__list__item-text'>
          {/*@ts-ignore*/}
          <h4 className='text-truncate-2'>{geos[item.item?.toUpperCase()]?.name}</h4>
        </div>
      </div>
    )
    if (group === 'period') return <div className='report__list__item-left'>
      <div className="report__list__item-icon">
        <ReactSVG src={period_svg} className='react-icon'/>
      </div>
      <div className='report__list__item-text'>
        <h4 className='text-truncate-2'>{item?.item as string}</h4>
      </div>

    </div>

    if (group === "embed") {
      const it = item.item as IVideoEmbed;
      const title = getEmbedName(it)
      return <div className='report__list__item-left'>
        <Video
          active={activeVideo === it.id}
          onChangeActiveVideo={onChangeActiveVideo}
          ref={videoRef}
          className='me-3'
          ve={it}
        />
        <div className='report__list__item-text'>
          <h4 className='text-truncate-2' title={title}>{title}</h4>
        </div>
      </div>
    }
    return null;
  }

  return (
    <div className='report__list__item'>
      <div className='text-muted report__list__item-id' >
        {index + 1}
      </div>
      <div className='report__list__item__content'>
        {renderItem()}
        {group !== 'country' && <div className={`reports-list-number-item  table-td${activeCol === 'views' ? ' active' : ''}`}>{formatNumber(item.views!) || '-'}</div>}
        <div className={`reports-list-number-item  table-td${activeCol === 'paidViews' ? ' active' : ''}`}>{formatNumber(item.paidViews)}</div>
        <div className={`reports-list-number-item  table-td${activeCol === 'cpm' ? ' active' : ''}`}>${formatNumber(item.cpm || 0, 2)}</div>
        <div className={`reports-list-number-item  table-td text-bold text-white${activeCol === 'profit' ? ' active' : ''}`}>${formatNumber(item.profit, 2)}</div>
        <ReactSVG src={eye} className='react-icon list-item-eye' onClick={() => openReportItemModal(item,group)} />
      </div>
    </div>
  );
}

export default ReportsListItem;
