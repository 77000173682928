import ExtensionBtn from './ExtensionBtn';
import ExtensionPanel from './ExtensionPanel';

import './style.scss'

export const  extensionLink = 'https://chromewebstore.google.com/detail/reactrino/edlmiiojapnfbpmhcgeoanfgnomppfki?hl=ru';

export {
  ExtensionBtn,
  ExtensionPanel,
};