import React, {FC, useEffect, useRef} from 'react';
import ContentTypeHeader from "./ContentTypeHeader";
import Pagination from "../Pagination";
import {EContentPlanVideoOrigin, IPagedData} from "../../modules/rest";
import Loadable from "../Loadable";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import arrow from '../../assets/images/icons/arrow_20.svg';
import {Link, useNavigate} from "react-router-dom";

interface Props {
  children: any;
  loading?: boolean;
  active?: boolean;
  seeAll?: boolean;
  type: EContentPlanVideoOrigin;
  className?: string;
  onChangeActiveContentType?: () => void;
  onPageChange: (page: number) => void;
  onLimitChange: (limit: number) => void;
  pager?: IPagedData<any>
  commentHeadComponent?: any
  maxTagsLength?: number;
  onHeaderClick?: () => void;
}

const ContentTypeWrapper: FC<Props> = ({
                                         children,
                                         type,
                                         className,
                                         loading,
                                         onChangeActiveContentType,
                                         active,
                                         onPageChange,
                                         onLimitChange,
                                         commentHeadComponent,
                                         maxTagsLength,
                                         pager,
                                         seeAll,
                                         onHeaderClick,
                                       }) => {
  const {t} = useTranslation();
  const _list: any = useRef(null);

  useEffect(() => {
    if (active) {
      _list.current?.scrollTo(0, 0);
    }
  }, [active]);



  return (
    <div className={`content-type ${type}${active ? ' active' : ''}${loading ? ' loading' : ''} ${className || ''}`}>
      <div className="content-type-wrap">
        <ContentTypeHeader
          onHeaderClick={onHeaderClick}
          maxTagsLength={maxTagsLength}
          commentHeadComponent={commentHeadComponent}
          type={type}
          onChangeActiveContentType={onChangeActiveContentType}
        />
        <div className='content-type-list' ref={_list}>
          <div className='content-type-list-content' ref={_list}>
            <Loadable loading={loading || false} size={'1x'}>
            {children}
            </Loadable>
            {/*{loading && <Loader/>}*/}
          </div>
        </div>
        {seeAll &&
        <Link to={`/${type}`} className='content-type-list-see'>
          <span>{t('SEE_ALL')}</span>
          <ReactSVG src={arrow} className='react-icon ms-1' />
        </Link>
        }
        <Pagination
          onPageChange={onPageChange}
          onLimitChange={onLimitChange}
          pager={pager}
        />
      </div>

    </div>
  );
}

export default ContentTypeWrapper;