import React, {FC, useState} from 'react';
import {formatViews, thumb} from "../../modules/utils";
import ContentTypeItemImg from "../../components/ContentType/ContentTypeItemImg";
import {useTranslation} from "react-i18next";
import moment from "moment";
import OutsideClick from "../../components/OutsideClick";
import CopyToClipboard2 from "../../components/CopyToClipboard2";
import {IResearchCountryReaction} from "../../modules/rest";

interface Props {
  item: IResearchCountryReaction;
}

const BestReactionsListItem: FC<Props> = ({item}) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(prevState => !prevState)
  }

  return (
    <div className='best-reactions-modal-item'>
      <div className='d-flex align-items-center'>
        <ContentTypeItemImg image={thumb(item.reaction?.preview?.id!, 180)} url={item.reaction?.url}/>
        <div className='ps-2'>
          <div className='d-grid overflow-hidden'>
          <div className='text-muted text-bold-14 word-break text-truncate-2'>{item.reaction.title}</div>
          </div>
          <div className='text-muted-12 d-flex align-items-center mt-1'>
            <div className='text-lowercase'>{formatViews(item.reaction.viewCount!)} {t('VIEWS')}</div>
            <div className="best-reactions-modal-item-separator"/>
            <div className='text-lowercase'>{moment(item.reaction.publishedAt).calendar()}</div>
          </div>
        </div>
      </div>
      {item.reaction.tags?.length
        ?
        <OutsideClick
          outsideClickEvent={() => setVisible(false)}
          className={`best-reactions-modal-item-tags${visible ? ' visible' : ''}`}>
          <div onClick={toggleVisible}>
          <div className="best-reactions-modal-item-tags-head">
            <div className='text-muted-14 text-nowrap'>{t('VIDEO_TAGS')}</div>
            <div className="best-reactions-modal-item-tags-head-list">
              <div className='d-flex align-items-center text-muted-12 pointer-event-none'>
                {item.reaction.tags.map(tag => (
                  <span key={tag} className='me-2 best-reactions-modal-tag mb-0'>{tag}</span>
                ))}
              </div>
            </div>
            <CopyToClipboard2 text={item.reaction.tags.join(', ')}/>
          </div>
          <div className="best-reactions-modal-item-tags-hidden">
            <div className="best-reactions-modal-item-tags-hidden-list text-muted-12">
              {item.reaction.tags.map(tag => (
                <div className='best-reactions-modal-tag text-truncate text-muted-12' key={tag}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
          </div>
        </OutsideClick>
        :
       null
      }
    </div>
  );
}

export default BestReactionsListItem;