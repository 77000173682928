import React, {FC} from 'react';

interface Props {

}

const BestReactionsEmptySkeleton: FC<Props> = (props) => {

  return (
    <div className='best-reactions-modal-skeleton'>
      {Array(5).fill({}).map((_, i) => (
        <div className='best-reactions-modal-skeleton-item' key={i}>
          <div className='d-flex align-items-center'>
            <div className="best-reactions-modal-skeleton-item-bg" style={{width: 78, height: 48}}/>
            <div className='ps-2'>
              <div className="best-reactions-modal-skeleton-item-bg" style={{width: 132, height: 16}}/>
              <div className='d-flex mt-2'>
              <div className="best-reactions-modal-skeleton-item-bg" style={{width: 69, height: 12}}/>
              <div className="best-reactions-modal-skeleton-item-bg ms-2" style={{width: 69, height: 12}}/>
              </div>
            </div>
          </div>
          <div className="best-reactions-modal-skeleton-item-bg w-100 mt-2" style={{ height: 32}}/>
        </div>
      ))}
    </div>
  );
}

export default BestReactionsEmptySkeleton;