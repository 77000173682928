import React, {FC} from 'react';
import {ECountry} from "../../modules/rest";
import {observer} from "mobx-react";
import session from "../../modules/session";
import {useTranslation} from "react-i18next";
import geos from "../../translates/en/geos.json";

interface Props {
  onChange: (country: ECountry) => void;
}

const BestReactionsSelectCountry: FC<Props> = ({onChange}) => {
  const {t} = useTranslation();
  const countries = session.activeChannel?.countries || [];

  return (
    <div>
      <div className='text-muted mb-1 pb-2 mt-4'>{t('SELECT_REGION')}</div>
      <div className="row g-2 best-reactions-modal-countries-list">
        {countries.map(country => (
          <div className='col-12 col-lg-6' key={country}>
            <button type='button' onClick={() => onChange(country)} className='btn btn-lg best-reactions-modal-country-btn'>
              {/*@ts-ignore*/}
              {geos[country.toUpperCase()]?.name || country}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}


export default observer(BestReactionsSelectCountry);