import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import arrow_left from "../Extension/images/arrow_left.svg";
import arrow_right from "../Extension/images/arrow_right.svg";

interface Props {
  active: number;
  len: number;
  arrows?: boolean;
  className?: string;
  onClick: (idx: '-1'|'1'|number) => void;
}

const SliderPagination: FC<Props> = ({active, len, onClick, arrows = true, className}) => {
  return (
    <div className={`slider-pagination ${className || ''}`}>
      {arrows &&
        <button type='button'  className='slider-pagination-arrow' disabled={active === 0} onClick={() => onClick('-1')}>
          <ReactSVG src={arrow_left} className={'react-icon'}/>
        </button>
      }
      <div className='d-flex'>
        {Array(len).fill({}).map((_, i) => (
          <div className={`slider-pagination-dot${active === i ? ' active' : ''}`} key={i}
               onClick={() => onClick(i)}/>
        ))}
      </div>
      {arrows &&
        <button type='button'  className='slider-pagination-arrow' disabled={active === len - 1} onClick={() => onClick('1')}>
          <ReactSVG src={arrow_right} className={'react-icon'}/>
        </button>
      }
    </div>
  );
}

export default SliderPagination;