import asyncModal from 'react-async-modal';
import React, {FC, FormEvent, useCallback, useState} from 'react';
import Button from '../../components/Button';
import Loadable from '../../components/Loadable';
import {useTranslation} from "react-i18next";
import ModalClose from "../ModalClose";
import {Select, Textarea} from "../../components/FormControls";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import lang_svg from "../../assets/images/icons/lang.svg";
import {Languages} from "../../modules/directory";
import {ELanguage} from "../../modules/rest";
import ModalHeader from "../ModalHeader";


interface Props {
  resolve(success: boolean): void;
}

const RequestVideoModal: FC<Props> = ({resolve}) => {
  const {t} = useTranslation();
  const [requestDescription, setRequestDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(ELanguage.English);

  const submit = useCallback((e: FormEvent) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    API.VideoEmbeds.requestVideoEmbed({comment: requestDescription, language})
      .then((res) => {
        toast.success(t('REQUEST_VIDEO_SENT'));
        resolve(true);
      })
      .catch(toast.error)
      .finally(() => setLoading(false));
  }, [loading, requestDescription, language]);

  const handleClose = useCallback(() => {
    resolve(false)
  }, [resolve]);

  return (
    <>
      <Loadable loading={loading}>
        <ModalHeader onClick={handleClose} title={'REQUEST_VIDEO_TEXT'} />
        <form onSubmit={submit}>
          <Textarea
            maxLength={512}
            required
            value={requestDescription}
            onChange={e => setRequestDescription(e.target.value)}
          />
          <div className='text-muted mt-3 mb-2 pb-1' style={{whiteSpace: 'pre-wrap'}}>{t('REQUEST_VIDEO_TEXT_2')}</div>
          <Select
            value={language}
            onChange={e => setLanguage(e.target.value)}
            className='sm flex-grow-1 me-0 me-md-2 w-100 w-md-50'>
            {Object.entries(Languages).map(([key, value]) => (
              <option value={key} key={key}>{t(`langs:${key}`)}</option>
            ))}
          </Select>
          <div className="modal-footer-btns">
            <Button
              text='CANCEL'
              onClick={handleClose}
              color='outline-primary'
            />
            <Button
              text='SEND'
              type='submit'
            />
          </div>
        </form>
      </Loadable>
    </>
  );
};

const openRequestVideoModal = (): Promise<boolean> => {
  return asyncModal(RequestVideoModal, {}, {showCloseIcon: false, center: true,});
};

export {openRequestVideoModal};
