import {
  ELanguage,
  IGenerateVideoEmbedTextsTask,
  IUserViewsHistory,
  IVideo,
  IVideoEmbed,
  IVideoEmbedViewsHistory
} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import ModalClose from "../ModalClose";
import MonetizationHistoryGraph from "../../components/Aside/AsideMonetization/MonetizationHistoryGraph";
import ModalHeader from "../ModalHeader";

interface Props {
  history?: (IUserViewsHistory|IVideoEmbedViewsHistory)&{ max: number }

  resolve(video: IVideo|null): void;
}

const HistoryViewsVideoModal: FC<Props> = ({history, resolve}) => {
  const {t} = useTranslation();

  const handleClose = () => {
    resolve(null)
  }

  return (
    <>
      <ModalHeader onClick={handleClose} title={'VIEWS_HISTORY'} />
      <MonetizationHistoryGraph history={history} className='video-more-modal-history'/>
    </>
  );
};

const openHistoryViewsVideoModal = (history?: (IUserViewsHistory|IVideoEmbedViewsHistory)&{
  max: number
}): Promise<IVideo|null> => asyncModal(HistoryViewsVideoModal, {history}, {
  showCloseIcon: false,
  center: true
});

export {openHistoryViewsVideoModal};
