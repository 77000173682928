import asyncModal from 'react-async-modal';
import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalHeader from "../ModalHeader";
import {IWithdrawMethodInfo} from "../../modules/rest";
import {systemsLogo} from "../../routes/billing/BillingPage";
import {Input, Select} from "../../components/FormControls";
import {toast} from "react-toastify";
import {API} from "../../modules/api";
import money from '../../assets/images/icons/monetization.svg';
import {ReactSVG} from "react-svg";
import Button from "../../components/Button";
import money_circle from "../../assets/images/icons/money_circle.svg";
import {Countries,} from "../../modules/directory";
import geos from "../../translates/en/geos.json";

interface Props {
  item: IWithdrawMethodInfo

  resolve(success?: boolean): void;
}

const dataTitles: any = {
  payoneer: 'PAYONEER_TITLE',
  platron: 'MASTERCARD_TITLE',
  usdt_trc20: 'TETHER_TITLE',
}

const CreateWithdrawModal: FC<Props> = ({item, resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<any>({});

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await API.Withdraw.create({method: item.method, ...form});
      resolve(true);
      toast.success(t('WITHDRAW_CREATED'))
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }

  }

  const countries = Object.keys(Countries)

  return (
    <div className='billing-withdraw-modal'>
      <ModalHeader onClick={() => resolve()} title={dataTitles[item.method]}/>
      <img src={systemsLogo[item.method]} alt={item.method} className='billing-withdraw-logo'/>
      <form onSubmit={handleSubmit}>
        <div className={`billing-withdraw-form${item.details.length > 1 ? ' half' : ''}`}>
          {item.details?.map(detail => {
            if (detail.id === 'country') return (

              <Select
                className={`sm mt-3`}
                placeholder={detail.placeholder || ''}
                required={detail.required}
                label={detail.id.toUpperCase() || ''}
                value={form[detail.id]}
                onChange={(e) => setForm((prevState: any) => ({...prevState, [detail.id]: e.target.value}))}
              >
                {Object.keys(Countries).map(ln => {
                  //@ts-ignore
                  if (!geos[ln.toUpperCase()]) return null;
                  return (
                    <option value={ln} key={ln}>
                      {/*@ts-ignore*/}
                      {geos[ln.toUpperCase()]?.name || ln}</option>
                  )
                })}
              </Select>
            )
            return (
              <Input
                type={detail?.extra?.type || detail.type}
                className={`sm mt-3`}
                placeholder={detail.placeholder || ''}
                required={detail.required}
                label={detail.id.toUpperCase() || ''}
                value={form[detail.id] || ''}
                onChange={(e) => setForm((prevState: any) => ({...prevState, [detail.id]: e.target.value}))}
              />
            )
          })}
        </div>
        <div className='billing-withdraw-amount'>
          <Input
            className='sm mt-3 '
            placeholder={'100'}
            required
            type='number'
            label={'AMOUNT'}
            value={form.amount || ''}
            onChange={(e) => setForm((prevState: any) => ({...prevState, amount: e.target.value}))}
          />
          <div className="billing-withdraw-currency">USD</div>
        </div>
        <div className='d-flex align-items-center mt-2 pt-1 text-tint'>
          <ReactSVG src={money} className='react-icon me-2'/>
          <span>{t('AMOUNT_LESS_THEN', {amount: '$100'})}</span>
        </div>
        <div className="modal-footer-btns">
          <Button
            text='CANCEL'
            onClick={() => resolve()}
            color='outline-primary'
          />
          <Button
            className='pe-1 btn-circle-icon'
            iconSvg={money_circle}
            text='ORDER_PAYMENT'
            type='submit'
            loading={loading}
          />
        </div>

      </form>
    </div>
  );
};

const openCreateWithdrawModal = (item: IWithdrawMethodInfo): Promise<boolean> => asyncModal(CreateWithdrawModal, {
  item,
}, {
  showCloseIcon: false,
  blockScroll: false,
  center: true
});

export {openCreateWithdrawModal};
