import asyncModal from 'react-async-modal';
import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {IWithdraw} from "../../modules/rest";
import ModalClose from "../ModalClose";
import {systemsLogo} from "../../routes/billing/BillingPage";
import BillingWithdrawStatus from "../../routes/billing/BillingWithdrawStatus";
import Button from "../../components/Button";
import {API} from "../../modules/api";

interface Props {
  item: IWithdraw

  resolve(success?: boolean): void;
}

const BillingRemoveWithdrawModal: FC<Props> = ({item, resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleClose = async () => {
    resolve()
  }
  const handleClick = async () => {
    try {
      setLoading(true)
      await API.Withdraw.cancel(item.id, {});
      resolve(true)
    } catch (e) {

    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ModalClose onClick={handleClose}/>
      <h2 className='text-danger text-bold-24 text-center text-pre-wrap my-3 pb-3'>{t('WITHDRAW_CANCEL')}</h2>
      <div className={`billing-payment-modal-top-card m-0 ${item.status}`}>
        <div className='billing-payment-modal-top-card-top'>
          <div className='flex-1 pe-3'>
            <img src={systemsLogo[item.method]} alt="system"/>
          </div>
          <BillingWithdrawStatus item={item} hideIcon/>
        </div>
        <div className='d-flex p-3 align-items-center justify-content-between'>
          <div className='text-muted'>{moment(item.createdAt).format('YYYY.MM.DD, HH:mm')}</div>
          <div>${item.amount}</div>
        </div>
      </div>
      <div className="modal-footer-btns">
        <Button
          text='NO'
          onClick={handleClose}

        />
        <Button
          onClick={handleClick}
          color='outline-primary'
          text='YES'
          loading={loading}
        />
      </div>
    </>
  );
};

const openBillingRemoveWithdrawModal = (item: IWithdraw): Promise<boolean> => asyncModal(BillingRemoveWithdrawModal, {
  item,
}, {
  classNames: {modal: 'small-modal'},
  showCloseIcon: false,
  center: true
});

export {openBillingRemoveWithdrawModal};
