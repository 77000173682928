import React, {useEffect} from 'react';
import {observer, useLocalObservable} from "mobx-react";
import {ECountry, EFieldGroup, IGetYoutuberStatsRequest, ITrack, IYoutuberStats} from "../../modules/rest";
import {runInAction} from "mobx";
import './report.scss'
import {useTranslation} from "react-i18next";
import ReportsFilters from "./ReportsFilters";
import Empty from "../../components/Empty";
import ReportsListItem from "./ReportsListItem";
import {API} from "../../modules/api";
import ReportsTabs from "./ReportsTabs";
import {toast} from "react-toastify";
import ReportsListHead from "./ReportsListHead";
import Loadable from "../../components/Loadable";
import Loader from "../../components/Loader";
import ReportsTotal from "./ReportsTotal";
import {formatDate} from "../../modules/utils";
import {openReportFilterModal} from "../../modals";

const colClassNames = ['views', "paidViews", 'cpm', 'profit']


interface State {
  // pager?: IGetStatsResponse<unknown>;
  // request: IGetStatsRequest;
  request: IGetYoutuberStatsRequest;
  pager?: IYoutuberStats;
  info?: { lastReport: string; nextReport: string };
  loading: boolean;
  filterVisible?: boolean;
  ready: boolean;
  activeVideo: number | null;
  tracksFilter: ITrack[];
  activeListCol: number;
}

// const startPeriod = moment().add('month', -3).format('YYYY-MM');
const ReportPage = observer(() => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    ready: false,
    loading: false,
    filterVisible: true,
    activeVideo: null,
    activeListCol: 0,
    request: {
      group: 'embed',
      periodBegin: '',
      periodEnd: '',
      // sort: 'views',
      country: [] as ECountry[],
      embed: [],
    },
    tracksFilter: [],
  }));

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const info = await API.Reports.getInfo();
      runInAction(() => (st.info = info));
      fetch()
    } catch (e) {

    }
  }

  const fetch = async (req?: Partial<IGetYoutuberStatsRequest>) => {
    runInAction(() => (st.loading = true));
    API.Stats.getYoutuberStats({...st.request, ...req}, [EFieldGroup.VideoEmbedVideo])
      .then((pager) => {
        runInAction(() => {
          st.pager = pager;
        });
      })
      .catch((e) => toast.error(e, {toastId: 'stats_fetch'}))
      .finally(() => runInAction(() => {
        st.loading = false;
        st.ready = true;
        if (req) st.request = {...st.request, ...req};
      }));
  }

  const toggleFiltersVisible = () => {
    if (window.innerWidth < 1280) {
      return openReportFilterModal(st.request).then((request: IGetYoutuberStatsRequest) => {
        runInAction(() => {
          st.request = {...st.request, ...request}
          fetch();
        })
      });
    }
    runInAction(() => {
      st.filterVisible = !st.filterVisible;
    })
  }


  const handleChangeActiveVideo = (id: number) => () => {
    runInAction(() => {
      st.activeVideo = st.activeVideo === id ? null : id;
    })
  }

  return (
    <section className={`reports-page${st.filterVisible ? ' filter-visible' : ''}`}>
      <div className='px-3 px-lg-0 mb-4'>
        <h1 className='mb-1 page-title'>{t('REPORTS')}</h1>
        {st.info
          ?
          <>
            <div><span className='text-muted'>{t('LAST_REPORT')}</span> <span>{st.info.lastReport}</span></div>
            <div><span className='text-muted'>{t('NEXT_REPORT')} ≈{formatDate(st.info.nextReport)}</span></div>
          </>
          :
          null
        }

      </div>
      {!st.ready
        ?
        <Loader/>
        :
        <>
          <ReportsTabs
            filterVisible={st.filterVisible}
            toggleFilter={toggleFiltersVisible}
            request={st.request}
            onGroupChange={(group) => {
              runInAction(() => {
                fetch({group});
              })
            }}
          />

          <Loadable loading={st.loading} className='d-flex flex-1'>
            <div className="report-filters-content"
                 style={st.request.group === 'embed' ? {borderTopLeftRadius: 0} : {}}>
              <ReportsListHead
                group={st.request.group}
                activeCol={colClassNames[st.activeListCol!]}
                onChangeActiveCol={(dir: 1 | -1) => {
                  if ((st.activeListCol === 0 && dir === -1) || (st.activeListCol === colClassNames.length - 1 && dir === 1)) return;
                  runInAction(() => {
                    st.activeListCol = st.activeListCol + dir;
                  })
                }}
              />
              <div className="report-filters-list-wrap">
                <div className="report-filters-list">
                  {st.pager?.data?.length ?
                    st.pager.data?.map((item: any, i: number) => (
                      <ReportsListItem
                        activeCol={colClassNames[st.activeListCol!]}
                        activeVideo={st.activeVideo}
                        onChangeActiveVideo={handleChangeActiveVideo(item.item.id)}
                        item={item}
                        key={i}
                        index={i}
                        group={st.request.group}
                        // sort={st.request.sort}
                      />
                    ))
                    :
                    <Empty show className='pb-0 pt-4'/>
                  }
                </div>
              </div>
              <ReportsTotal pager={st.pager}   activeCol={colClassNames[st.activeListCol!]}/>
            </div>
            <ReportsFilters
              visible={st.filterVisible}
              request={st.request}
              updateState={(request: Partial<IGetYoutuberStatsRequest>) => {
                runInAction(() => {
                  st.request = {...st.request, ...request}
                  fetch();
                })
              }}
            />
          </Loadable>

        </>
      }


    </section>
  );
});

export default ReportPage;
