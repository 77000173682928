import React, {FC, useEffect, useState} from 'react';
import {
  EContentPlanVideoOrigin,
  ECountry,
  IAlsoTrendingItem,
  IMonitoringVideo,
  ITrendingItem
} from "../../modules/rest";
import TrendsAsideHead from "./TrendsAsideHead";
import TrendsAsideSelect from "./TrendsAsideSelect";
import TrendsAsideList from "./TrendsAsideList";
import {API} from "../../modules/api";
import {observer} from "mobx-react";
import session from "../../modules/session";

interface Props {
  type: EContentPlanVideoOrigin
}

const TrendsAside: FC<Props> = observer(({type}) => {

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(type === EContentPlanVideoOrigin.Trending ? ECountry.US : session.categories?.[0]?.id);
  const [data, setData] = useState<(IAlsoTrendingItem)[]>();

  const channelId = session.activeChannel?.id!;

  useEffect(() => {
    if (channelId) fetch();
  }, [channelId, filter]);


  const fetch = async (region?: ECountry) => {
    try {
      setLoading(true);
      let res: any;
      if (type === EContentPlanVideoOrigin.Trending) {
        res = await API.Reactrino.getTrending(filter, {limit: 10, page: 1});
      } else if (type === EContentPlanVideoOrigin.NewVideos) {
        res = await API.Reactrino.getNewVideos(filter || 1, {limit: 10, page: 1});
      }
      setData(res.data)
      // setTrends(res.items);
      // setRegion(res.region);
    } catch (e: any) {
    } finally {
      setLoading(false);
    }
  }
  return (

      <div className='trends-aside-wrap'>
        <div className='trends-aside'>
          <TrendsAsideHead type={type}/>
          <TrendsAsideSelect loading={loading} value={filter} type={type} onChange={setFilter}/>
          <TrendsAsideList data={data}/>
        </div>
      </div>
  );
})

export default TrendsAside;