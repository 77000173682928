import React, {FC, useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Button from "../components/Button";
import Loadable from "../components/Loadable";
import {API} from "../modules/api";
import Loader from "../components/Loader";

interface Props {
}

const ApiKeyPage: FC<Props> = () => {
  const {t} = useTranslation();
  const [apiKey, setApiKey] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getApiKey();
  }, []);

  const getApiKey = useCallback(async () => {
    try {
      // const res = await API.Users.getApiKey();
      // setApiKey(res || '');
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }, []);

  const createApiKey = useCallback(async () => {
    try {
      setLoading(true);
      // const res = await API.Users.setApiKey();
      // setApiKey(res);
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }, []);

  const deleteApiKey = useCallback(async () => {
    try {
      setLoading(true);
      // await API.Users.deleteApiKey()
      setApiKey('');
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }, []);
  return (
    <Loadable loading={loading}>
      <h1 className='page-title mb-4'>{t('AUTHORIZATION')}</h1>
      <div className={'bold-24'} id='reactrino-apiKey'>{localStorage.token}</div>
      <Loader className={'reactrino-apiKey-loading'}/>
      <div className={'text-success reactrino-apiKey-success'}>{t('SUCCESS_EXTENSION')}</div>
    </Loadable>
  );
}

export default ApiKeyPage;