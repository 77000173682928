import {ELanguage, IGenerateVideoEmbedTextsTask, IVideoEmbed} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC, useCallback, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalClose from "../ModalClose";
import ImportantBlock from "../../components/ImportantBlock";
import TextGenerate from "../../components/TextGenerate";
import Video from "../../components/Video";
import HowInsertVideo from "../../components/HowInsertVideo";
import {openTextGenerateModal} from "./TextGenerateModal";

interface Props {
  ve: IVideoEmbed;

  resolve(): void;
}

const DownloadVideoModal: FC<Props> = ({ve, resolve}) => {
  const videoRef: any = useRef(null);
  const {t} = useTranslation();
  const [generateTexts, setGenerateTexts] = useState<any>({});

  const handleClose = useCallback(() => {
    resolve()
  }, [resolve]);

  const handleUpdateGenTexts =  (text: IGenerateVideoEmbedTextsTask, language: ELanguage) => {
    setGenerateTexts((prevState: any) => ({...prevState, [language]: text}));
  }


  return (
    <div className='modal-without-padding download-video-modal' >
      <ModalClose onClick={handleClose}/>
      <div className='videos-modal-header flex-column p-0 mb-1'>
        <div className='get-text-modal-head'>
          <Video
            downloadModal={false}
            local
            ref={videoRef}
            className='w-auto'
            ve={ve}
          />
          <div className='download-video-modal-title'>
            <h2>{t('DOWNLOAD_VIDEO_TEXT')}</h2>
          </div>
        </div>
        <div className='download-video-modal-content'>
        <HowInsertVideo className={'w-100 p-0 p-md-3 pb-0 how-to-inset-video'}  preview={ve.preview}/>
        <div className='w-100 p-0 p-md-3 py-3 position-relative'>
          <TextGenerate
            onBtnModalClick={() => {
              resolve();
              openTextGenerateModal(ve)
            }}
            separatorVisible
            videoEmbedId={ve.id}
            onUpdate={handleUpdateGenTexts}
            generateTexts={generateTexts}
          />
        </div>
      </div>
      {/*<TrackCover dt={dt} toggleActiveTrackId={toggleActiveTrack} active={activeTrack} download/>*/}
      </div>
      <div className='p-0 p-md-1'>
      <ImportantBlock title='VIDEO_EMBED_IMPORTANT_TITLE' text={'VIDEO_EMBED_IMPORTANT_TEXT'}/>
      </div>
    </div>
  );
};

const openDownloadVideoModal = (ve: IVideoEmbed): Promise<void> => asyncModal(DownloadVideoModal, {ve}, {
  showCloseIcon: false,
  center: true,
  blockScroll: false,
  styles: {modal: {width: 750}}
});

export {openDownloadVideoModal};
