import React, {FC,   useState} from 'react';
import {useTranslation} from "react-i18next";
import {API} from "../../modules/api";
import {IReferralRecord} from "../../modules/rest";
import './referrals.scss';
import CopyToClipboard from "../../components/CopyToClipboard";
import session from "../../modules/session";
import Loadable from "../../components/Loadable";
import {UserStates} from "../../modules/directory";
import gift from './assets/gift.png';
import {ReactSVG} from "react-svg";
import chevron_left from "../../assets/images/icons/chevron_left.svg";
import chevron_right from "../../assets/images/icons/chevron_right.svg";
import eye from "../../assets/images/icons/eye.svg";
import {formatNumber} from "../../modules/utils";
import {openReferralItemModal} from "../../modals";


interface Props {
  setReferrals: (referrals: IReferralRecord[]) => void;
  referrals?: IReferralRecord[];
}

const ReferralsPage: FC<Props> = ({setReferrals, referrals}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [activeListCol, setActiveListCol] = useState(0);

  const fetch = () => {
    API.Referrals.getList().then(setReferrals);
  }

  const onChangeActiveCol = (dir: 1 | -1) => () => {
    setActiveListCol(prevState => {
      if ((prevState === 0 && dir === -1) || (prevState === 2 && dir === 1)) return prevState;
      return prevState + dir
    })
  }

  const refLink = `https://reactrino.com/r/${session.user?.refTag}`


  if (!referrals?.length) return null;
  return (
    <>
      <div className='page-header'>
        <h1 className='page-title'>{t('PARTNERS')}</h1>
      </div>
      <div className='referrals-program-wrap'>
        <div className='referrals-program'>
          <h2>{t('AFFILIATE_PROGRAM')}</h2>
          <div className='referrals-program-content-wrap'>
            <img src={gift} alt="gift" className='referrals-gift'/>
            <div className='referrals-program-content'>
              <div>
                <div className='text-bold-24 text-tint mb-2'>10%</div>
                <div className='text-pre-wrap text-light'>{t('YOU_RECEIVE')}</div>
              </div>
              <div className='form-group overflow-hidden ps-3'>
                <label className={`muted mb-2`}>
                  <span>{t('YOUR_LINK')}</span>
                </label>
                <div className='form-control referral-link'>
                  <div className='text-truncate me-2 me-xl-3'>
                    {refLink}
                  </div>
                  <CopyToClipboard text={refLink}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className='ps-2  mt-4'>{t('MY_REFERRALS')}</h2>
      <Loadable loading={loading} className='referrals-table'>

        <div className="referrals-table-th table-list-head">
          <div className="referrals-table-id">ID</div>
          <div className="referrals-table-email">E-mail</div>
          <div className={`referrals-table-td table-th${activeListCol === 0 ? ' active' : ''}`}>{t('STATUS')}</div>
          {/*<div className='referrals-table-td'>{t('SOURCE')}</div>*/}
          <div
            className={`referrals-table-td referrals-table-channels table-th${activeListCol === 1 ? ' active' : ''}`}>{t('CHANNELS')}</div>
          <div
            className={`referrals-table-td referrals-table-views table-th${activeListCol === 2 ? ' active' : ''}`}>{t('VIEWS')}</div>
          <div className='list-head-arrows'>
            <div className={`list-head-arrow${activeListCol === 0 ? ' disabled' : ''}`} onClick={onChangeActiveCol(-1)}>
              <ReactSVG src={chevron_left} className='react-icon'/>
            </div>
            <div className={`list-head-arrow ms-2${activeListCol === 2 ? ' disabled' : ''}`}
                 onClick={onChangeActiveCol(1)}>
              <ReactSVG src={chevron_right} className='react-icon'/>
            </div>
          </div>
        </div>
        {referrals?.map((item, i) => (
          <div className="referrals-table-item" key={i}>
            <div className="referrals-table-id">{item.id}</div>
            <div className="referrals-table-email text-truncate">{item.email}</div>
            <div
              className={`referrals-table-td table-td${activeListCol === 0 ? ' active' : ''}`}>
              <div className={`referrals-status ${item.status}`}>{t(UserStates[item.status])}</div>
            </div>
            {/*<div className='referrals-table-td text-truncate'>{item.source}</div>*/}
            <div className={`referrals-table-td referrals-table-channels table-td${activeListCol === 1 ? ' active' : ''}`}>
              {formatNumber(item.channels)}
              {/*{item.channels.map((channel, i) => (*/}
              {/*  <div className='d-grid overflow-hidden'>*/}
              {/*    <div className='text-truncate' key={i}>{channel}</div>*/}
              {/*  </div>*/}
              {/*))}*/}
            </div>
            <div className={`referrals-table-td referrals-table-td referrals-table-views  table-td${activeListCol === 2 ? ' active' : ''}`}>
              {formatNumber(item.views)}
            </div>
            <ReactSVG src={eye} className='react-icon list-item-eye' onClick={() => openReferralItemModal(item)}/>
          </div>
        ))}
      </Loadable>
    </>
  );
}

export default ReferralsPage;