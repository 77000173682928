import React, {FC} from 'react';
import {formatNumber} from "../../modules/utils";
import {useTranslation} from "react-i18next";
import {IYoutuberStats} from "../../modules/rest";

interface Props {
  pager?: IYoutuberStats;
  activeCol: string;
}

const ReportsTotal: FC<Props> = ({pager, activeCol}) => {
  const {t} = useTranslation();
  if(!pager) return null;
  return (
      <div className='reports-total table-total'>
          <div className="flex-1">{t('TOTAL')}</div>
          <div className={`reports-list-number-item table-td${activeCol === 'views' ? ' active' : ''}`}>{formatNumber(pager.views!) || '-'}</div>
          <div className={`reports-list-number-item table-td${activeCol === 'paidViews' ? ' active' : ''}`}>{formatNumber(pager.paidViews)}</div>
          <div className={`reports-list-number-item table-td${activeCol === 'cpm' ? ' active' : ''}`}>${formatNumber(pager.cpm || 0, 2)}</div>
          <div className={`reports-list-number-item table-td${activeCol === 'profit' ? ' active' : ''} text-bold text-white`}>${formatNumber(pager.profit, 2)}</div>
      </div>
  );
}

export default ReportsTotal;