import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import chevron_svg from './assets/icons/chevron-right.svg';
import video from './assets/icons/video.svg';
import lang from './assets/icons/land.svg';
import month from './assets/icons/month.svg';
import {IGetYoutuberStatsRequest} from "../../modules/rest";

const group = [
  {group_by: 'embed', title: 'VIDEO', icon: video},
  {group_by: 'country', title: 'COUNTRIES', icon: lang},
  {group_by: 'period', title: 'MONTHS', icon: month},
]

interface Props {
  request: IGetYoutuberStatsRequest;
  onGroupChange: (group: IGetYoutuberStatsRequest["group"]) => void;
  toggleFilter: () => void;
  filterVisible?: boolean;
}

const ReportsTabs: FC<Props> = ({request, onGroupChange, toggleFilter}) => {
  const {t} = useTranslation();

  const handleGroup = (group: IGetYoutuberStatsRequest['group']) => () => {
    if (group === request.group) return;
    onGroupChange(group);
  }

  const len = ((request.embed as number[])?.length || 0) + (request.country?.length || 0) + ((request?.periodBegin || request?.periodEnd) ? 1 : 0);

  return (
    <div className="report-filters-head">
      <div className="content-plan-header-tabs-wrapper">
        <div className='content-plan-header-tabs'>
          {group.map((item, i) => (
            <div className='my-tabs-item-wrap' key={item.group_by}>
              <div className={`my-tabs-item${request.group === item.group_by ? ' active' : ''}`}
                   onClick={handleGroup(item.group_by as IGetYoutuberStatsRequest['group'])}
              >
                <ReactSVG src={item.icon} className='react-icon report-chevron'/>
                <span className='ps-2'>{t(item.title)}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button type='button' className={`btn btn-outline-primary btn-lg report-filters-btn${len ? ' count' : ''}`}
              onClick={toggleFilter}>
        {len
          ?
          <div className='report-filter-count'>{len}</div>
          :
          null
        }
        {t('FILTER')}
      </button>
    </div>
  );
}

export default ReportsTabs;