import React, {FC} from 'react';
import {ECountry} from "../../../modules/rest";

interface Props {
  positions?: number;
  className?: string;
  country: ECountry;
}

const ListItemTrendsTag: FC<Props> = ({positions, country, className}) => {
  return (
    <div className={`custom-tag small m-1 ${className || ''}`}>
      {positions
        ?
        <div className="content-type-tags-place">
          {positions === 1 && <span className='content-type-tags-place-medal'>🥇</span>}
          {positions === 2 && <span className='content-type-tags-place-medal'>🥈</span>}
          {positions === 3 && <span className='content-type-tags-place-medal'>🥉</span>}
          <span className='pe-1'>{positions}</span>
        </div>
        :
        null
      }
      <span>{country.toUpperCase()}</span>
    </div>
  );
}

export default ListItemTrendsTag;