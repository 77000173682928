import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import ChannelCategoryItem from "./ChannelCategoryItem";
import {IMonitoringCategory} from "../../modules/rest";
import {observer} from "mobx-react";
import session from "../../modules/session";

interface Props {
  onUpdate: (category: IMonitoringCategory) => void;
  subscriptions: IMonitoringCategory[];
}

const SystemChannels: FC<Props> = observer(({subscriptions, onUpdate}) => {
  const {t} = useTranslation();
  return (
    <>
      <h4 className='mt-4'>{t('SYSTEM_CHANNELS')}</h4>
      <div className='text-muted mt-2 text-pre-wrap'>{t('SYSTEM_CHANNELS_TEXT')}</div>
      <div className='channel-category-list'>
        {session.categories?.map((item, i) => (
          <ChannelCategoryItem subscriptions={subscriptions} item={item} key={i} onUpdate={onUpdate}/>
        ))}
      </div>
    </>
  );
})

export default SystemChannels;