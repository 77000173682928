import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import star from '../../components/ContentType/assets/images/star.svg';
import fire from '../../components/ContentType/assets/images/fire.svg';
import question from '../../components/ContentType/assets/images/question.svg';
import edit from '../../components/ContentType/assets/images/edit.svg';
import moment from "moment";
import {EContentPlanVideoStatus, IContentPlanVideo} from "../../modules/rest";
import trash from "../../assets/images/icons/trash.svg";
import camera from "../../assets/images/icons/camera.svg";
import no_camera from "../../assets/images/icons/no_camera.svg";
import no_rocket from "../../assets/images/icons/no_rocket.svg";
import rocket from "../../assets/images/icons/rocket.svg";
import ContentPlanItemBody from "./ContentPlanItemBody";

interface Props {
  defaultStatus: EContentPlanVideoStatus;
  item: IContentPlanVideo;
  placeholder: string;
  onRemove: () => void;
  onEdit: () => void;
  onStatusChange: (status: EContentPlanVideoStatus) => void;
}

const icons: any = {
  trending: fire,
  'new-videos': star,
  comments: question,
}

const ContentPlanItem: FC<Props> = ({
                                      defaultStatus,
                                      placeholder,
                                      item,
                                      onRemove,
                                      onEdit,
                                      onStatusChange,
                                    }) => {
  const {t} = useTranslation();

  const empty = defaultStatus !== item.status;

  return (
    <div className={`content-plan-item-wrap${empty ? ' empty' : ''}`}>
      <div className="content-plan-item" onClick={() => onStatusChange(defaultStatus)}>
        <div className="content-plan-item-content">
          {!empty ?
            <div onClick={e => e.stopPropagation()}>
              <div className='content-plan-item-header'>
                <div className='d-flex align-items-center'>
                  <div className='text-14'>{moment(item.scheduledAt).format('HH:mm')}</div>
                  <div className="content-plan-item-header-icons-hidden">
                    <ReactSVG src={edit} className='react-icon ms-1 action-icon' onClick={onEdit}/>
                  </div>
                </div>
                {icons[item.origin] ?
                  <ReactSVG src={icons[item.origin]} className='react-icon content-plan-item-type-icon'/> : null}
                <div className="content-plan-item-header-icons-hidden">
                  {item.status === EContentPlanVideoStatus.VideoReady && (
                    <ReactSVG src={no_camera} className='react-icon action-icon ms-2'
                              onClick={() => onStatusChange(EContentPlanVideoStatus.VideoPending)}/>
                  )}
                  {item.status === EContentPlanVideoStatus.VideoUploaded && (
                    <ReactSVG src={no_rocket} className='react-icon action-icon ms-2'
                              onClick={() => onStatusChange(EContentPlanVideoStatus.VideoReady)}/>
                  )}
                  {item.status !== EContentPlanVideoStatus.VideoUploaded && (
                    <ReactSVG src={trash} className='react-icon icon-trash ms-2' onClick={onRemove}/>
                  )}

                </div>
              </div>
              <div className="content-plan-item-bottom">
                <ContentPlanItemBody item={item}/>
                <div className="content-plan-item-bottom-btn">
                  {item.status === EContentPlanVideoStatus.VideoPending && (
                    <button type='button' className='btn btn-outline-primary btn-xs btn-icon'
                            onClick={() => onStatusChange(EContentPlanVideoStatus.VideoReady)}>
                      <ReactSVG src={camera} className='react-icon'/>
                      {t('VIDEO_TAKEN')}
                    </button>
                  )}
                  {item.status === EContentPlanVideoStatus.VideoReady && (
                    <button type='button' className='btn btn-outline-primary btn-xs btn-icon'
                            onClick={() => onStatusChange(EContentPlanVideoStatus.VideoUploaded)}>
                      <ReactSVG src={rocket} className='react-icon'/>
                      {t('UPLOADED')}
                    </button>
                  )}
                </div>
              </div>
            </div>
            :
            <div className='text-muted'>
              {t(placeholder)}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default ContentPlanItem;