import React, {FC} from 'react';
import Loader from "../../Loader";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

interface Props {
  aside?: boolean;
  ready: boolean;
  pendingUpdate: boolean;
  refreshEnabled?: boolean;
  updatedAt: string|null;
  onClick?: () => void;
}

const CommentsUpdateStatus: FC<Props> = ({
                                           aside,
                                           ready,
                                           updatedAt,
                                           pendingUpdate,
                                           refreshEnabled,
                                           onClick,
                                         }) => {
  const {t} = useTranslation();

  if (aside) {
    if (!ready) return (
      <div className='trends-aside-last-update'>
        <Loader/>
      </div>
    )
    if (pendingUpdate) return (
      <div className='trends-aside-last-update'>
        <div className='text-muted-14 content-type-header-update-text'>{t('UPDATE')}</div>
        <Loader/>
      </div>
    )
    if (updatedAt) return (
      <div className='trends-aside-last-update'>
        <div className='text-muted-14 content-type-header-update-text'>{t('LAST_UPDATE')}:</div>
        <div
          className='text-bold-14 mt-1 text-lowercase content-type-header-update-text'>{moment(updatedAt).calendar()}</div>
      </div>
    )
    return null;
  }

  if (pendingUpdate) return (
    <div className={`content-type-header-update loading`}>
      <span className='content-type-header-update-text'>{t('UPDATE')}</span>
      <Loader className='ms-2'/>
    </div>
  )

  if (refreshEnabled) return (
    <div onClick={onClick} className={`content-type-header-update`}>
      <span className='content-type-header-update-text'>{t('UPDATE')}</span>
    </div>
  )

  if (updatedAt) return (
    <div className='content-type-header-update text-muted-14 cursor-default date_at' title={t('UPDATE_ONCE_DAY') || ''}>
      <span className='content-type-header-update-text'>
        <span>{t('LAST_UPDATE')}:</span><span className='text-lowercase ps-1'>{moment(updatedAt).calendar()}</span>
        </span>
    </div>
  )
  return null;
}

export default CommentsUpdateStatus;