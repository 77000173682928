import React, {FC, useCallback, useState} from 'react';
import stub from "../../assets/images/icons/video_stub.svg";
import dots from "../../assets/images/icons/dots_vertical.svg";
import {ReactSVG} from "react-svg";
import ModalDropdown from "../../modals/ModalDropdown";
import trash_svg from "../../assets/images/icons/trash.svg";
import {useTranslation} from "react-i18next";
import {confirmDialog} from "../../modules/confirm";
import Loadable from "../../components/Loadable";
import {IMonitoringChannel} from "../../modules/rest";
import {thumb} from "../../modules/utils";
import trash from "../../assets/images/icons/trash.svg";

interface Props {
  item: IMonitoringChannel;
  onRemove: (id: number) => void;
}

const ChannelsListItem: FC<Props> = ({item, onRemove}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleRemoveChannel = useCallback((e: any) => {
    e.preventDefault();
    confirmDialog(t('DELETE_CHANNEL_CONFIRM_TITLE', {title: item.channel.name}), {
      title: t('DELETING_CHANNEL')!,
      danger: true,
      confirmText: t('REMOVE')!,
    }).then(async (confirmed) => {
      if (!confirmed) return;
      setLoading(true);
      await onRemove(item.id);
      setLoading(false);
    });
  }, []);

  return (
    <Loadable loading={loading} className='channel-monitoring-list-item'>
      <div style={{minWidth: 40, width: 40}} className='text-muted-14'>{item.id}</div>
      <a href={item.channel.url} target='_blank' className='channel-monitoring-list-item-content'>
        <div className="channel-monitoring-list-item-channel">
          <img src={thumb(item.channel?.icon?.id!, 80)} alt="img"/>
          <div className='d-grid overflow-hidden pe-3 flex-1'>
            <h4 className='text-truncate'>{item.channel.name}</h4>
          </div>
          <div className="channel-monitoring-list-item-category">
            <div className='text-medium text-truncate'>
              {item?.categories?.map((c, i) => (
                <span key={c.id}>{i ? ', ' : ''}{c.name}</span>
              ))}
            </div>
          </div>
          <div style={{width: 72, minWidth: 72}} className='d-flex justify-content-end'>
            <div className="channel-monitoring-list-item-filter" onClick={(e) => e.preventDefault()}>
              <ReactSVG src={trash} className='react-icon icon-trash' onClick={handleRemoveChannel}/>
              {/*<ModalDropdown icon={dots}>*/}
              {/*  <div onClick={handleRemoveChannel} className={'modal-dropdown-hidden-item text-danger pe-4'}>*/}
              {/*    <ReactSVG src={trash_svg} className='react-icon'/>*/}
              {/*    <span className="text-nowrap ps-2">{t('REMOVE')}</span>*/}
              {/*  </div>*/}
              {/*</ModalDropdown>*/}
            </div>
          </div>
        </div>
      </a>
    </Loadable>
  );
}

export default ChannelsListItem;