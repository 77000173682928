import React, {FC, useRef} from 'react';
import Video from "../Video";
import {IVideoEmbed} from "../../modules/rest";
import {getEmbedName} from "../../modules/utils";

interface Props {
  ve: IVideoEmbed;
  activeVideo?: number | null;
  onChangeActiveVideo: () => void;
  onClick: () => void;
  checked: boolean;
  singleLine?: boolean;
}

const VideoEmbedSelectItem: FC<Props> = ({
                                           checked,
                                           ve,
                                           activeVideo,
                                           onChangeActiveVideo,
                                           singleLine,
                                           onClick,
                                         }) => {
  const videoRef: any = useRef(null);

  const active = ve.id === activeVideo;

  return (
    <div key={ve.id} onClick={singleLine ? onClick : undefined}
         className={`video-embed-select-options-item${checked ? ' checked' : ''}`}>
      <div key={ve.id} className="video-embed-select-options-item-content">
        <Video
          onClick={onClick}
          active={active}
          onChangeActiveVideo={onChangeActiveVideo}
          ref={videoRef}
          className=''
          ve={ve}
        />
        <div className='d-grid overflow-hidden mt-1 video-embed-select-options-item-name'>
          <div className='text-14 text-truncate'>{getEmbedName(ve)}</div>
        </div>
        {!singleLine && <div className="video-embed-select-options-radio" onClick={onClick}/>}
      </div>
    </div>
  );
}

export default VideoEmbedSelectItem;