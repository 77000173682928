import {
  IVideo,
} from '../../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalClose from "../../ModalClose";
import {  thumb} from "../../../modules/utils";
import {ReactSVG} from "react-svg";
import trash_svg from "../../../assets/images/icons/trash.svg";
import youtube_outline_svg from "../../../assets/images/icons/youtube_outline.svg";
import stub from "../../../assets/images/icons/video_stub.svg";
import {confirmDialog} from "../../../modules/confirm";
import {API} from "../../../modules/api";
import {toast} from "react-toastify";
import Loadable from "../../../components/Loadable";
import {VideoStatuses} from "../../../modules/directory";
import ModalHeader from "../../ModalHeader";

interface Props {
  video: IVideo;

  resolve(needUpdate?: boolean): void;
}

const VideoItemMoreModal: FC<Props> = ({video, resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    resolve();
  }
  const deleteVideo = () => {
    confirmDialog(t('DELETE_CLIP_CONFIRM_TITLE', {title: video.title}), {
      title: t('DELETING_CLIP') || '',
      danger: true,
      confirmText: t('REMOVE') || '',
    }).then((confirmed) => {
      if (!confirmed) return;
      setLoading(true);
      API.VideoEmbeds.deleteYoutubeVideo(video.id)
        .then(() => {
          toast.success(t('CLIP_REMOVED'));
          resolve(true);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    });
  }

  return (
    <div className='video-more-modal'>
      <ModalHeader onClick={handleClose}>
        <div className='flex-1 d-flex'>
          <div className='my-video-preview'>
            {video.cover
              ?
              <img src={thumb(video.cover!.id, 180)} alt='preview'/>
              :
              <ReactSVG src={stub} className='react-icon'/>
            }
          </div>
          <div className='d-grid overflow-hidden ps-2'>
            <div className='text-truncate-2 text-14'> {video.title || video.extId}</div>
          </div>
        </div>
      </ModalHeader>

      <div className='modal-content-type-edit-small mt-3'>
        <div className="modal-content-type-edit-small-item pointer-event-none text-muted">
          {t(video.hasTrack ? VideoStatuses[video.status] : 'TRACK_CUT')}
        </div>
        <a href={`https://studio.youtube.com/video/${video.extId}/edit`} target='_blank' className='modal-content-type-edit-small-item'>
          <ReactSVG src={youtube_outline_svg} className='react-icon'/>
          <span className="text-nowrap ps-2 text-muted">{t('GO_TO_STUDIO')}</span>
        </a>
        {/*<Loadable loading={loading} className='modal-content-type-edit-small-item' onClick={deleteVideo}>*/}
        {/*  <ReactSVG src={trash_svg} className='react-icon'/>*/}
        {/*  <span className="text-nowrap ps-2 text-danger">{t('REMOVE')}</span>*/}
        {/*</Loadable>*/}
      </div>
    </div>
  );
};

const openVideoItemMoreModal = (video: IVideo): Promise<boolean> => asyncModal(VideoItemMoreModal, {video}, {
  showCloseIcon: false,
  center: true
});

export {openVideoItemMoreModal};
