import React, {FC} from 'react';
import './assets/styles.scss';
import {useTranslation} from "react-i18next";
import {thumb} from "../../modules/utils";
import {IAsset} from "../../modules/rest";

interface Props {
  className?: string;
  preview: IAsset|null;
}

const HowInsertVideo: FC<Props> = ({className, preview}) => {
  const {t} = useTranslation();

  return (
    <div className={className || ''}>
      <div className='text-muted'>{t('HOW_TO_INSERT')}</div>
      <div className="how-insert-video-wrap">
        <div className="how-insert-video">
          <div className='how-insert-video-title'>{t('YOUR_INTRO')}</div>
          <div className='how-insert-video-content'>
            <div className='d-flex'>
              {Array(2).fill({}).map((item, i) => (
                <div className={'how-insert-video-item'} key={i}/>
              ))}
            </div>
            <div className={'how-insert-video-footer'}>
              {Array(6).fill({}).map((item, i) => (
                <div className={'how-insert-video-footer-item'} key={i}/>
              ))}
            </div>
          </div>
        </div>
        <div className="how-insert-video active">
          <div className='how-insert-video-title'>{t('PROMO')}</div>
          <div className='how-insert-video-content'>
            <img src={thumb(preview?.id!, 200)} alt='preview' />
          </div>
        </div>
        <div className="how-insert-video">
          <div className='how-insert-video-title'>{t('YOUR_VIDEO')}</div>
          <div className='how-insert-video-content'>
            <div className='d-flex'>
              {Array(6).fill({}).map((item, i) => (
                <div className={'how-insert-video-item'} key={i}/>
              ))}
            </div>
            <div className={'how-insert-video-footer'}>
              {Array(2).fill({}).map((item, i) => (
                <div className={'how-insert-video-footer-item'} key={i}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowInsertVideo;