import React, {FC} from 'react';
import CopyToClipboard2 from "../../components/CopyToClipboard2";
import {useTranslation} from "react-i18next";

interface Props {
  tags?: string[] | null;
}

const BestReactionsTags: FC<Props> = ({tags}) => {
  const {t} = useTranslation();

  if (!tags?.length) return null;

  return (
    <div className='novelties-aside-top best-reactions-modal-tags'>
      <div className='novelties-aside-top-content'>
        <div className='best-reactions-modal-tags-head'>
          <div className='text-nowrap'>{t('BEST_TAGS')}</div>
          <CopyToClipboard2 text={tags.join(', ')}/>
        </div>

        <div className="best-reactions-modal-tags-content">
          {tags.map(item => (
            <div className='best-reactions-modal-tag text-truncate text-muted-12' key={item}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BestReactionsTags;