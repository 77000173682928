import {
  ECountry,
  ELanguage,
  EReviewStatus,
  EUserStatus, EVideoStatus,
} from './rest';

const Regions: ECountry[] = [ECountry.AU, ECountry.AT, ECountry.AZ, ECountry.AL, ECountry.DZ, ECountry.AR, ECountry.AM, ECountry.BD, ECountry.BH, ECountry.BY, ECountry.BE, ECountry.BG, ECountry.BO, ECountry.BA, ECountry.BR, ECountry.GB, ECountry.HU, ECountry.VE, ECountry.VN, ECountry.GH, ECountry.GT, ECountry.DE, ECountry.HN, ECountry.HK, ECountry.GR, ECountry.GE, ECountry.DK, ECountry.DO, ECountry.EG, ECountry.ZW, ECountry.IL, ECountry.IN, ECountry.ID, ECountry.JO, ECountry.IQ, ECountry.IE, ECountry.IS, ECountry.ES, ECountry.IT, ECountry.YE, ECountry.KZ, ECountry.KH, ECountry.CA, ECountry.QA, ECountry.KE, ECountry.CY, ECountry.TW, ECountry.CO, ECountry.CR, ECountry.KW, ECountry.LA, ECountry.LV, ECountry.LB, ECountry.LY, ECountry.LT, ECountry.LI, ECountry.LU, ECountry.MK, ECountry.MY, ECountry.MT, ECountry.MA, ECountry.MX, ECountry.MD, ECountry.MN, ECountry.NP, ECountry.NG, ECountry.NL, ECountry.NI, ECountry.NZ, ECountry.NO, ECountry.AE, ECountry.OM, ECountry.PK, ECountry.PA, ECountry.PG, ECountry.PY, ECountry.PE, ECountry.PL, ECountry.PT, ECountry.PR, ECountry.KR, ECountry.RU, ECountry.RO, ECountry.SV, ECountry.SA, ECountry.SN, ECountry.RS, ECountry.SG, ECountry.SK, ECountry.SI, ECountry.US, ECountry.TH, ECountry.TZ, ECountry.TN, ECountry.TR, ECountry.UG, ECountry.UA, ECountry.UY, ECountry.PH, ECountry.FI, ECountry.FR, ECountry.HR, ECountry.ME, ECountry.CZ, ECountry.CL, ECountry.CH, ECountry.SE, ECountry.LK, ECountry.EC, ECountry.EE, ECountry.ZA, ECountry.JM, ECountry.JP];
const Regions2: ECountry[] = [ECountry.AE, ECountry.AL, ECountry.AM, ECountry.AR, ECountry.AS, ECountry.AT, ECountry.AU, ECountry.AW, ECountry.BA, ECountry.BD, ECountry.BE, ECountry.BG, ECountry.BH, ECountry.BL, ECountry.BM, ECountry.BO, ECountry.BR, ECountry.BY, ECountry.CA, ECountry.CC, ECountry.CH, ECountry.CL, ECountry.CO, ECountry.CR, ECountry.CX, ECountry.CY, ECountry.CZ, ECountry.DE, ECountry.DK, ECountry.DO, ECountry.DZ, ECountry.EC, ECountry.EE, ECountry.EG, ECountry.ES, ECountry.FI, ECountry.FJ, ECountry.FR, ECountry.GB, ECountry.GE, ECountry.GF, ECountry.GH, ECountry.GP, ECountry.GR, ECountry.GT, ECountry.GU, ECountry.HK, ECountry.HM, ECountry.HN, ECountry.HR, ECountry.HU, ECountry.ID, ECountry.IE, ECountry.IL, ECountry.IN, ECountry.IQ, ECountry.IS, ECountry.IT, ECountry.JO, ECountry.JP, ECountry.KE, ECountry.KH, ECountry.KR, ECountry.KW, ECountry.KY, ECountry.LA, ECountry.LB, ECountry.LI, ECountry.LK, ECountry.LT, ECountry.LU, ECountry.LV, ECountry.LY, ECountry.MA, ECountry.MC, ECountry.MF, ECountry.MK, ECountry.MP, ECountry.MQ, ECountry.MT, ECountry.MX, ECountry.MY, ECountry.NF, ECountry.NG, ECountry.NI, ECountry.NL, ECountry.NO, ECountry.NP, ECountry.NR, ECountry.NU, ECountry.NZ, ECountry.OM, ECountry.PA, ECountry.PE, ECountry.PF, ECountry.PH, ECountry.PK, ECountry.PL, ECountry.PM, ECountry.PR, ECountry.PT, ECountry.PY, ECountry.QA, ECountry.RE, ECountry.RO, ECountry.RS, ECountry.SA, ECountry.SB, ECountry.SE, ECountry.SG, ECountry.SK, ECountry.SN, ECountry.SV, ECountry.TC, ECountry.TH, ECountry.TK, ECountry.TN, ECountry.TO, ECountry.TR, ECountry.TV, ECountry.TW, ECountry.TZ, ECountry.UA, ECountry.UG, ECountry.US, ECountry.UY, ECountry.VE, ECountry.VI, ECountry.VN, ECountry.VU, ECountry.WF, ECountry.WS, ECountry.YE, ECountry.YT, ECountry.ZA, ECountry.ZW];

const Countries: Record<ECountry, string> = {
  [ECountry.AU]: 'Австралия',
  [ECountry.AT]: 'Австрия',
  [ECountry.AZ]: 'Азербайджан',
  [ECountry.AX]: 'Аландские острова',
  [ECountry.AL]: 'Албания',
  [ECountry.DZ]: 'Алжир',
  [ECountry.VI]: 'Виргинские Острова (США)',
  [ECountry.AS]: 'Американское Самоа',
  [ECountry.AI]: 'Ангилья',
  [ECountry.AO]: 'Ангола',
  [ECountry.AD]: 'Андорра',
  [ECountry.AQ]: 'Антарктика',
  [ECountry.AG]: 'Антигуа и Барбуда',
  [ECountry.AR]: 'Аргентина',
  [ECountry.AM]: 'Армения',
  [ECountry.AW]: 'Аруба',
  [ECountry.AF]: 'Афганистан',
  [ECountry.BS]: 'Багамские Острова',
  [ECountry.BD]: 'Бангладеш',
  [ECountry.BB]: 'Барбадос',
  [ECountry.BH]: 'Бахрейн',
  [ECountry.BZ]: 'Белиз',
  [ECountry.BY]: 'Беларусь',
  [ECountry.BE]: 'Бельгия',
  [ECountry.BJ]: 'Бенин',
  [ECountry.BM]: 'Бермуды',
  [ECountry.BG]: 'Болгария',
  [ECountry.BO]: 'Боливия',
  [ECountry.BQ]: 'Бонайре, Синт-Эстатиус и Саба',
  [ECountry.BA]: 'Босния и Герцеговина',
  [ECountry.BW]: 'Ботсвана',
  [ECountry.BR]: 'Бразилия',
  [ECountry.IO]: 'Британская территория в Индийском океане',
  [ECountry.VG]: 'Виргинские Острова (Великобритания)',
  [ECountry.BN]: 'Бруней',
  [ECountry.BF]: 'Буркина-Фасо',
  [ECountry.BI]: 'Бурунди',
  [ECountry.BT]: 'Бутан',
  [ECountry.VU]: 'Вануату',
  [ECountry.VA]: 'Ватикан',
  [ECountry.GB]: 'Великобритания',
  [ECountry.HU]: 'Венгрия',
  [ECountry.VE]: 'Венесуэла',
  [ECountry.UM]: 'Внешние малые острова США',
  [ECountry.TL]: 'Восточный Тимор',
  [ECountry.VN]: 'Вьетнам',
  [ECountry.GA]: 'Габон',
  [ECountry.HT]: 'Гаити',
  [ECountry.GY]: 'Гайана',
  [ECountry.GM]: 'Гамбия',
  [ECountry.GH]: 'Гана',
  [ECountry.GP]: 'Гваделупа',
  [ECountry.GT]: 'Гватемала',
  [ECountry.GF]: 'Гвиана',
  [ECountry.GN]: 'Гвинея',
  [ECountry.GW]: 'Гвинея-Бисау',
  [ECountry.DE]: 'Германия',
  [ECountry.GG]: 'Гернси',
  [ECountry.GI]: 'Гибралтар',
  [ECountry.HN]: 'Гондурас',
  [ECountry.HK]: 'Гонконг',
  [ECountry.GD]: 'Гренада',
  [ECountry.GL]: 'Гренландия',
  [ECountry.GR]: 'Греция',
  [ECountry.GE]: 'Грузия',
  [ECountry.GU]: 'Гуам',
  [ECountry.DK]: 'Дания',
  [ECountry.JE]: 'Джерси',
  [ECountry.DJ]: 'Джибути',
  [ECountry.DM]: 'Доминика',
  [ECountry.DO]: 'Доминиканская Республика',
  [ECountry.CD]: 'ДР Конго',
  [ECountry.EU]: 'Флаг ЕС Европейский союз',
  [ECountry.EG]: 'Египет',
  [ECountry.ZM]: 'Замбия',
  [ECountry.EH]: 'САДР',
  [ECountry.ZW]: 'Зимбабве',
  [ECountry.IL]: 'Израиль',
  [ECountry.IN]: 'Индия',
  [ECountry.ID]: 'Индонезия',
  [ECountry.JO]: 'Иордания',
  [ECountry.IQ]: 'Ирак',
  [ECountry.IR]: 'Иран',
  [ECountry.IE]: 'Ирландия',
  [ECountry.IS]: 'Исландия',
  [ECountry.ES]: 'Испания',
  [ECountry.IT]: 'Италия',
  [ECountry.YE]: 'Йемен',
  [ECountry.CV]: 'Кабо-Верде',
  [ECountry.KZ]: 'Казахстан',
  [ECountry.KY]: 'Острова Кайман',
  [ECountry.KH]: 'Камбоджа',
  [ECountry.CM]: 'Камерун',
  [ECountry.CA]: 'Канада',
  [ECountry.QA]: 'Катар',
  [ECountry.KE]: 'Кения',
  [ECountry.CY]: 'Кипр',
  [ECountry.KG]: 'Киргизия',
  [ECountry.KI]: 'Кирибати',
  [ECountry.TW]: 'Китайская Республика',
  [ECountry.KP]: 'КНДР (Корейская Народно-Демократическая Республика)',
  [ECountry.CN]: 'Китай (Китайская Народная Республика)',
  [ECountry.CC]: 'Кокосовые острова',
  [ECountry.CO]: 'Колумбия',
  [ECountry.KM]: 'Коморы',
  [ECountry.CR]: 'Коста-Рика',
  [ECountry.CI]: 'Кот-д’Ивуар',
  [ECountry.CU]: 'Куба',
  [ECountry.KW]: 'Кувейт',
  [ECountry.CW]: 'Кюрасао',
  [ECountry.LA]: 'Лаос',
  [ECountry.LV]: 'Латвия',
  [ECountry.LS]: 'Лесото',
  [ECountry.LR]: 'Либерия',
  [ECountry.LB]: 'Ливан',
  [ECountry.LY]: 'Ливия',
  [ECountry.LT]: 'Литва',
  [ECountry.LI]: 'Лихтенштейн',
  [ECountry.LU]: 'Люксембург',
  [ECountry.MU]: 'Маврикий',
  [ECountry.MR]: 'Мавритания',
  [ECountry.MG]: 'Мадагаскар',
  [ECountry.YT]: 'Майотта',
  [ECountry.MO]: 'Макао',
  [ECountry.MK]: 'Северная Македония',
  [ECountry.MW]: 'Малави',
  [ECountry.MY]: 'Малайзия',
  [ECountry.ML]: 'Мали',
  [ECountry.MV]: 'Мальдивы',
  [ECountry.MT]: 'Мальта',
  [ECountry.MA]: 'Марокко',
  [ECountry.MQ]: 'Мартиника',
  [ECountry.MH]: 'Маршалловы Острова',
  [ECountry.MX]: 'Мексика',
  [ECountry.FM]: 'Микронезия',
  [ECountry.MZ]: 'Мозамбик',
  [ECountry.MD]: 'Молдова',
  [ECountry.MC]: 'Монако',
  [ECountry.MN]: 'Монголия',
  [ECountry.MS]: 'Монтсеррат',
  [ECountry.MM]: 'Мьянма',
  [ECountry.NA]: 'Намибия',
  [ECountry.NR]: 'Науру',
  [ECountry.NP]: 'Непал',
  [ECountry.NE]: 'Нигер',
  [ECountry.NG]: 'Нигерия',
  [ECountry.NL]: 'Нидерланды',
  [ECountry.NI]: 'Никарагуа',
  [ECountry.NU]: 'Ниуэ',
  [ECountry.NZ]: 'Новая Зеландия',
  [ECountry.NC]: 'Новая Каледония',
  [ECountry.NO]: 'Норвегия',
  [ECountry.AE]: 'ОАЭ',
  [ECountry.OM]: 'Оман',
  [ECountry.BV]: 'Остров Буве',
  [ECountry.IM]: 'Остров Мэн',
  [ECountry.CK]: 'Острова Кука',
  [ECountry.NF]: 'Остров Норфолк',
  [ECountry.CX]: 'Остров Рождества',
  [ECountry.PN]: 'Острова Питкэрн',
  [ECountry.SH]: 'Остров Святой Елены',
  [ECountry.PK]: 'Пакистан',
  [ECountry.PW]: 'Палау',
  [ECountry.PS]: 'Государство Палестина',
  [ECountry.PA]: 'Панама',
  [ECountry.PG]: 'Папуа — Новая Гвинея',
  [ECountry.PY]: 'Парагвай',
  [ECountry.PE]: 'Перу',
  [ECountry.PL]: 'Польша',
  [ECountry.PT]: 'Португалия',
  [ECountry.PR]: 'Пуэрто-Рико',
  [ECountry.CG]: 'Республика Конго',
  [ECountry.KR]: 'Республика Корея',
  [ECountry.RE]: 'Реюньон',
  [ECountry.RU]: 'Россия',
  [ECountry.RW]: 'Руанда',
  [ECountry.RO]: 'Румыния',
  [ECountry.SV]: 'Сальвадор',
  [ECountry.WS]: 'Самоа',
  [ECountry.SM]: 'Сан-Марино',
  [ECountry.ST]: 'Сан-Томе и Принсипи',
  [ECountry.SA]: 'Саудовская Аравия',
  [ECountry.SZ]: 'Эсватини',
  [ECountry.MP]: 'Северные Марианские Острова',
  [ECountry.SC]: 'Сейшельские Острова',
  [ECountry.BL]: 'Сен-Бартелеми',
  [ECountry.MF]: 'Сен-Мартен',
  [ECountry.PM]: 'Сен-Пьер и Микелон',
  [ECountry.SN]: 'Сенегал',
  [ECountry.VC]: 'Сент-Винсент и Гренадины',
  [ECountry.KN]: 'Сент-Китс и Невис',
  [ECountry.LC]: 'Сент-Люсия',
  [ECountry.RS]: 'Сербия',
  [ECountry.SG]: 'Сингапур',
  [ECountry.SX]: 'Синт-Мартен',
  [ECountry.SY]: 'Сирия',
  [ECountry.SK]: 'Словакия',
  [ECountry.SI]: 'Словения',
  [ECountry.SB]: 'Соломоновы Острова',
  [ECountry.SO]: 'Сомали',
  [ECountry.SD]: 'Судан',
  [ECountry.SR]: 'Суринам',
  [ECountry.US]: 'США',
  [ECountry.SL]: 'Сьерра-Леоне',
  [ECountry.TJ]: 'Таджикистан',
  [ECountry.TH]: 'Таиланд',
  [ECountry.TZ]: 'Танзания',
  [ECountry.TC]: 'Теркс и Кайкос',
  [ECountry.TG]: 'Того',
  [ECountry.TK]: 'Токелау',
  [ECountry.TO]: 'Тонга',
  [ECountry.TT]: 'Тринидад и Тобаго',
  [ECountry.TV]: 'Тувалу',
  [ECountry.TN]: 'Тунис',
  [ECountry.TM]: 'Туркменистан',
  [ECountry.TR]: 'Турция',
  [ECountry.UG]: 'Уганда',
  [ECountry.UZ]: 'Узбекистан',
  [ECountry.UA]: 'Украина',
  [ECountry.WF]: 'Уоллис и Футуна',
  [ECountry.UY]: 'Уругвай',
  [ECountry.FO]: 'Фарерские острова',
  [ECountry.FJ]: 'Фиджи',
  [ECountry.PH]: 'Филиппины',
  [ECountry.FI]: 'Финляндия',
  [ECountry.FK]: 'Фолклендские острова',
  [ECountry.FR]: 'Франция',
  [ECountry.PF]: 'Французская Полинезия',
  [ECountry.TF]: 'Французские Южные и Антарктические территории',
  [ECountry.HM]: 'Херд и Макдональд',
  [ECountry.HR]: 'Хорватия',
  [ECountry.CF]: 'ЦАР',
  [ECountry.TD]: 'Чад',
  [ECountry.ME]: 'Черногория',
  [ECountry.CZ]: 'Чехия',
  [ECountry.CL]: 'Чили',
  [ECountry.CH]: 'Швейцария',
  [ECountry.SE]: 'Швеция',
  [ECountry.SJ]: 'Флаг Шпицбергена и Ян-Майена Шпицберген и Ян-Майен',
  [ECountry.LK]: 'Шри-Ланка',
  [ECountry.EC]: 'Эквадор',
  [ECountry.GQ]: 'Экваториальная Гвинея',
  [ECountry.ER]: 'Эритрея',
  [ECountry.EE]: 'Эстония',
  [ECountry.ET]: 'Эфиопия',
  [ECountry.ZA]: 'ЮАР',
  [ECountry.GS]: 'Южная Георгия и Южные Сандвичевы Острова',
  [ECountry.SS]: 'Южный Судан',
  [ECountry.JM]: 'Ямайка',
  [ECountry.JP]: 'Япония',
};


const Languages: Record<ELanguage|string, string|undefined> = {
  ru: 'Русский',
  en: 'Английский',
  es: 'Испанский',
  it: 'Итальянский',
  aa: 'Афарский',
  ab: 'Абхазский',
  ae: 'Авестийский',
  af: 'Африкаанс',
  ak: 'Акан',
  am: 'Амхарский',
  ar: 'Арабский',
  as: 'Ассамский',
  av: 'Аварский',
  ay: 'Аймара',
  az: 'Азербайджанский',
  ba: 'Башкирский',
  be: 'Беларусский',
  bg: 'Болгарский',
  bi: 'Бислама',
  bm: 'Бамбара',
  bn: 'Бенгальский',
  bo: 'Тибетский',
  br: 'Бретонский',
  bs: 'Боснийский',
  ca: 'Каталанский',
  ce: 'Чеченский',
  ch: 'Чаморро',
  co: 'Корсиканский',
  cs: 'Чешский',
  cu: 'Старославянский',
  cv: 'Чувашский',
  cy: 'Валлийский',
  da: 'Датский',
  de: 'Немецкий',
  dv: 'Дивехи',
  dz: 'Дзонг-кэ',
  ee: 'Эве',
  el: 'Греческий',
  eo: 'Эсперанто',
  et: 'Эстонский',
  eu: 'Баскский',
  fa: 'Персидский',
  ff: 'Фулах',
  fi: 'Финский',
  fj: 'Фиджи',
  fo: 'Фарерский',
  fr: 'Французский',
  fy: 'Фризский',
  ga: 'Ирландский',
  gd: 'Гэльский',
  gl: 'Галисийский',
  gn: 'Гуарани',
  gu: 'Гуджарати',
  gv: 'Мэнский',
  ha: 'Хауса',
  he: 'Иврит',
  hi: 'Хинди',
  ho: 'Хиримоту',
  hr: 'Хорватский',
  hu: 'Венгерский',
  hy: 'Армянский',
  hz: 'Гереро',
  ia: 'Интерлингва',
  id: 'Индонезийский',
  ie: 'Интерлингве',
  ig: 'Игбо',
  ik: 'Инупиак',
  is: 'Исландский',
  iu: 'Инуктитут',
  ja: 'Японский',
  jv: 'Яванский',
  ka: 'Грузинский',
  kg: 'Конго',
  ki: 'Кикуйю',
  kj: 'Киньяма',
  kk: 'Казахский',
  kl: 'Гренландский',
  km: 'Кхмерский',
  kn: 'Каннада',
  ko: 'Корейский',
  kr: 'Канури',
  ks: 'Кашмири',
  ku: 'Курдский',
  kv: 'Коми',
  kw: 'Корнский',
  ky: 'Киргизский',
  la: 'Латинский',
  lb: 'Люксембургский',
  lg: 'Ганда',
  ln: 'Лингала',
  lo: 'Лаосский',
  lt: 'Литовский',
  lu: 'Луба-катанга',
  lv: 'Латышский',
  me: 'Мерянский',
  mg: 'Малагасийский',
  mh: 'Маршалльский',
  mi: 'Маори',
  mk: 'Македонский',
  ml: 'Малаялам',
  mn: 'Монгольский',
  mo: 'Молдавский',
  mr: 'Маратхи',
  ms: 'Малайский',
  mt: 'Мальтийский',
  my: 'Бирманский',
  na: 'Науру',
  nd: 'Ндебеле северный',
  ne: 'Непальский',
  ng: 'Ндунга',
  nl: 'Нидерландский',
  nn: 'Нюнорск',
  no: 'Норвежский',
  nr: 'Ндебеле южный',
  nv: 'Навахо',
  ny: 'Ньянджа',
  oc: 'Окситанский',
  oj: 'Оджибве',
  om: 'Оромо',
  or: 'Ория',
  os: 'Осетинский',
  pa: 'Пенджабский',
  pi: 'Пали',
  pl: 'Польский',
  ps: 'Пушту',
  pt: 'Португальский',
  qu: 'Кечуа',
  rm: 'Ретороманский',
  rn: 'Рунди',
  ro: 'Румынский',
  rw: 'Руанда',
  sa: 'Санскрит',
  sc: 'Сардинский',
  sd: 'Синдхи',
  sg: 'Санго',
  si: 'Сингальский',
  sk: 'Словацкий',
  sl: 'Словенский',
  sm: 'Самоанский',
  sn: 'Шона',
  so: 'Сомали',
  sq: 'Албанский',
  sr: 'Сербский',
  ss: 'Свази',
  st: 'Сото южный',
  su: 'Сунданский',
  sv: 'Шведский',
  sw: 'Суахили',
  ta: 'Тамильский',
  te: 'Телугу',
  tg: 'Таджикский',
  th: 'Тайский',
  ti: 'Тигринья',
  tk: 'Туркменский',
  tl: 'Тагальский',
  tn: 'Тсвана',
  to: 'Тонганский',
  tr: 'Турецкий',
  ts: 'Тсонга',
  tt: 'Татарский',
  tw: 'Тви',
  ty: 'Таитянский',
  ug: 'Уйгурский',
  uk: 'Украинский',
  ur: 'Урду',
  uz: 'Узбекский',
  ve: 'Венда',
  vi: 'Вьетнамский',
  vo: 'Волапюк',
  wo: 'Волоф',
  xh: 'Коса',
  yi: 'Идиш',
  yo: 'Йоруба',
  za: 'Чжуанский',
  zh: 'Китайский',
  zu: 'Зулу',
};


const ReviewStates: Record<EReviewStatus, string> = {
  [EReviewStatus.Draft]: 'DRAFT',
  [EReviewStatus.Review]: 'UNDER_REVIEW',
  [EReviewStatus.Reject]: 'REJECTED',
  [EReviewStatus.Approve]: 'APPROVED',
};


const UserStates: Record<EUserStatus, string> = {
  [EUserStatus.Active]: 'ACTIVE',
  [EUserStatus.Block]: 'BLOCKED_1',
};

const VideoStatuses: Record<EVideoStatus, string> = {
  [EVideoStatus.Public]: 'PUBLISHED',
  [EVideoStatus.Unavailable]: 'NOT_AVAILABLE',
  [EVideoStatus.Unlisted]: 'IS_HIDDEN',
};
export {
  Languages,
  Countries,
  UserStates,
  VideoStatuses,
  ReviewStates,
  Regions,
  Regions2,
};


