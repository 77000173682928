import React, {FC} from 'react';
import {formatNumber, formatViews} from "../../../modules/utils";
import {IUserViewsHistory, IVideoEmbedViewsHistory} from "../../../modules/rest";
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
  history?: (IUserViewsHistory | IVideoEmbedViewsHistory)&{ max: number }
}

const MonetizationHistoryGraph: FC<Props> = ({className, history}) => {
  const {t} = useTranslation();
  if (!history) return null;

  const historyArr = history.history?.length ? history.history : Array(28).fill({})
  return (
    <div className={className || ''}>
      <div className='d-flex align-items-center mb-2'>
        <h4>{formatNumber(history.views)}</h4>
        <div
          className={`aside-monetization_stat-hidden-badge${history.diff < 0 ? ' badge-danger' : ''}`}>
          {history.diff >= 0 ? '+' : ''}{formatNumber(history.diff)}
        </div>
      </div>
      <div className='d-flex flex-column-reverse'>
        <div className={`aside-monetization_stat-hidden-graph ${!history.history?.length ? ' empty' : ''}`}>
          {historyArr.map((item: any, i: number) => (
            <div className="aside-monetization_stat-hidden-graph-item" key={i}
                 style={{height: (item.value || 1) /( history.max || 1) * 24 + 8}}>
              <div
                className="aside-monetization_stat-hidden-graph-item-hidden">{item.date} &middot; {formatViews(item.value || '')}</div>
            </div>
          ))}
        </div>
        <div className='text-muted-12 aside-monetization_stat-hidden-graph-title'>
          {t('VIEWS')} &middot; {t('LAST_VIEWS')}
        </div>
      </div>
    </div>
  );
}

export default MonetizationHistoryGraph;