import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {ReactSVG} from "react-svg";
import card from './assets/icons/card.svg'
import eye from "../../assets/images/icons/eye.svg";
import {openBillingPaymentItemModal, openBillingRemoveWithdrawModal} from "../../modals";
import chevron_left from "../../assets/images/icons/chevron_left.svg";
import chevron_right from "../../assets/images/icons/chevron_right.svg";
import {EWithdrawStatus, IPagedData, IWithdraw} from "../../modules/rest";
import Pagination from "../../components/Pagination";
import payoneer_img from "./assets/payments_icons/payoneer.png";
import master_img from "./assets/payments_icons/master.png";
import tether_img from "./assets/payments_icons/tether.png";
import BillingWithdrawStatus from "./BillingWithdrawStatus";

const icons: any = {
  payoneer: payoneer_img,
  platron: master_img,
  usdt_trc20: tether_img,
}

interface Props {
  pager?: IPagedData<IWithdraw>;
  fetch: (page?: number) => void;
}

const BillingRequests: FC<Props> = ({pager, fetch}) => {
  const {t} = useTranslation();
  const [activeListCol, setActiveListCol] = useState(0);

  const onChangeActiveCol = (dir: 1|-1) => () => {
    setActiveListCol(prevState => {
      if ((prevState === 0 && dir === -1) || (prevState === 2 && dir === 1)) return prevState;
      return prevState + dir
    })
  }

  const handleRemove = (item: IWithdraw) => (e: any) => {
    e.stopPropagation();
    openBillingRemoveWithdrawModal(item).then(success => {
      if(success) {
        fetch()
      }
    })
  }

  if (!pager?.data?.length) return null;

  return (
    <div className='billing-card-wrap'>
      <div className='billing-card'>
        <div className='billing-card-head'>
          <ReactSVG src={card} className='react-icon me-2'/>
          <div>{t('BILLING_REQUESTS')}</div>
        </div>

        <div className="billing-card-table">
          <div className='table-list-head text-muted p-3'>
            <div className='billing__list__item-id'>#</div>
            {/*<div className="table-th billing__list__item-category">{t('CATEGORY')}</div>*/}
            <div className="billing__list__item-system">{t('WHERE_TO')}</div>
            <div className={`table-th billing__list__item-date${activeListCol === 0 ? ' active' : ''}`}>
              {t('OPERATION_DATE')}
            </div>
            <div className={`table-th billing__list__item-amount${activeListCol === 1 ? ' active' : ''}`}>
              {t('AMOUNT')}
            </div>
            <div className={`table-th billing__list__item-status${activeListCol === 2 ? ' active' : ''}`}>
              {t('STATUS')}
            </div>
            <div className='list-head-arrows'>
              <div className={`list-head-arrow${activeListCol === 0 ? ' disabled' : ''}`}
                   onClick={onChangeActiveCol(-1)}>
                <ReactSVG src={chevron_left} className='react-icon'/>
              </div>
              <div className={`list-head-arrow ms-2${activeListCol === 2 ? ' disabled' : ''}`}
                   onClick={onChangeActiveCol(1)}>
                <ReactSVG src={chevron_right} className='react-icon'/>
              </div>
            </div>
          </div>
          {pager?.data.map((item, i) => (
            <div key={i} className='billing__list__item' onClick={() => openBillingPaymentItemModal(item)}>
              <div className='billing__list__item-id'>{item.id}</div>
              {/*<div className="table-td billing__list__item-category">*/}
              {/*  <div className='billing-item-badge'>{t(item.status === 'outgo' ? 'OUTGO' : 'INCOMING')}</div>*/}
              {/*</div>*/}
              <div className="billing__list__item-system">
                <img src={icons[item.method]} alt='system'/>
              </div>
              <div className={`table-td billing__list__item-date text-muted${activeListCol === 0 ? ' active' : ''}`}>
                {moment(item.createdAt).format('YYYY.MM.DD, HH:mm')}
              </div>
              <div className={`table-td billing__list__item-amount${activeListCol === 1 ? ' active' : ''}`}>
                ${item.amount}
              </div>
              <div className={`table-td billing__list__item-status${activeListCol === 2 ? ' active' : ''}`}>
                <BillingWithdrawStatus item={item}/>
                {item.status === EWithdrawStatus.Pending &&
                  <div className="billing__list__item-remove" onClick={handleRemove(item)}/>
                }
              </div>
              <ReactSVG src={eye} className='react-icon list-item-eye'
              />
            </div>
          ))}
        </div>
        <Pagination
          className='m-2'
          onPageChange={fetch}
          pager={pager}
        />
      </div>
    </div>
  );
}

export default BillingRequests;