import React, {FC} from 'react';
import asyncModal from "react-async-modal";
import {ModalClose} from "../modals";
import {IDoc} from "../modules/rest";
import ModalHeader from "./ModalHeader";

interface Props {
  doc: IDoc;
  resolve: () => void;
}

const TermsModal: FC<Props> = ({doc, resolve}) => {

  return (
    <div>
      <ModalHeader onClick={resolve} title={doc?.title} />

      <div dangerouslySetInnerHTML={{__html: doc?.content || ''}} />
    </div>
  );
}

const openTermsModal = (doc: IDoc): Promise<void> => asyncModal(TermsModal, {doc}, {showCloseIcon: false, center: true,classNames: {modal: 'terms-modal'}});

export {openTermsModal};