import React, {FC} from 'react';
import {observer} from "mobx-react";
import ContentTypeTrends from "./ContentTypeTrends";
import ContentTypeNovelties from "./ContentTypeNovelties";
import ContentTypeComments from "./ContentTypeComments";
import {EContentPlanVideoOrigin} from "../../modules/rest";



interface Props {
  data: EContentPlanVideoOrigin[];
  onChangeActiveContentType?: (type: EContentPlanVideoOrigin) => void;
  className?: string;
  activeType?: EContentPlanVideoOrigin;
  updateContentPlan?: () => void;
  onHeaderClick?: (type: EContentPlanVideoOrigin) => void;
}


const ContentType: FC<Props> = observer(({
                                           data,
                                           onChangeActiveContentType,
                                           activeType,
                                           className,
                                           updateContentPlan,
                                           onHeaderClick,
                                         }) => {

  const handleChangeActiveType = (type: EContentPlanVideoOrigin) => () => {
    if (onChangeActiveContentType) onChangeActiveContentType(type);
  }

  return (
    <>
      {data.includes(EContentPlanVideoOrigin.Trending) && (
        <ContentTypeTrends
          onHeaderClick={onHeaderClick ? () => onHeaderClick(EContentPlanVideoOrigin.Trending) : undefined}
          updateContentPlan={updateContentPlan}
          className={className || ''}
          active={activeType === EContentPlanVideoOrigin.Trending}
          onChangeActiveContentType={handleChangeActiveType(EContentPlanVideoOrigin.Trending)}
        />
      )}
      {data.includes(EContentPlanVideoOrigin.NewVideos) && (
        <ContentTypeNovelties
          onHeaderClick={onHeaderClick ? () => onHeaderClick(EContentPlanVideoOrigin.NewVideos) : undefined}
          updateContentPlan={updateContentPlan}
          className={className || ''}
          active={activeType === EContentPlanVideoOrigin.NewVideos}
          onChangeActiveContentType={handleChangeActiveType(EContentPlanVideoOrigin.NewVideos)}
        />
      )}
      {data.includes(EContentPlanVideoOrigin.Comments) && (
        <ContentTypeComments
          onHeaderClick={onHeaderClick ? () => onHeaderClick(EContentPlanVideoOrigin.Comments) : undefined}
          updateContentPlan={updateContentPlan}
          className={className || ''}
          active={activeType === EContentPlanVideoOrigin.Comments}
          onChangeActiveContentType={handleChangeActiveType(EContentPlanVideoOrigin.Comments)}
        />
      )}
    </>
  );
})

export default ContentType;