import React, {FC, useLayoutEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import card_svg from "./assets/images/card.svg";
import camera_svg from "./assets/images/camera.svg";
import rocket_svg from "./assets/images/rocket.svg";
import chevron_svg from "../../assets/images/icons/chevron_sm_white.svg";
import {ReactSVG} from "react-svg";
import {observer} from "mobx-react";
import {EContentPlanVideoStatus, IContentPlanVideo} from "../../modules/rest";
import {openComposeChannelToContentPlanModal} from "../../modals";
import ContentPlanListItem from "./ContentPlanListItem";
import ContentPlanEmpty from "./ContentPlanEmpty";
import {formatDate} from "../../modules/utils";
import useScreenSize from "../../hooks/useScreenSize";
import {TContentPlanData, TContentPlanSmallData} from "./useFetchContentPlan";
import Loadable from "../../components/Loadable";
import ContentPlanItemSmall from "./ContentPlanItemSmall";
import useMethodsContentPlan from "./useMethodsContentPlan";


interface Props {
  className?: string;
  dashboard?: boolean;
  data?: TContentPlanData
  smallData?: TContentPlanSmallData
  fetch: () => void;
  ready: boolean;
  isArchive?: boolean;
}

export const dataFromStatus: any = {
  [EContentPlanVideoStatus.VideoPending]: {title: 'FILM_VIDEO', icon: card_svg},
  [EContentPlanVideoStatus.VideoReady]: {title: 'VIDEO_TAKEN', icon: camera_svg},
  [EContentPlanVideoStatus.VideoUploaded]: {title: 'UPLOADED', icon: rocket_svg},
}


const ContentPlan: FC<Props> = observer(({
                                           className,
                                           data,
                                           smallData,
                                           fetch,
                                           dashboard,
                                           ready,
                                           isArchive,
                                         }) => {
  const {t} = useTranslation();
  const {isSmallScreen} = useScreenSize();
  const [active, setActive] = useState<{ date?: string, status?: string }>({});
  const {loading, onEdit, onStatusChange, onAdd, onRemove} = useMethodsContentPlan(fetch);

  const empty = !data;

  if (isSmallScreen && empty) return (
    <ContentPlanEmpty
      small
      dashboard={dashboard}
      ready={ready}
      onClick={onAdd}
      isArchive={isArchive}
    />
  )

  if (isSmallScreen) return (
    <div className={`content-plan-small ${className || ''}`}>
      {Object.entries(smallData || {}).map(([date, statuses]) => (
        <div className="content-plan-section" key={date}>
          <h2 className='mb-3 mb-xl-1 px-2'>{formatDate(date)}</h2>
          {Object.entries(statuses).map(([status, items], i) => (
            <div key={i}
                 onClick={() => setActive({date, status})}
                 className={`content-plan-item-status${date === active.date && status === active.status && items?.length ? ' active' : ''}${!items?.length ? ' disabled' : ''}`}>
              <div className="content-plan-head-item">
                <div className='d-flex align-items-center flex-1'>
                  <ReactSVG src={dataFromStatus[status].icon} className='react-icon'/>
                  <div className='ms-1'>{t(dataFromStatus[status].title)}</div>
                  {items?.length ? <div className="content-plan-head-count">{items.length}</div> : null}
                </div>
                <ReactSVG src={chevron_svg} className='react-icon chevron'/>
              </div>
              <Loadable loading={loading} className="content-plan-item-status-list">
                {items.map(item => (
                  <ContentPlanItemSmall item={item} fetch={fetch}/>
                ))}
              </Loadable>
            </div>
          ))}
        </div>
      ))}
      {/*{items.map(item => (*/}
      {/*  <ContentPlanListItem*/}
      {/*    item={item}*/}
      {/*    key={item.id}*/}
      {/*    loading={loading}*/}
      {/*    onRemove={handleRemove(item)}*/}
      {/*    onEdit={handleEdit(item)}*/}
      {/*    onStatusChange={handleStatusChange(item)}*/}
      {/*  />*/}
      {/*))}*/}
    </div>
  )

  return (
    <div className={className || ''}>
      <div className={`content-plan tabs-list ${empty ? ' empty' : ''}`}>
        <div className="content-plan-section d-flex">
          <div className="content-plan-head-item">
            <ReactSVG src={card_svg} className='react-icon'/>
            <span className='ms-1'>{t('FILM_VIDEO')}</span>
          </div>
          <div className="content-plan-head-item">
            <ReactSVG src={camera_svg} className='react-icon'/>
            <span className='ms-1'>{t('VIDEO_TAKEN')}</span>
          </div>
          <div className="content-plan-head-item">
            <ReactSVG src={rocket_svg} className='react-icon'/>
            <span className='ms-1'>{t('UPLOADED')}</span>
          </div>
        </div>
        {!empty
          ? Object.entries(data).map(([date, items]) => (
            <div className="content-plan-section" key={date}>
              <h2 className='pb-1 ps-4 ms-1'>{formatDate(date)}</h2>
              {items.map(item => (
                <ContentPlanListItem
                  item={item}
                  key={item.id}
                  loading={loading}
                  onRemove={() => onRemove(item)}
                  onEdit={() => onEdit(item)}
                  onStatusChange={(status) => onStatusChange(item, status)}
                />
              ))}
            </div>
          ))
          :
          <ContentPlanEmpty
            dashboard={dashboard}
            ready={ready}
            onClick={onAdd}
            isArchive={isArchive}
          />
        }
      </div>

    </div>
  );
})

export default ContentPlan;