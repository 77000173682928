import React, {FC} from 'react';
import {Link, NavLink} from "react-router-dom";
import {IReactorChannel, IReferralRecord} from "../../modules/rest";
import {useTranslation} from "react-i18next";
import logo from "../../assets/images/logo.png";
import goTo from "../../assets/images/icons/go_to.svg";
import {ExtensionPanel} from "../Extension";
import AsideMonetization from "./AsideMonetization";
import {SingleValue} from "react-select";
import session from "../../modules/session";
import {observer} from "mobx-react";
import {runInAction} from "mobx";
import ChannelSelect from "./ChannelSelect/ChannelSelect";
import {ReactSVG} from "react-svg";
import CloseBtn from "../CloseBtn";
import {mainMenu, monetizationMenu, monetizationSectionPath, TSection} from "../../routes/AppRouter";

interface Props {
  visible: boolean;
  sectionName?: TSection
  referrals?: IReferralRecord[];
  closeMenu: () => void;
}

const Aside: FC<Props> = ({referrals, visible, closeMenu, sectionName}) => {
  const {t} = useTranslation();


  return (
    <aside className={visible ? 'visible' : ''} onClick={closeMenu}>
      <div className="aside-content-wrap" onClick={(e) => e.stopPropagation()}>
        <div className='aside-header'>
          <Link to={'/'} className='logo' onClick={closeMenu}>
            <img src={logo} alt="logo"/>
          </Link>
          <CloseBtn onClick={closeMenu}/>
        </div>
        <div className='aside-content'>
          <div className='aside-submenu'>
            <div className="aside-submenu-bg"/>
            <Link to='/' className={`aside-submenu-item${sectionName === 'scanner' ? ' active' : ''}`}
                  onClick={closeMenu}>{t('SCANNER')}</Link>
            <Link to={monetizationSectionPath}
                  className={`aside-submenu-item${sectionName === 'monetization' ? ' active' : ''}`}
                  onClick={closeMenu}>{t('MONETIZE')}</Link>
          </div>
          <div className='aside-content-wrapper'>
            {sectionName === 'scanner' &&
              <div className='aside-channel-select-wrap'>
                <div className="aside-goTo-wrap">
                  <a href={session.activeChannel?.channel?.url} target='_blank' className='aside-goTo'>
                    <ReactSVG src={goTo} className='react-icon'/>
                    <div className='aside-goTo-text'>{t('GO_TO')}</div>
                  </a>
                </div>
                <ChannelSelect
                  className='flex-1'
                  deletable
                  canAdd
                  value={session.activeChannel}
                  onChange={(ch: SingleValue<IReactorChannel>) => runInAction(() => {
                    localStorage.setItem('activeChannel', String(ch?.id || ''))
                    session.activeChannel = ch as IReactorChannel;
                    closeMenu()
                  })}
                />
              </div>
            }
            <nav className='nav'>
              {sectionName === 'scanner'
                ?
                mainMenu.map(item => (
                  <NavLink key={item.path} to={item.path} onClick={closeMenu}>
                    <ReactSVG src={item.icon} className='react-icon me-2'/>
                    <span>{t(item.title)}</span>
                  </NavLink>
                ))
                :
                monetizationMenu.map(item => {
                  if (item.path === '/monetization/referrals' && !referrals?.length) return null;
                  return <NavLink key={item.path} to={item.path} onClick={closeMenu}>
                    <ReactSVG src={item.icon} className='react-icon me-2'/>
                    <span>{t(item.title)}</span>
                  </NavLink>
                })
              }
            </nav>
            <div className='aside-footer '>
              <ExtensionPanel/>
              <AsideMonetization closeMenu={closeMenu}/>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default observer(Aside);
