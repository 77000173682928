import React, {FC, useState} from 'react';
import {ReactSVG} from "react-svg";
import arrow from "./images/arrow.svg";
import BestReactionsTags from "./BestReactionsTags";
import {IResearchCountry} from "../../modules/rest";
import {useTranslation} from "react-i18next";
import CloseBtn from "../../components/CloseBtn";

interface Props {
  tags?: string[] | null;
  loading?: boolean;
  error?: boolean;
  className?: string;
}

const BestReactionsRight: FC<Props> = ({tags, loading, error, className}) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(true)

  return (
    <div className={`best-reactions-modal-right ${className || ''}`}>
      {visible
        ?
        <div className='position-relative mb-3'>
          {!error && <ReactSVG src={arrow} className='react-icon best-reactions-modal-right-empty-arrow'/>}
          <div className='best-reactions-modal-right-empty'>
            <div className='d-flex'>
              <div className='text-medium text-12 text-pre-wrap pe-2 flex-1'>{t('BEST_REACTIONS_RIGHT_EMPTY_TITLE')}</div>
              <CloseBtn onClick={() => setVisible(false)}/>
            </div>
            <div
              className='text-medium text-12 text-tint mt-2 text-pre-wrap'>{t('BEST_REACTIONS_RIGHT_EMPTY_TEXT')}</div>
          </div>
        </div>
        :
        null}
      {error ? null : loading
        ?
        <div className='best-reactions-modal-skeleton-item p-3'>
          <div className="best-reactions-modal-skeleton-item-bg m-1 mb-4" style={{width: 100, height: 20}}/>
          <div className='d-flex'>
            <div className="best-reactions-modal-skeleton-item-bg" style={{width: 69, height: 12}}/>
            <div className="best-reactions-modal-skeleton-item-bg ms-2" style={{width: 69, height: 12}}/>
          </div>
          <div className='d-flex mt-2'>
            <div className="best-reactions-modal-skeleton-item-bg" style={{width: 59, height: 12}}/>
            <div className="best-reactions-modal-skeleton-item-bg ms-2" style={{width: 69, height: 12}}/>
          </div>
        </div>
        :
        <BestReactionsTags tags={tags}/>
      }
    </div>
  );
}

export default BestReactionsRight;