import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {ReactSVG} from "react-svg";
import history from './assets/icons/history.svg'
import eye from "../../assets/images/icons/eye.svg";
import {openBillingHistoryItemModal } from "../../modals";
import chevron_left from "../../assets/images/icons/chevron_left.svg";
import chevron_right from "../../assets/images/icons/chevron_right.svg";
import {IPagedData, ITransaction} from "../../modules/rest";
import Pagination from "../../components/Pagination";


interface Props {
  pager?: IPagedData<ITransaction>
  onPageChange: (page: number) => void;
}

const BillingHistory: FC<Props> = ({pager, onPageChange}) => {
  const {t} = useTranslation();
  const [activeListCol, setActiveListCol] = useState(0);

  const onChangeActiveCol = (dir: 1|-1) => () => {
    setActiveListCol(prevState => {
      if ((prevState === 0 && dir === -1) || (prevState === 1 && dir === 1)) return prevState;
      return prevState + dir
    })
  }

  if (!pager?.data?.length) return null;

  return (
    <div className='billing-card-wrap'>
      <div className='billing-card'>
        <div className='billing-card-head'>
          <ReactSVG src={history} className='react-icon me-2'/>
          <div>{t('BILLING_HISTORY')}</div>
        </div>
        <div className="billing-card-table">
          <div className='table-list-head text-muted p-3'>
            <div className='billing__list__item-id'>#</div>
            {/*<div className="table-th billing__list__item-category">{t('CATEGORY')}</div>*/}
            <div className="billing__list__item-date">{t('OPERATION_DATE')}</div>
            <div
              className={`table-th billing__list__item-amount${activeListCol === 0 ? ' active' : ''}`}>{t('AMOUNT')}</div>
            <div
              className={`table-th billing__list__item-note${activeListCol === 1 ? ' active' : ''}`}>{t('NOTE')}</div>
            <div className='list-head-arrows'>
              <div className={`list-head-arrow${activeListCol === 0 ? ' disabled' : ''}`}
                   onClick={onChangeActiveCol(-1)}>
                <ReactSVG src={chevron_left} className='react-icon'/>
              </div>
              <div className={`list-head-arrow ms-2${activeListCol === 1 ? ' disabled' : ''}`}
                   onClick={onChangeActiveCol(1)}>
                <ReactSVG src={chevron_right} className='react-icon'/>
              </div>
            </div>
          </div>
          {pager?.data.map((item, i) => (
            <div key={i} className='billing__list__item' onClick={() => openBillingHistoryItemModal(item)}>
              <div className='billing__list__item-id'>{item.id}</div>
              <div className="billing__list__item-date text-muted">
                {moment(item.createdAt).format('YYYY.MM.DD, HH:mm')}
              </div>
              <div className={`table-td billing__list__item-amount${activeListCol === 0 ? ' active' : ''}`}>
                <span
                  className={item.amount < 0 ? ' text-danger' : ''}>{item.amount < 0 ? '- ' : ''}${item.amount}</span>
              </div>
              <div className={`table-td billing__list__item-note${activeListCol === 1 ? ' active' : ''}`}>
                <div className='text-truncate' title={item.comment || ''}>
                  {item.comment}
                </div>
              </div>
              <ReactSVG src={eye} className='react-icon list-item-eye'/>
            </div>
          ))}
        </div>
        <Pagination
          className='m-2'
          onPageChange={onPageChange}
          pager={pager}
        />
      </div>
    </div>
  );
}

export default BillingHistory;