import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {IGetYoutuberStatsRequest} from "../../modules/rest";
import chevron_left from '../../assets/images/icons/chevron_left.svg'
import chevron_right from '../../assets/images/icons/chevron_right.svg'
import {ReactSVG} from "react-svg";

interface Props {
  group: IGetYoutuberStatsRequest['group']
  activeCol: string;
  onChangeActiveCol: (dir: 1 | -1) => void;
}


const ReportsListHead: FC<Props> = ({group, activeCol, onChangeActiveCol}) => {
  const {t} = useTranslation();

  return (
    <div className='reports-list-head table-list-head'>
      <div className='report__list__item-id'>#</div>
      <div className='flex-1'>{t(group.toUpperCase())}</div>
      {group !== 'country' &&
        <div className={`reports-list-number-item table-th${activeCol === 'views' ? ' active' : ''}`}>
          {t('VIEWS')}
        </div>
      }
      <div className={`reports-list-number-item table-th${activeCol === 'paidViews' ? ' active' : ''}`}>
        {t('COM_views')}
      </div>
      <div className={`reports-list-number-item table-th${activeCol === 'cpm' ? ' active' : ''} `}>
        {t('CPM')}
      </div>
      <div className={`reports-list-number-item table-th text-white text-bold${activeCol === 'profit' ? ' active' : ''}`}>
        {t('INCOME')}
      </div>
      <div className='list-head-arrows'>
        <div className={`list-head-arrow${activeCol === 'views' ? ' disabled' : ''}`} onClick={() => onChangeActiveCol(-1)}>
          <ReactSVG src={chevron_left} className='react-icon' />
        </div>
        <div className={`list-head-arrow ms-2${activeCol === 'profit' ? ' disabled' : ''}`} onClick={() => onChangeActiveCol(1)}>
          <ReactSVG src={chevron_right} className='react-icon' />
        </div>
      </div>
    </div>
  );
}

export default ReportsListHead;