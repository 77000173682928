import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import play_svg from "../../assets/images/icons/video_play.svg";
import {IAlsoTrendingItem, IMonitoringVideo, ITrendingItem, IYouTubeVideo} from "../../modules/rest";
import {thumb} from "../../modules/utils";

interface Props {
  data?: (IAlsoTrendingItem| IYouTubeVideo)[]
}

const TrendsAsideList: FC<Props> = ({data}) => {
  return (
    <div className='trends-aside-list-wrap'>
      <div className='trends-aside-list'>
        {data?.map((item) => (
          <a href={item.url} target='_blank' className='video-trends-item' key={item.id}>
            <div className='video-trends-item-img'>
              <img src={(item as IAlsoTrendingItem).image || thumb((item as IYouTubeVideo).preview?.id!, 180)} alt="img"/>
              <div className='video-trends-item-play'>
                <ReactSVG className='react-icon' src={play_svg}/>
              </div>
            </div>
            <div className='text-medium-14 mt-1'>{item.title}</div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default TrendsAsideList;