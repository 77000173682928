import React, {FC, FormEvent, useCallback, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {observer, useLocalObservable} from "mobx-react";
import {runInAction} from "mobx";
import Loader from "../../components/Loader";
import SystemChannels from "./SystemChannels";
import MyChannels from "./MyChannels";
import {toast} from "react-toastify";

import {API} from "../../modules/api";
import {
  EFieldGroup,
  IGetMonitoringChannelsRequest,
  IMonitoringCategory,
  IMonitoringChannel,
  IPagedData
} from "../../modules/rest";
import session from "../../modules/session";
import Button from "../../components/Button";

interface Props {
  handleClose: (status: boolean) => void;
  defaultTab?: number
}
interface State {
  // pager?: IGetStatsResponse<unknown>;
  // request: IGetStatsRequest;
  request: IGetMonitoringChannelsRequest;
  pager?: IPagedData<IMonitoringChannel>;
  loading: boolean;
  ready: boolean;
  panelVisible: boolean;
  activeTabIdx: number;
  categories: IMonitoringCategory[];
}


const ChannelMonitoringPage: FC<Props> = observer(({defaultTab, handleClose}) => {
  const {t} = useTranslation();

  const channel = session.activeChannel! || {};

  const st = useLocalObservable<State>(() => ({
    panelVisible: true,
    ready: false,
    loading: false,
    activeTabIdx: defaultTab || 0,
    categories: channel.categories!,
    request: {
      search: '',
      page: 1,
      limit: 10,
    },
  }));

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      await getMyChannels();
    } catch (e: any) {
      toast.error(e)
    } finally {
      runInAction(() => {
        st.loading = false;
        st.ready = true;
      })
    }
  }

  const getMyChannels = async () => {
    try {
      const pager = await API.Monitoring.getChannels({
        ...st.request,
        scope: 'private'
      }, [EFieldGroup.MonitoringCategories]);
      runInAction(() => {
        st.pager = pager;
      });
    } catch (e: any) {
      toast.error(e)
    }
  }


  const deleteChannel =  async (id: number) => {
    try {
      runInAction(() => {
        st.loading = true
      })
      await API.Monitoring.deleteChannel(id);
      await getMyChannels();
    } catch (e: any) {
      toast.error(e)
    } finally {
      runInAction(() => {
        st.loading = false
      })
    }

  }

  const handleSubmit = async () => {
    runInAction(() => {
      st.loading = true
    })
    try {
      await session.updateChannel(channel.id, {categories: st.categories?.map(c => c.id)});
      toast(t('UPDATE_CHANNEL_SUCCESS'));
      handleClose(true);
    } catch (e: any) {
      toast.error(e || {message: 'Error'})
    } finally {
      runInAction(() => {
        st.loading = false
      })
    }
  }

  return (
    <div className='channel-monitoring'>
      <div className='aside-submenu m-0 mt-4'>
        <div className="aside-submenu-bg"/>
        <div className={`aside-submenu-item${st.activeTabIdx === 0 ? ' active' : ''}`}
             onClick={() => runInAction(() => {
               st.activeTabIdx = 0;
             })}>{t('SYSTEM_CHANNELS')}</div>
        <div className={`aside-submenu-item${st.activeTabIdx === 1 ? ' active' : ''}`}
             onClick={() => runInAction(() => {
               st.activeTabIdx = 1;
             })}>{t('PERSONAL_LIST')}</div>
      </div>

      {st.activeTabIdx === 0 &&
        <SystemChannels
          onUpdate={(category) => {
            const idx = st.categories.findIndex(c => c.id === category.id);
            if(idx === -1) {
              runInAction(() => {
                st.categories = [...st.categories, category]
              })
            } else {
              runInAction(() => {
                st.categories = st.categories.filter(c => c.id !== category.id)
              })

            }
          }}
          subscriptions={st.categories}
        />
      }
      {st.activeTabIdx === 1 &&
        <MyChannels
          onRemove={deleteChannel}
          pager={st.pager}
          request={st.request}
          fetch={getMyChannels}
          onChangeFilters={(request) => runInAction(async () => {
            st.request = request;
            await getMyChannels();
          })}
        />
      }
      {!st.ready && <Loader/>}
      <div className="modal-footer-btns">
        <Button
          text='CANCEL'
          onClick={() => handleClose(false)}
          color='outline-primary'
        />
        <Button
          loading={st.loading}
          onClick={handleSubmit}
          text='SAVE'
          type='button'
        />
      </div>
    </div>
  )
})

export default ChannelMonitoringPage;