import { IVideo} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC, useCallback, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ModalClose} from "../../modals";
import slide1 from './images/slides/slide1.png';
import slide2 from './images/slides/slide2.png';
import slide3 from './images/slides/slide3.png';
import slide4 from './images/slides/slide4.png';
import {ReactSVG} from "react-svg";
import arrow_left from './images/arrow_left.svg';
import arrow_right from './images/arrow_right.svg';
import logo from './images/logo.svg';
import Slider from "react-slick";
import {extensionLink} from "./index";
import SliderPagination from "../SliderPagination";
import ModalHeader from "../../modals/ModalHeader";

const slides = [slide1, slide2, slide3, slide4]

interface Props {

  resolve(): void;
}

const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '92px',
  // autoplay: true,
  // autoplaySpeed: 6000
};

const ExtensionModal: FC<Props> = ({resolve}) => {
  const {t} = useTranslation();
  const _slider: any = useRef(null)
  const [active, setActive] = useState(0);

  const handleClose = useCallback(() => {
    resolve()
  }, [resolve]);

  const onNavClick = useCallback((dir: number|'-1'|"1") => {
    if (dir === '-1') {
      _slider.current?.slickPrev();
    } else if (dir === '1') {
      _slider.current?.slickNext();
    } else {
      _slider.current?.slickGoTo(dir);
    }
  }, []);

  return (
    <>
      <ModalHeader onClick={handleClose} title={'HOW_IT_WORK'} />
      <div className="extension-modal">
        <Slider {...settings} className='extension-modal-slick' ref={_slider}
                beforeChange={(currentSlide, nextSlide) => setActive(nextSlide)}
        >
          {slides.map((slide, i) => (
            <div className="extension-modal-item" key={i}>
              <img src={slide} alt="img"/>
              <h4 className='mb-2'>{t(`EXTENSION_ITEM_${i + 1}_TITLE`)}</h4>
              <p>{t(`EXTENSION_ITEM_${i + 1}_TEXT`)}</p>
            </div>
          ))}
        </Slider>
      </div>
      <SliderPagination active={active} len={slides.length} onClick={onNavClick} />
      <div className='extension-modal-footer'>
        <button type='button' className='btn btn-outline-primary flex-grow-1 me-2' onClick={handleClose}>
          {t('CLOSE')}
        </button>
        <a href={extensionLink} target='_blank' type='button' className='btn btn-primary flex-grow-1 ms-2'>
          <ReactSVG src={logo} className='react-icon'/>
          {t('INSTALL')}
        </a>
      </div>
    </>
  );
};

const openExtensionModal = (): Promise<IVideo|null> => asyncModal(ExtensionModal, {}, {
  showCloseIcon: false,
  center: true
});

export {openExtensionModal};
