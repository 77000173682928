import React, {FormEvent, useCallback, useState} from 'react';
import asyncModal from 'react-async-modal';
import Button from '../components/Button';
import {useTranslation} from "react-i18next";
import CloseBtn from "../components/CloseBtn";
import ModalClose from "../modals/ModalClose";
import ModalHeader from "../modals/ModalHeader";

interface InputOptions {
  label?: string;
  placeholder?: string;
  required?: boolean;
}

interface ConfirmDialogOptions {
  danger?: boolean;
  confirmText?: string;
  cancelText?: string;
  title?: string;
  input?: InputOptions;
}

interface ConfirmModalProps {
  message: string;
  options?: ConfirmDialogOptions;

  resolve(result: boolean|string): void;
}

const ConfirmModal = ({message, resolve, options}: ConfirmModalProps) => {
  const {t} = useTranslation();
  const input = options?.input;
  const [value, setValue] = useState('');

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      resolve(input ? value : true);
    },
    [value, input, resolve]
  );

  const handleClose = useCallback(() => {
    resolve(false)
  }, [resolve]);

  return (
    <>
      <div>
        <ModalHeader onClick={handleClose} title={options?.title ?? 'CONFIRM_ACTION'} />
        <form onSubmit={submit}>
          <div className='mb-4 text-muted text-medium'>{message}</div>
          {input && (
            <div className="mb-4">
              {input.label && <label className={input.required ? 'req' : ''}>{input.label}</label>}
              <input
                required={input.required}
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="form-control"
                placeholder={input.placeholder}
                autoFocus
              />
            </div>
          )}
          <div className="d-flex justify-content-end">
            <Button
              text={options?.cancelText || 'CANCEL'}
              onClick={() => resolve(false)}
              color="outline-primary"
              className="me-3"
              size={'sm'}
            />
            <Button
              size={'sm'}
              type="submit"
              text={options?.confirmText || 'CONFIRM'}
              color={options?.danger ? 'danger' : 'primary'}
            />
          </div>
        </form>
      </div>
    </>
  );
};

const confirmDialog = (message: string, options?: ConfirmDialogOptions): Promise<boolean|string> => {
  return asyncModal(ConfirmModal, {message, options}, {showCloseIcon: false});
};

export {confirmDialog};
