import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {openActiveMonetizationModal} from "../../components/Aside/AsideMonetization/AsideMonetizationModal";
import {observer} from "mobx-react";
import session from "../../modules/session";
import {EReviewStatus} from "../../modules/rest";

interface Props {
}

const NoMonetizationPage: FC<Props> = observer(() => {
  const {t} = useTranslation();

  const handleClick = () => {
    openActiveMonetizationModal().then();
  }

  const status = session.monetization?.status || '';

  return (
    <div className='no-monetization-page'>
      <div className="no-monetization-page-bg">
        {Array(Math.round(window.innerHeight / 128)).fill({}).map((_, i) => (
          <div key={i} className='no-monetization-page-bg-item'/>
        ))}
      </div>
      <div className={`no-monetization-page-stub ${status}`}/>
      {(status === EReviewStatus.Draft || !status) && (
        <>
          <h2>{t('MONETIZE')}</h2>
          <p className='mt-1 text-muted'>{t('MONETIZATION_PAGE_TEXT')}</p>

          <button type='button' className='btn btn-primary mt-4' onClick={handleClick}>
            {t('ACTIVATE_MONETIZATION')}
          </button>
        </>
      )}
      {(status === EReviewStatus.Review) && (
        <>
          <h2>{t('REQUEST_PENDING')}</h2>
          <p className='mt-1 text-muted'>{t('REQUEST_PENDING_TEXT')}</p>
        </>
      )}
      {status === EReviewStatus.Reject && (
        <>
          <h2 className='text-danger'>{t('REQUEST_REJECTED')}</h2>
          <p className='mt-1 text-danger mb-2'><span
            className='text-medium'>{t('REASON')}:</span> {session.monetization?.rejectReason}</p>

          <button type='button' className='btn btn-primary mt-4' onClick={handleClick}>
            {t('ACTIVATE_MONETIZATION')}
          </button>
          <p className='mt-3 text-muted-14'>{t('REQUEST_REJECTED_TEXT')}</p>
        </>
      )}

    </div>
  );
})

export default NoMonetizationPage;