import React, {FC} from 'react';
import './assets/styles.scss';
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
}

const HowInsertVideoAnimated: FC<Props> = ({className}) => {
  const {t} = useTranslation();

  return (
    <div className={className || ''}>
      <div className="how-insert-video-wrap animated">
        <div className="how-insert-video">
          <div className='how-insert-video-title'>{t('YOUR_INTRO')}</div>
          <div className='how-insert-video-content'>
            <div className='d-flex'>
              {Array(2).fill({}).map((item, i) => (
                <div className={'how-insert-video-item'} key={i}/>
              ))}
            </div>
            <div className={'how-insert-video-footer'}>
              {Array(2).fill({}).map((item, i) => (
                <div className={'how-insert-video-footer-item'} key={i}/>
              ))}
            </div>
          </div>
        </div>
        <div className="how-insert-video active">
          <div className='how-insert-video-title'>{t('PROMO')}</div>
          <div className='how-insert-video-content'/>
        </div>
        <div className="how-insert-video">
          <div className='how-insert-video-title'>{t('YOUR_VIDEO')}</div>
          <div className='how-insert-video-content'>
            <div className='d-flex'>
              {Array(6).fill({}).map((item, i) => (
                <div className={'how-insert-video-item'} key={i}/>
              ))}
            </div>
            <div className={'how-insert-video-footer'}>
              {Array(6).fill({}).map((item, i) => (
                <div className={'how-insert-video-footer-item'} key={i}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowInsertVideoAnimated;