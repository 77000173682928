import asyncModal from 'react-async-modal';
import React, {FC, FormEvent, useCallback, useEffect, useState} from 'react';
import Loadable from '../../components/Loadable';
import {useTranslation} from "react-i18next";
import Button from "../../components/Button";
import {
  EContentPlanVideoOrigin,
  EContentPlanVideoStatus, EFieldGroup,
  EReviewStatus,
  IContentPlanVideo,
  IVideoEmbed
} from "../../modules/rest";
import {toast} from "react-toastify";
import {API} from "../../modules/api";
import {Checkbox, Input, Radio} from "../../components/FormControls";
import ContentPlanItemBody from "./ContentPlanItemBody";
import rocket from '../../assets/images/icons/rocket_black.svg';
import {ReactSVG} from "react-svg";
import VideoEmbedSelect from "../../components/VideoEmbedSelect";
import {smallestScreen} from "../../modules/utils";
import ModalHeader from "../../modals/ModalHeader";

interface Props {
  item: IContentPlanVideo;

  resolve(updated: boolean): void;
}


const ContentPlanUploadedModal: FC<Props> = ({
                                               resolve,
                                               item,
                                             }) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isPromo, setIsPromo] = useState('yes');
  const [url, setUrl] = useState('');
  const [ve, setVe] = useState<IVideoEmbed>();
  const [veData, setVeData] = useState<IVideoEmbed[]>();
  const [errorVe, setErrorVe] = useState(false);
  const [showForce, setShowForce] = useState(false);
  const [force, setForce] = useState(false);

  useEffect(() => {
    getVideoEmbed()
  }, []);

  const getVideoEmbed = async () => {
    try {
      const res = await API.VideoEmbeds.getList({
        limit: 100,
        status: EReviewStatus.Approve
      }, [EFieldGroup.VideoEmbedVideo]);
      setVeData(res.data);
    } catch (e) {

    } finally {
      setLoading(false)
    }
  }

  const handleClose = useCallback(() => {
    resolve(false);
  }, [resolve]);


  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (loading || errorVe) return;
    if (isPromo === 'yes' && !ve) {
      return setErrorVe(true);
    }
    setLoading(true);
    try {
      if (isPromo === 'yes' && ve) {
        await API.VideoEmbeds.addYoutubeVideo(ve.id, {url, force});
      }
      await API.ContentPlan.updateVideo(item.id, {status: EContentPlanVideoStatus.VideoUploaded, videoUrl: url});

      resolve(true);
    } catch (e: any) {
      toast.error(e);
      setShowForce(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>

      <Loadable loading={loading} className='content-plan-modal'>
        <form onSubmit={submit}>
          <ModalHeader onClick={handleClose} title={'REACTION_LOADED'}/>
          <div className='text-muted mb-2 pb-1'>
            {t(item.origin === EContentPlanVideoOrigin.Comments ? 'REACTION_COMMENT' : 'REACTION_VIDEO')}
          </div>
          <div className='content-uploaded-modal-item'>
            <ContentPlanItemBody item={item}/>
          </div>
          <Input
            required
            label='YOUTUBE_LINK'
            className='sm mt-4'
            type='url'
            placeholder={t('LINK') || ''}
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              setShowForce(false);
              setForce(false);
            }}
          />
          {(showForce && isPromo === 'yes') &&
            <div className={`video-exist${force ? ' active' : ''}`}>
              <Checkbox checked={force} onChange={e => setForce(e.target.checked)} label='VIDEO_EXIST'/>
              <div className='text-muted-14 mt-2 ps-4 ms-3'>{t('VIDEO_EXIST_TEXT')}</div>
            </div>
          }
          {veData?.length
            ?
            <>
              <h4 className='mt-4'>{t('PROMO_IN_VIDEO')}</h4>
              <div className='d-flex mt-3'>
                <Radio
                  checked={isPromo === 'yes'}
                  onChange={(e) => setIsPromo(e.target.value)}
                  value={'yes'}
                  name='promo'
                  label={'YES'}
                  className={'me-4 pe-2'}
                />
                <Radio
                  checked={isPromo === 'no'}
                  onChange={(e) => setIsPromo(e.target.value)}
                  value={'no'}
                  name='promo'
                  label={'NO'}
                />
              </div>
              {isPromo === 'yes' &&
                <div className="content-plan-modal-video-embed">
                  <div className='text-muted mb-2 pb-1'>{t('PROMO_TO_REACTION')}</div>
                  <VideoEmbedSelect
                    error={errorVe}
                    onChange={(ve) => {
                      setErrorVe(false);
                      setVe(ve);
                    }}
                    value={ve}
                    options={veData}
                  />
                </div>
              }
            </>
            :
            null
          }

          <div className="modal-footer-btns">
            <Button
              text='CANCEL'
              onClick={handleClose}
              color='outline-primary'
            />
            <button type='submit' className='btn btn-primary btn-icon' style={{padding: '0 32px'}}>
              <ReactSVG src={rocket} className='react-icon'/>
              {t('NEXT')}
            </button>
          </div>
        </form>
      </Loadable>
    </>
  );
};

const openContentPlanUploadedModal = (item: IContentPlanVideo): Promise<boolean> => asyncModal(ContentPlanUploadedModal, {item}, {
  showCloseIcon: false,
  center: true,
  blockScroll: false,
  classNames: {modal: 'add-channel-modal', modalContainer: smallestScreen() ? 'bottom-modal' : ''},
});

export {openContentPlanUploadedModal};
