import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import Tag from "../../Tag";
import {useTranslation} from "react-i18next";
import {EContentPlanVideoOrigin, IMonitoringVideo} from "../../../modules/rest";
import plan_svg from "../assets/images/plan.svg";
import ContentTypeItemImg from "../ContentTypeItemImg";
import {  smallestScreen, thumb} from "../../../modules/utils";
import {openBestReactionsModal} from "../../../modals";
import ContentTypeTags from "../ContentTypeTags";
import ContentTypeListItemViews from "../ContentTypeListItemViews";

interface Props {
  idx: number;
  item: IMonitoringVideo;
  onAddToContentPlan: (item: IMonitoringVideo) => () => void;
}

const ListItemNovelties: FC<Props> = ({item, onAddToContentPlan, idx}) => {
  const {t} = useTranslation();


  return (
    <div className={`content-type-item${item.isPlanned ? ' planned' : ''}`}
         onClick={smallestScreen() ? onAddToContentPlan(item) : undefined}
    >
      <div className='content-type-item-id'>{idx}</div>
      <ContentTypeItemImg image={thumb(item.video?.preview?.id!, 180)} url={item.video?.url}/>
        <div className='content-type-item-info'>
          <div className='d-grid overflow-hidden'>
            <div className='text-medium-14 text-truncate text-muted'>{item.video?.title}</div>
          </div>
          <ContentTypeListItemViews item={item} />
      </div>
      <div className='content-type-item-right'>
        <div className="content-type-item-best-reaction text-tint-active"
             onClick={() => openBestReactionsModal(item, EContentPlanVideoOrigin.NewVideos)}>{t('BEST_REACTION')}</div>
        <div className="content-type-item-hidden">
          <button type='button' className='btn btn-outline-primary btn-xs' onClick={onAddToContentPlan(item)}>
            {t('ADD_TO_CONTENT_PLAN')}
          </button>
        </div>
        {item.isPlanned &&
        <div className="content-type-item-planned">
          <ReactSVG src={plan_svg} className='react-icon content-type-item-planned-icon me-2'/>
          <div className='text-muted-14 content-type-item-planned-text'>{t('IN_CONTENT_PLAN')}</div>
        </div>
        }
        <div className="content-type-tags-small">
          {item?.channel?.categories?.length! > 1 &&
            <div className={`custom-tag small px-2 me-1`}>
              +{item?.channel?.categories?.length! - 1}
            </div>
          }
          <Tag
            small
            className='pe-0'
            text={item.channel?.categories?.[0]?.name || ''}   />
        </div>
        <ContentTypeTags
          small
          type={EContentPlanVideoOrigin.NewVideos}
          data={item.channel?.categories }
        />
        {/*<div className='content-type-tags'>*/}
        {/*  {item.channel?.categories?.slice(0, 3).map(item => (*/}
        {/*    <Tag text={item.name} key={item.id} small/>*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default ListItemNovelties;