import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {API} from '../../modules/api';
import store from '../../modules/session';
import {runInAction} from 'mobx';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useTranslation} from "react-i18next";
import {Checkbox, Input, InputPassword} from "../../components/FormControls";
import AuthWrap from "./AuthWrap";
import {EFieldGroup, EService, EUserRole, IDoc} from '../../modules/rest';
import Button from "../../components/Button";
import {openTermsModal} from "../../modals/TermsModal";

export interface ITerms {
  terms: IDoc,
  privacy: IDoc
}

const LoginRoute = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(localStorage.getItem('email') ?? '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState<boolean>(false);
  const [terms, setTerms] = useState<ITerms>();

  useEffect(() => {
    fetchTerms()
  }, [])

  const fetchTerms = useCallback(async () => {
    try {
      const [privacy, terms] = await Promise.all([
        API.Docs.getByTag('reactrino.privacy', [EFieldGroup.DocContent]),
        API.Docs.getByTag('reactrino.terms', [EFieldGroup.DocContent]),
      ]);
      setTerms({privacy, terms})
    } catch (e) {

    }
  }, []);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Users.login({
        email,
        password,
        role: EUserRole.Youtuber,
        service: EService.Reactrino
      }, [EFieldGroup.UserBalance, EFieldGroup.UserRef, EFieldGroup.UserTelegram, EFieldGroup.UserYoutuberFlags])
        .then((res) => {
          window.localStorage.setItem('token', res.token);
          window.localStorage.setItem('email', email);
          API.setToken(res.token);
          store.login(res.user);
          const returnPath = window.localStorage.getItem('return') ?? '/';
          window.localStorage.removeItem('return');
          navigate(returnPath, {replace: true});
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [email, password, setLoading, navigate]
  );

  return (
    <AuthWrap loading={loading} onSubmit={submit}>
      <Input
        className="mb-4"
        label={'auth:EMAIL'}
        placeholder={t('auth:EMAIL_PLACEHOLDER') || ''}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoFocus
        type="email"
        required
      />
      <InputPassword
        className="mb-4"
        onForgotPassword={() => navigate('/password')}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      {/*<Checkbox*/}
      {/*  className='mb-4'*/}
      {/*  checked={remember}*/}
      {/*  onChange={(e) => setRemember(e.target.checked)}*/}
      {/*  label={'REMEMBER_ME'}*/}
      {/*/>*/}
      <div className="text-muted mb-4 pb-3 d-flex align-items-start">
        <Checkbox
          // className={'ps-4'}
          checked={agree}
          onChange={(e) => setAgree(e.target.checked)}
          id='agree'
        />
        {/*<label className="form-check-label cursor-pointer" htmlFor="agree">*/}
        {/*  {t('auth:TERMS_TEXT_1')}{' '}*/}
        {/*  <span*/}
        {/*    className="text-muted"*/}
        {/*  >*/}
        {/*    {t('auth:TERMS_TEXT_2')}*/}
        {/*  </span>{' '}*/}
        {/*  {t('auth:TERMS_TEXT_3')}{' '}*/}
        {/*  <span*/}
        {/*    className="text-muted"*/}
        {/*  >*/}
        {/*    {t('auth:TERMS_TEXT_4')}*/}
        {/*  </span>*/}
        {/*</label>*/}
        <label className="form-check-label cursor-pointer" htmlFor="agree">
          {t('auth:TERMS_TEXT_1')}{' '}
          <span className="text-muted cursor-pointer terms-link" onClick={(e) => openTermsModal(terms?.terms!)}>
            {t('auth:TERMS_TEXT_2')}
          </span>{' '}
          {t('auth:TERMS_TEXT_3')}{' '}
          <span className="text-muted cursor-pointer terms-link" onClick={(e) => openTermsModal(terms?.privacy!)}>
            {t('auth:TERMS_TEXT_4')}
          </span>
        </label>
      </div>
      <Button
        loading={loading}
        disabled={!agree}
        type="submit"
        text='auth:LOGIN'
        className='w-100'
      />
    </AuthWrap>
  );
});

export default LoginRoute;
