import React, {FC, useEffect, useRef} from "react";
import {observer, useLocalObservable} from "mobx-react";
import ModalClose from "../ModalClose";
import asyncModal from "react-async-modal";
import {
  EContentPlanVideoOrigin,
  ECountry, ETaskStatus,
  IMonitoringVideo,
  IResearch,
  IResearchCountry,
  ITrendingItem,
} from "../../modules/rest";
import {runInAction} from "mobx";
import {API} from "../../modules/api";
import BestReactionsTabs from "./BestReactionsTabs";
import session from "../../modules/session";
import {toast} from "react-toastify";
import BestReactionsList from "./BestReactionsList";
import BestReactionsRight from "./BestReactionsRight";
import BestReactionsHeader from "./BestReactionsHeader";
import BestReactionsSelectCountry from "./BestReactionsSelectCountry";
import BestReactionsUpdate from "./BestReactionsUpdate";
import {useTranslation} from "react-i18next";
import {smallestScreen} from "../../modules/utils";
import CopyToClipboard2 from "../../components/CopyToClipboard2";
import {ReactSVG} from "react-svg";
import chevron from '../../assets/images/icons/chevron_right.svg';
import copy_svg from '../../assets/images/icons/copy_black.svg';
import check from "../../assets/images/icons/done.svg";
import ModalHeader from "../ModalHeader";

interface Props {
  resolve(): void;

  video: IMonitoringVideo|ITrendingItem;
  videoType: EContentPlanVideoOrigin;
}

interface State {
  updating: boolean;
  loading: boolean;
  activeCountry?: ECountry;
  research?: IResearch;
  data?: IResearchCountry;
  step?: 'tags'|null;
}

const BestReactionsModal: FC<Props> = observer(({video, videoType, resolve}) => {
  const _ping: any = useRef(null);
  const {t} = useTranslation();
  const _pingList: any = useRef(null);
  const st = useLocalObservable<State>(() => ({
    updating: false,
    loading: false,
    pendingUpdate: false,
    updatedAt: null,
  }));

  useEffect(() => {
    checkReady();
    return () => {
      clearPing();
    }
  }, []);

  const checkReady = async (): Promise<any> => {
    try {
      const res = await API.Research.getByVideo(video.video.id);
      if (!res.isReady) return _ping.current = setTimeout(checkReady, 1000);
      runInAction(() => {
        st.research = res;
      });
    } catch (e: any) {
      checkReady();
    }
  }

  const fetchList = async (update?: boolean): Promise<any> => {
    if (!st.research?.id) return _pingList.current = setTimeout(fetchList, 1000);
    clearTimeout(_pingList.current);
    try {
      const res = await API.Research.processResearch(st.research?.id!, {country: st.activeCountry!, update});
      if (res.status === ETaskStatus.Failed || res.status === ETaskStatus.Finished) {
        runInAction(() => {
          st.data = res;
          st.updating = false;
          st.loading = false;
        });
      } else {
        runInAction(() => {
          st.data = res;
          st.loading = false;
        });
        _pingList.current = setTimeout(fetchList, 1000);
      }
    } catch (e: any) {
      toast.error(e);
      runInAction(() => {
        st.updating = false;
      });
    }
  }

  const clearPing = () => {
    clearTimeout(_ping.current);
    clearTimeout(_pingList.current);
  }

  const handleCountryChange = async (country: ECountry) => {
    runInAction(() => {
      st.activeCountry = country;
      st.loading = true;
      fetchList();
    })
  }

  const handleUpdateStatusClick = async () => {
    if (!st.data?.canUpdate) return;
    runInAction(() => {
      st.updating = true;
    });
    fetchList(true);
  }

  const loading = (!st.data?.reactions.length && (st.data?.status !== ETaskStatus.Failed && st.data?.status !== ETaskStatus.Finished)) || st.loading

  if (st.step === 'tags') return (
    <>
      <ModalHeader onClick={() => runInAction(() => {
        st.step = null;
      })} title={'BEST_TAGS'}/>
      <BestReactionsRight
        className='separate-modal'
        tags={st.data?.tags}
        loading={Boolean(st.data?.status !== 'finished' || !st.data?.tags?.length) || st.loading}
      />
      <CopyToClipboard2 text={st.data?.tags.join(', ')}>
        {(copied: boolean) => <div className='btn btn-primary mt-4'>
          <ReactSVG src={copied ? check : copy_svg} className='react-icon position-absolute' style={{left: 16}}/>
          {t("COPY")}
        </div>}
      </CopyToClipboard2>
    </>
  )

  return (
    <>

      <div className='best-reactions-modal'>
        <ModalHeader onClick={resolve}>
          <BestReactionsHeader video={video} videoType={videoType}/>
        </ModalHeader>

        {st.activeCountry
          ?
          <>
            <BestReactionsTabs
              disabled={loading}
              active={st.activeCountry}
              tabs={session.activeChannel?.countries}
              onClick={handleCountryChange}
            />
            <div className='best-reactions-modal-content'>
              <div className='flex-1'>
                <BestReactionsUpdate
                  data={st.data}
                  loading={st.updating}
                  onClick={handleUpdateStatusClick}
                />
                <div className={`best-reactions-modal-tags-input${!st.data?.tags?.length ? ' disabled' : ''}`}
                     onClick={() => runInAction(() => {
                       st.step = 'tags';
                     })}>
                  <div>{t('BEST_TAGS')}</div>
                  <div className='d-flex'>
                    <CopyToClipboard2 text={st.data?.tags.join(', ')}/>
                    <ReactSVG src={chevron} className='react-icon ms-2 ps-1' style={{opacity: 0.5}}/>
                  </div>
                </div>
                <div className='best-reactions-modal-list-wrap'>
                  <BestReactionsList data={st.data?.reactions} loading={loading}/>
                </div>
              </div>
              <BestReactionsRight
                error={st.data?.status === 'failed' && !st.data?.tags?.length}
                tags={st.data?.tags}
                loading={Boolean(st.data?.status !== 'finished' || !st.data?.tags?.length) || st.loading}
              />
            </div>
          </>
          :
          <BestReactionsSelectCountry onChange={handleCountryChange}/>
        }
      </div>
    </>
  );
});

const openBestReactionsModal = (video: IMonitoringVideo|ITrendingItem, videoType: EContentPlanVideoOrigin): Promise<boolean> => asyncModal(BestReactionsModal, {
  video,
  videoType
}, {
  classNames: {modal: 'add-channel-modal', modalContainer: smallestScreen() ? 'bottom-modal' : ''},
  blockScroll: false,
  showCloseIcon: false,
  center: true
});

export {openBestReactionsModal};