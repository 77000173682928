import React, {FC, useState} from 'react';
import {ReactSVG} from "react-svg";
import arrow from './assets/images/arrow.svg';
import edit from './assets/images/edit.svg';
import expand from './assets/images/expand.svg';
import collapse from './assets/images/collapse.svg';
import more from '../../assets/images/icons/more.svg';
import info from '../../assets/images/icons/info_white.svg';
import {openFilterContentCountryModal} from "./FilterContentCountryModal";
import {Link} from "react-router-dom";
import {openMonitoringGenresModal} from "./MonitoringGenresModal";
import {EContentPlanVideoOrigin} from "../../modules/rest";
import {useTranslation} from "react-i18next";
import useScreenSize from "../../hooks/useScreenSize";
import {Popover} from "react-tiny-popover";
import OutsideClick from "../OutsideClick";

interface Props {
  type: EContentPlanVideoOrigin;
  onChangeActiveContentType?: () => void;
}

const ContentTypeHeaderControls: FC<Props> = ({type, onChangeActiveContentType}) => {
  const {isSmallestScreen, isSmallScreen} = useScreenSize();
  const {t} = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleEditClick = () => {
    if (type === EContentPlanVideoOrigin.Trending) openFilterContentCountryModal().then();
    else if (type === EContentPlanVideoOrigin.NewVideos) openMonitoringGenresModal(0).then();
  }

  if (isSmallScreen && type !== EContentPlanVideoOrigin.Comments) return (
    <div onClick={handleEditClick} className='content-type-header-controls-more'>
      <ReactSVG src={more} className='react-icon'/>
    </div>
  )

  if (isSmallScreen && type === EContentPlanVideoOrigin.Comments) return null;

  return (
    <div className='content-type-header-controls'>
      <Link to={`/${type}`} title={t('GO_SECTION') || ''}>
        <ReactSVG src={arrow} className='react-icon content-type-header-controls-navigate me-2'/>
      </Link>
      {onChangeActiveContentType
        ?
        <div
          className='content-type-header-controls-resize'
          onClick={onChangeActiveContentType}
        >
          <div className='expand' title={t('MAXIMAZE') || ''}>
            <ReactSVG src={expand} className='react-icon'/>
          </div>
          <div className='collapse' title={t('MINIMAZE') || ''}>
            <ReactSVG src={collapse} className='react-icon '/>
          </div>
        </div>
        :
        null
      }
      {type !== EContentPlanVideoOrigin.Comments
        ?
        <>
          <OutsideClick outsideClickEvent={() => setPopoverOpen(false)}>
            <Popover positions={['bottom']} isOpen={popoverOpen} content={<div className='popover-content text-muted-14'>{t(`${type}_INFO_TEXT`)}</div>}>
              <div title={t('info') || ''} className='ms-2 content-type-header-controls-info'
                   onClick={() => setPopoverOpen(prevState => !prevState)}>
                <ReactSVG src={info} className=' react-icon'/>
              </div>
            </Popover>
          </OutsideClick>
          <div title={t('EDIT') || ''} className='ms-2 content-type-header-controls-edit'>
            <ReactSVG src={edit} className='react-icon' onClick={handleEditClick}/>
          </div>
        </>
        :
        null
      }
    </div>
  );
}

export default ContentTypeHeaderControls;
