import React, {FC, useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import {ReactSVG} from "react-svg";
import logo from '../../assets/images/icons/tg.svg'

interface Props {
  title?: string;
  primary?: boolean;
  icon?: boolean;
}

const TgBtn: FC<Props> = ({title, primary, icon = true}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const link = await API.Telegram.getNotificationsBotLink();
      window.open(link!);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false)
    }
  }, [loading]);

  if(primary) return <button type='button' className='btn btn-sm tg-btn-primary'  onClick={handleClick}>
    {t(title || 'LAUNCH')}
    {icon && <ReactSVG src={logo} className='react-icon tg-btn-primary-icon' /> }
  </button>

  return (
    <button type='button'  className='btn btn-outline-primary btn-sm tg-btn' onClick={handleClick}>
      {t(title || 'LAUNCH')}
    </button>
  );
}

export default TgBtn;