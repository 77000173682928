import React, {FC} from 'react';
import {thumb} from "../../modules/utils";
import {IMonitoringVideo, ITrendingItem} from "../../modules/rest";
import {ReactSVG} from "react-svg";
import play_svg from "../../assets/images/icons/video_play_small.svg";

interface Props {
  image?: string;
  url?: string;
}

const ContentTypeItemImg: FC<Props> = ({image, url}) => {
  if (!image) return (
    <a href={url} target='_blank' className='content-type-item-img empty'>
    </a>
  )
  return (
    <a href={url} target='_blank' className='content-type-item-img'>
      <img
        src={image}
        alt="img"/>
      <div className='content-type-item-play'>
        <ReactSVG className='react-icon' src={play_svg}/>
      </div>
    </a>
  );
}

export default ContentTypeItemImg;