import React, {FC, useState} from 'react';
import {ReactSVG} from "react-svg";

import close from '../../assets/images/icons/close_small.svg';
import {useTranslation} from "react-i18next";
import {extensionLink} from "./index";
import {openExtensionModal} from "./ExtensionModal";

interface Props {
}

const ExtensionPanel: FC<Props> = () => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(true);


  if (!visible) return null;

  return (
    <div className='extension-panel'>
      <div className='extension-panel-content'>
        {/*<ReactSVG src={extension_svg} className='react-icon me-2'/>*/}
        <div className='d-flex justify-content-between'>
        <span className='text-12 text-medium pe-3 text-decoration-none' dangerouslySetInnerHTML={{__html: t('EXTENSION_TITLE', {href: extensionLink}) || ''}} />
          <ReactSVG src={close} className='react-icon extension-panel-close' onClick={() => setVisible(false)}/>
        </div>
        <div className='d-flex justify-content-between mt-3 align-items-center'>
          <a href={extensionLink} target='_blank' className='btn btn-outline-primary btn-sm'>
            {t('INSTALL')}
          </a>
          <div className='extension-panel-how text-12' onClick={openExtensionModal}>{t('HOW_IT_WORK')}</div>
        </div>
      </div>
    </div>
  );
}

export default ExtensionPanel;