import React, {FC} from 'react';
import {ModalClose} from "./index";
import {useTranslation} from "react-i18next";

interface Props {
  onClick: () => void;
  title?: string;
  children?: any;
  titleClassName?: string;
  closable?: boolean;
  needTitleTranslate?: boolean;
}

const ModalHeader: FC<Props> = ({
                                  onClick,
                                  title,
                                  needTitleTranslate = true,
                                  children,
                                  titleClassName,
                                  closable = true
}) => {
  const {t} = useTranslation();
  return (
    <div className='modal-header'>
      <div className={`modal-title ${titleClassName || ''}`}>
        {children || <h2>{needTitleTranslate ? t(title!) : title}</h2>}
      </div>
      {closable && <ModalClose onClick={onClick} className='modal-close-relative'/>}
    </div>
  );
}

export default ModalHeader;