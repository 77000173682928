import React, {FC, Fragment} from 'react';
import Loader from "../../components/Loader";
import {ReactSVG} from "react-svg";
import stub_svg from "./assets/images/stub.svg";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {EContentPlanVideoStatus} from "../../modules/rest";
import no_camera from "../../assets/images/icons/no_camera.svg";
import no_rocket from "../../assets/images/icons/no_rocket.svg";
import trash from "../../assets/images/icons/trash.svg";
import ContentPlanItemBody from "./ContentPlanItemBody";
import camera from "../../assets/images/icons/camera.svg";
import rocket from "../../assets/images/icons/rocket.svg";
import {formatDate} from "../../modules/utils";
import chevron_svg from "../../assets/images/icons/chevron_sm_white.svg";
import Loadable from "../../components/Loadable";
import ContentPlanItemSmall from "./ContentPlanItemSmall";
import {dataFromStatus} from "./ContentPlan";

interface Props {
  ready: boolean;
  isArchive?: boolean;
  dashboard?: boolean;
  small?: boolean;
  onClick?: () => void;
}

const emptyDatesArr = [moment().toDate().toLocaleDateString(), moment().add(1, 'days').toDate().toLocaleDateString()]

const ContentPlanEmpty: FC<Props> = ({ready, onClick, isArchive, dashboard, small}) => {
  const {t} = useTranslation();

  if (!ready) return  <Loader className='justify-content-center d-flex py-5'/>

  if(dashboard && small) return (
    <div className={`content-plan-small`}>
      {emptyDatesArr.map(date => (
        <div className="content-plan-section" key={date}>
          <h2 className='mb-3 mb-xl-1 px-2'>{formatDate(date)}</h2>
          {Object.values(EContentPlanVideoStatus).map(status => (
            <div key={status}  className={`content-plan-item-status disabled`}>
              <div className="content-plan-head-item">
                <div className='d-flex align-items-center flex-1'>
                  <ReactSVG src={dataFromStatus[status].icon} className='react-icon'/>
                  <div className='ms-1'>{t(dataFromStatus[status].title)}</div>
                </div>
                <ReactSVG src={chevron_svg} className='react-icon chevron'/>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )

  if (dashboard) return (
    <div className="content-plan-section">
      {emptyDatesArr.map(date => (
        <Fragment key={date}>
          <h2 className='pb-1 ps-4 ms-1'>{date}</h2>
          <div className="content-plan-line">
            {Array(3).fill({}).map((_, i) => (
              <div className={`content-plan-item-wrap`} key={i}>
                <div className="content-plan-item"/>
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  )

  return (
    <div className='content-plan-empty'>
      <ReactSVG src={stub_svg} className='react-icon mb-1' style={{opacity: 0.5}}/>
      <div className='text-muted-14'>{t('CONTENT_PLAN_EMPTY_TITLE')}</div>
      {!isArchive &&
        <button className='btn btn-outline-primary btn-lg btn-add mt-4' onClick={onClick}>
          {t('ADD')}
        </button>
      }
    </div>
  );
}

export default ContentPlanEmpty;