import React, {FC, useCallback, useState} from 'react';
import {Checkbox} from "../../components/FormControls";
import OutsideClick from "../../components/OutsideClick";
import {IChannel, IMonitoringCategory, IMonitoringChannel} from "../../modules/rest";
import {API} from "../../modules/api";
import Loadable from "../../components/Loadable";
import {toJS} from "mobx";
import {toast} from "react-toastify";
import {thumb} from "../../modules/utils";
import {useTranslation} from "react-i18next";
import Loader from "../../components/Loader";

interface Props {
  item: IMonitoringCategory;
  subscriptions: IMonitoringCategory[];
  onUpdate: (category: IMonitoringCategory) => void;
}

const ChannelCategoryItem: FC<Props> = ({item, subscriptions, onUpdate}) => {
  const {t} = useTranslation();
  const [infoVisible, setInfoVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [channelsLoading, setChannelsLoading] = useState(false);
  const [channels, setChannels] = useState<IMonitoringChannel[]>();
  const [checked, setChecked] = useState(!!subscriptions.find(sub => sub.id === item.id));

  const handleCheck = async (e: any) => {
    const value = e.target.checked;
    setChecked(value);
    onUpdate(item);
  }

  const handleOpen = async () => {
    setInfoVisible(true);
    if (channels || channelsLoading) return;
    setChannelsLoading(true);
    try {
      const res = await API.Monitoring.getChannels({category: item.id, scope: 'global', limit: 20});
      setChannels(res.data);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setChannelsLoading(false);
    }
  }

  return (
    <OutsideClick outsideClickEvent={() => setInfoVisible(false)}
                  className={`channel-category-item${checked ? ' active' : ''}`}>
      <Loadable loading={loading} className='d-flex align-items-center'>
        <Checkbox checked={checked} className='mb-0 me-1' small onChange={handleCheck}/>
        <span className='ps-2'>{item.name}</span>
      </Loadable>
      {/*<div className="channel-category-item-info" onClick={handleOpen}/>*/}
      {infoVisible &&
        <div className="channel-category-item-info-hidden">
          <h4>{item.name}</h4>
          <div className='channel-category-item-info-hidden-list'>
            {loading && <Loader/>}
            {channels?.length
              ?
              channels.map((channel) => (
                <div className='channel-category-item-info-hidden-item' key={channel.id}>
                  <img src={thumb(channel?.channel?.icon?.id!, 64)} alt="img"/>
                  <div className='d-grid'>
                    <div className='text-truncate'>{channel?.channel.name}</div>
                    <a href={channel?.channel?.url} target='_blank'
                       className='text-truncate'>@{channel?.channel?.handle}</a>
                  </div>
                </div>
              ))
              :
              <div className='px-3 pt-3 pb-2 text-muted-14'>
                {channelsLoading ? '' : t('EMPTY_LIST')}
              </div>
            }
          </div>
        </div>
      }
    </OutsideClick>
  );
}

export default ChannelCategoryItem;