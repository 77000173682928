import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import star from '../../components/ContentType/assets/images/star.svg';
import fire from '../../components/ContentType/assets/images/fire.svg';
import question from '../../components/ContentType/assets/images/question.svg';
import moment from "moment";
import {EContentPlanVideoStatus, IContentPlanVideo} from "../../modules/rest";
import ContentPlanItemBody from "./ContentPlanItemBody";
import more_svg from '../../assets/images/icons/more_white.svg';
import {openContentPlanModalMore} from "./ContentPlanModalMore";

interface Props {
  item: IContentPlanVideo;
  more?: boolean;
  fetch?: () => void;
}

const icons: any = {
  trending: fire,
  'new-videos': star,
  comments: question,
}

const ContentPlanItemSmall: FC<Props> = ({item, more = true, fetch}) => {

  const handleClick = () => {
    openContentPlanModalMore(item, fetch!).then(success => {
      if(success && fetch) fetch()
    })
  }

  return (
    <div className="content-plan-item-small">
      <div className='text-14'>{moment(item.scheduledAt).format('HH:mm')}</div>
      <div className='content-plan-item-info'>
        <ContentPlanItemBody item={item}/>
      </div>
      {icons[item.origin] ?
        <ReactSVG src={icons[item.origin]} className='react-icon content-plan-item-type-icon'/> : null}
      {more && <ReactSVG src={more_svg} className='react-icon content-plan-item-type-more'  onClick={handleClick}/>}
    </div>
  );
}

export default ContentPlanItemSmall;