import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
// import stub from './images/stub.svg'
import stub from './images/stub.png'

interface Props {

}

const BestReactionsEmptyList: FC<Props> = (props) => {
  const {t} = useTranslation();
  return (
    <div className='pt-5 d-flex flex-column align-items-center'>
      <img src={stub} alt="stub" style={{width: 156}}/>
      <div className='text-bold text-center mt-4'>{t('BEST_REACTIONS_EMPTY_TITLE')}</div>
      <p className='text-muted-14 mt-2 text-center text-pre-wrap'>{t('BEST_REACTIONS_EMPTY_TEXT')}</p>
    </div>
  );
}

export default BestReactionsEmptyList;