import {RestAPI} from './rest';
import i18next from "i18next";

const isDev = !!window.location.host.match(/^(localhost|127\.|192\.)/);
// const backendUrl = isDev ? 'http://192.168.1.223:8004' : `https://api.reactrino.com`;
const backendUrl = isDev ? 'https://api-dev.tubyx.com' : `https://api.reactrino.com`;
// const backendUrl =  `https://api.reactrino.com`;
const API = new RestAPI(backendUrl, isDev);

API.setAuthErrorHandler(() => {
  alert('Session expired')
  window.localStorage.removeItem('token');
  window.location.href = '/';
})

API.setHeadersHandler((headers) => {
  headers['Accept-Language'] = i18next.language;
});

export {API, isDev, backendUrl};
