import React, {FC} from 'react';
import {formatViews} from "../../modules/utils";
import moment from "moment/moment";
import {IMonitoringVideo, ITrendingItem} from "../../modules/rest";
import {useTranslation} from "react-i18next";

interface Props {
  item?: ITrendingItem|IMonitoringVideo
}

const ContentTypeListItemViews: FC<Props> = ({item}) => {
  const {t} = useTranslation();
  return (
    <div className='text-muted-12 text-truncate content-type-item-views'>
      <div className='text-lowercase'>{formatViews(item?.video?.viewCount!)} {t('VIEWS')}</div>
      <div className="best-reactions-modal-item-separator"/>
      <div className='text-lowercase'>{moment(item?.video.publishedAt).calendar()}</div>
    </div>
  );
}

export default ContentTypeListItemViews;