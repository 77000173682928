import asyncModal from 'react-async-modal';
import React, {FC, FormEvent, useCallback, useEffect, useState} from 'react';
import Loadable from '../components/Loadable';
import {useTranslation} from "react-i18next";
import ModalClose from "./ModalClose";
import Button from "../components/Button";
import {Input} from "../components/FormControls";
import {API} from "../modules/api";
import {
  EContentPlanVideoOrigin,
  IContentPlanVideo,
  IMonitoringVideo,
  IReactorComment,
  ITrendingItem
} from "../modules/rest";
import CloseBtn from "../components/CloseBtn";
import {observer} from "mobx-react";
import session from "../modules/session";
import moment from "moment";
import {toast} from "react-toastify";
import ContentTypeItemImg from "../components/ContentType/ContentTypeItemImg";
import {smallestScreen, thumb} from "../modules/utils";
import {ReactSVG} from "react-svg";
import plan_svg from "../components/ContentType/assets/images/plan.svg";
import plan_add_svg from "../components/ContentType/assets/images/plan_add.svg";
import comment_stub from "../components/ContentType/assets/images/question.svg";
import ListItemTrendsTag from "../components/ContentType/ContentTypeTrends/ListItemTrendsTag";
import Tag from "../components/Tag";
import {openBestReactionsModal} from "./BestReactionsModal/BestReactionsModal";
import ContentTypeListItemViews from "../components/ContentType/ContentTypeListItemViews";
import ModalHeader from "./ModalHeader";

interface Props {
  item?: ITrendingItem&IMonitoringVideo&IReactorComment;
  type?: EContentPlanVideoOrigin
  contentPlanItem?: IContentPlanVideo

  resolve(item: IContentPlanVideo | null): void;
}


const ComposeChannelToContentPlanModal: FC<Props> = observer(({item, type, contentPlanItem, resolve}) => {
  const {t} = useTranslation();
  const [step, setStep] = useState<'content-plan'>();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [contentPlan, setContentPlan] = useState<IContentPlanVideo[]>();
  const [url, setUrl] = useState(contentPlanItem?.originVideoUrl || item?.video?.url || '');
  const [scheduledAt, setScheduledAt] = useState({
    date: contentPlanItem?.scheduledAt ? moment(contentPlanItem?.scheduledAt).format('YYYY-MM-DD') : '',
    time: contentPlanItem?.scheduledAt ? moment(contentPlanItem?.scheduledAt).format('HH:mm') : ''
  });

  const handleChangeDate = (key: string) => (e: any) => {
    setScheduledAt(prevState => ({...prevState, [key]: e.target.value}));
  };

  const handleClose = useCallback(() => {
    resolve(null);
  }, [resolve]);

  useEffect(() => {
    getContentPlan();
  }, [scheduledAt]);


  const getContentPlan = async () => {
    try {
      setLoading(true);
      if (moment(scheduledAt.date).isValid()) {
        const res = await API.ContentPlan.get(session.activeChannel?.id!, {dateFrom: scheduledAt.date});
        setContentPlan(res);
      }
    } catch (e) {

    } finally {
      setLoading(false);
    }
  }


  const submit = async (e: FormEvent) => {
    e.preventDefault();
    setLoadingBtn(true);
    if (loadingBtn) return;
    try {
      const date = moment(`${scheduledAt.date} ${scheduledAt.time || '12:00'}`).format();
      if (!moment(date).isValid()) return;

      const req = {
        origin: type,
        scheduledAt: date,
        originUrl: url,
      }
      let res;
      if (contentPlanItem) {
        res = await API.ContentPlan.updateVideo(contentPlanItem.id, req)
      } else {
        res = await API.ContentPlan.addVideo(session.activeChannel?.id!, req)
      }
      resolve(res);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoadingBtn(false)
    }
  }

  if (smallestScreen() && !step && item) return (
    <>
      <ModalClose onClick={handleClose} style={{top: 16, right: 16}}/>
      <div>
        {type === EContentPlanVideoOrigin.Comments
          ?
          <div className='d-flex align-items-center'>
            <ReactSVG src={comment_stub} className='react-icon me-2'/>
            <div className='d-grid flex-grow-1 overflow-hidden'>
              <div className='text-truncate text-bold-14'>{item?.title}</div>
              <div className='text-muted-14 text-truncate'>{item?.performer}</div>
            </div>
          </div>
          :
          <div>
            <div className='d-flex align-items-center pe-5 me-3 mb-1'>
              <ContentTypeItemImg image={thumb(item?.video?.preview?.id!, 180)} url={item?.video?.url}/>
              <div className='d-grid overflow-hidden ps-2'>
                <div className='text-medium-14 text-truncate-2 text-muted'>{item?.video?.title}</div>
              </div>
            </div>
            <ContentTypeListItemViews item={item} />
          </div>
        }

        {type === EContentPlanVideoOrigin.Trending &&
          <div className='d-flex mt-1 flex-wrap'>
            {item?.countries?.map(country => (
              <ListItemTrendsTag country={country} key={country} className='ms-0 mb-0'/>
            ))}
          </div>
        }
        {type === EContentPlanVideoOrigin.NewVideos &&
          <div className='d-flex mt-1 flex-wrap'>
            {item?.channel?.categories?.map((category) => (
              <Tag text={category.name!} key={category.id!} className='me-1 pe-0 mt-1' small/>
            ))}
          </div>
        }
        <div className='modal-content-type-edit-small mt-4'>
          {item?.isPlanned
            ?
            <div className='modal-content-type-edit-small-item pointer-event-none'>
              <ReactSVG src={plan_svg} className='react-icon me-1' style={{opacity: 0.3}}/>
              <span style={{opacity: 0.3}}>{t('IN_CONTENT_PLAN')}</span>
            </div>
            :
            <div className='modal-content-type-edit-small-item' onClick={() => setStep('content-plan')}>
              <ReactSVG src={plan_add_svg} className='react-icon me-1'/>
              <span>{t('ADD_TO_CONTENT_PLAN')}</span>
            </div>
          }

          {type === EContentPlanVideoOrigin.Comments
            ?
            <a
              href={`https://www.youtube.com/results?search_query=${item?.performer} - ${item?.title}`}
              target='_blank'
              className='modal-content-type-edit-small-item text-tint'>{t('SEARCH_IN_YOUTUBE')}</a>
            :
            <div
              className="modal-content-type-edit-small-item text-tint"
              onClick={() => {
                handleClose()
                openBestReactionsModal(item!, type!)
              }}
            >
              {t('BEST_REACTION')}
            </div>
          }
        </div>
      </div>
    </>
  )

  return (
    <>
      <form onSubmit={submit}>
        <ModalHeader onClick={handleClose} title={contentPlanItem ? 'UPDATE_CONTENT_PLAN' : 'ADDING_TO_CONTENT_PLAN'} />
        {type === EContentPlanVideoOrigin.Comments &&
          <div className='card justify-content-between flex-row align-items-center mb-4'>
            <div>
              <div className='text-truncate text-bold-14'>{item?.title}</div>
              <div className='text-muted-14 text-truncate'>{item?.performer}</div>
            </div>
            <a
              href={`https://www.youtube.com/results?search_query=${item?.performer} - ${item?.title}`}
              target='_blank'
              className='text-14 text-nowrap me-2 cursor-pointer text-tint-active'>{t('SEARCH_IN_YOUTUBE')}</a>
          </div>
        }
        <Input
          required
          placeholder={t('LINK') || ''}
          clearable
          className='sm'
          type='url'
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          label={'PROVIDE_LINK_TO_VIDEO'}
        />
        {item && url === item.video?.url
          ?
          <div className="under-input-card d-flex align-items-center">

            <ContentTypeItemImg image={thumb(item.video?.preview?.id!, 180)} url={item.video?.url}/>
            <div className='d-grid flex-grow-1 overflow-hidden'>
              <div className='text-muted-14 text-bold ms-2 text-truncate'>{item.title}</div>
            </div>
            <CloseBtn onClick={() => setUrl('')} className='me-1 ms-3'/>
          </div>
          :
          null
        }

        <div className='input-group mt-4'>
          <Input
            onKeyDown={(e) => e.preventDefault()}
            required
            type={'date'}
            className='sm w-50'
            label={'DATE_AND_TIME'}
            placeholder={t('FROM') || ''}
            value={scheduledAt.date}
            onChange={handleChangeDate('date')}
          />
          <Input
            type={'time'}
            className={'sm w-50'}
            placeholder={t('TIME') || ''}
            value={scheduledAt.time}
            onChange={handleChangeDate('time')}
          />
        </div>
        {contentPlan?.length
          ?
          <div className="under-input-card px-4" style={{paddingTop: 52}}>
            <Loadable loading={loading}>
              {contentPlan.map((item) => (
                <div key={item.id} className='modal-content-plan-item'>
                  <div className='text-muted-12 text-truncate pe-4 text-bold'>{item.title}</div>
                  <div className='text-14'>{moment(item.scheduledAt).format('HH:mm')}</div>
                </div>
              ))}
            </Loadable>
          </div>
          :
          null
        }


        <Loadable loading={loadingBtn} className="modal-footer-btns">
          <Button
            text='CANCEL'
            onClick={handleClose}
            color='outline-primary'
          />
          <Button
            text={contentPlanItem ? 'UPDATE' : 'ADD'}
            type='submit'
          />
        </Loadable>
      </form>
    </>
  );
});

const openComposeChannelToContentPlanModal = (
  item?: ITrendingItem|IMonitoringVideo|IReactorComment,
  type?: EContentPlanVideoOrigin,
  contentPlanItem?: IContentPlanVideo
): Promise<IContentPlanVideo | null> => asyncModal(ComposeChannelToContentPlanModal, {
  item,
  contentPlanItem,
  type
}, {
  classNames: {modal: 'add-channel-modal', modalContainer: smallestScreen() ? 'bottom-modal' : ''},
  showCloseIcon: false,
  blockScroll: false,
  center: true
});

export {openComposeChannelToContentPlanModal};
