import asyncModal from 'react-async-modal';
import React, {FC, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IGetYoutuberStatsRequest, IReferralRecord, IVideoEmbed} from "../../modules/rest";
import ModalHeader from "../ModalHeader";
import geos from "../../translates/en/geos.json";
import {formatNumber, getEmbedName} from "../../modules/utils";
import Video from "../../components/Video";
import {UserStates} from "../../modules/directory";

interface Props {
  item: IReferralRecord

  resolve(request?: IGetYoutuberStatsRequest): void;
}

const ReferralItemModal: FC<Props> = ({item, resolve}) => {

  const {t} = useTranslation();
  const videoRef: any = useRef(null);
  const [activeVideo, setActiveVideo] = useState(false)


  return (
    <div className='report-filter-modal'>
      <ModalHeader onClick={() => resolve()} title={item.email}/>

      <div className="report-filter-modal-stat">
        <div className="row g-0">

          <div className="col-6">
            <div className="report-filter-modal-stat-item">
              <div className='text-muted-14 mb-1'>{t('STATUS')}</div>
              <div className='d-flex justify-content-start'>
                <div className={`referrals-status ${item.status}`}>{t(UserStates[item.status])}</div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="report-filter-modal-stat-item">
              <div className='text-muted-14 mb-1'>{t('CHANNELS')}</div>
              <div className='text-bold-14'>{formatNumber(item.channels)}</div>
            </div>
          </div>
          <div className="col-6">
            <div className="report-filter-modal-stat-item">
              <div className='text-muted-14 mb-1'>{t('VIEWS')}</div>
              <div className='text-bold-14'>{formatNumber(item.views)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const openReferralItemModal = (item: IReferralRecord): Promise<void> => asyncModal(ReferralItemModal, {
  item,
}, {
  showCloseIcon: false,
  classNames: {modalContainer: 'bottom-modal'},
  center: true
});

export {openReferralItemModal};
