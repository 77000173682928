import React, {FC, useState} from 'react';
import {IReactorComment, TIdentifier} from "../../../modules/rest";
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";
import close from '../../../assets/images/icons/close.svg';
import Loadable from "../../Loadable";
import {smallestScreen} from "../../../modules/utils";

interface Props {
  item: IReactorComment;
  idx: number;
  onAddToContentPlan: (item: IReactorComment) => () => void;
  onRemoveComment: () => Promise<void>;
}

const ListItemComments: FC<Props> = ({item, onAddToContentPlan, idx, onRemoveComment}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleRemove = async () => {
    setLoading(true);
    await onRemoveComment();
    setLoading(false);
  }

  return (

    <Loadable loading={loading} className='content-type-item'>
      <div className='d-flex w-100 align-items-center' onClick={smallestScreen() ? onAddToContentPlan(item) : undefined}>
        <div className='content-type-item-id'>{idx}</div>
        <div className='content-type-item-info ps-0'>
          <div className='d-grid flex-grow-1 overflow-hidden'>
            <div className='text-truncate text-bold-14'>{item.title}</div>
            <div className='text-muted-14 text-truncate'>{item.performer}</div>
          </div>
        </div>
        <div className='content-type-item-right'>
          <div className='d-flex align-items-center'>
            <div className='content-type-item-hidden'>
              <a
                href={`https://www.youtube.com/results?search_query=${item.performer} - ${item.title}`}
                target='_blank'
                className='text-tint-active text-14 text-nowrap me-2 cursor-pointer'>{t('SEARCH_IN_YOUTUBE')}</a>
              <button type='button' className='btn btn-outline-primary btn-xs px-3 mx-1'
                      onClick={onAddToContentPlan(item)}>
                {t('ADD_TO_CONTENT_PLAN')}
              </button>
              <ReactSVG src={close} className='react-icon cursor-pointer' onClick={handleRemove}/>
            </div>
          </div>
          <div className='content-type-tags text-muted-12'>
            {t('COMMENTS_')}: {item.count}
          </div>
        </div>
      </div>
    </Loadable>

  );
}

export default ListItemComments;