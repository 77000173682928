import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './translates/en/en.json';
import auth_en from './translates/en/auth.json';
import langs_en from './translates/en/langs.json';
import geos_en from './translates/en/geos.json';
import {getLang} from "./modules/utils";

export const availableLanguages = ['en']

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {

  en: {
    translation: en,
    auth: auth_en,
    langs: langs_en,
    geos: geos_en,
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: getLang(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    // defaultNS: 'ru',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;