import {makeAutoObservable, runInAction} from 'mobx';
import {
  EFieldGroup,
  IMonitoringCategory,
  IReactorChannel,
  IUpdateReactionChannelRequest,
  IUser, IYoutuberApplication,
  TIdentifier
} from './rest';
import {API} from './api';
import version from './version';
import {openComposeChannelModal} from "../modals";
import {toast} from "react-toastify";

class Session {
  _ping: any;
  public user: IUser|null = null;
  public categories: IMonitoringCategory[] = [];
  public channels: IReactorChannel[] = [];
  public activeChannel: IReactorChannel|undefined;
  public monetization: IYoutuberApplication|null = null;
  public ready: boolean = false;

  constructor() {
    version.init();
    const m = window.location.pathname.match(/^\/as\/([0-9A-z\.\-\_=]+)$/);
    if (m) {
      localStorage.setItem('token', m[1]);
    }
    makeAutoObservable(this);
    this.fetch().catch(() => runInAction(() => {
      this.ready = true;
    }));
  }

  fetch = async (): Promise<any> => {
    clearTimeout(this._ping)
    const token = window.localStorage.getItem('token');
    if (token) {
      API.setToken(token);
      try {
        const user = await this.getUser();
        this.init(user);
      } catch (e) {
        // if (API.getStatusCode() === 401) {
        //   API.setToken(null);
        //   window.localStorage.removeItem('token');
        //   runInAction(() => {
        //     this.user = null;
        //     this.ready = true;
        //   });
        // } else {
        //   this._ping = setTimeout(this.fetch, 5000);
        // }
        this._ping = setTimeout(this.fetch, 5000);
      }
    } else {
      return Promise.reject();
    }
  };

  init = async (user: IUser) => {
    if (!user) return;
    try {
      this._ping = setInterval(this.getUser, 60000);
      const categories = await API.Monitoring.getCategories();
      runInAction(() => {
        this.categories = categories;
      });
      const channels = await API.ReactorChannels.getChannels([EFieldGroup.ReactorChannelCategories, EFieldGroup.ReactorChannelCountries]);
      let monetization: IYoutuberApplication|null = null;
      if (!user.flagYoutuberMonetizationEnabled) {
        monetization = await API.Reactrino.getMonetizationStatus();
      }
      runInAction(() => {
        this.channels = channels;
        this.activeChannel = channels?.find(ch => String(ch.id) === localStorage.getItem('activeChannel')) || channels?.[0];
        this.monetization = monetization;
      });

    } catch (e) {
    } finally {
      runInAction(() => {
        this.ready = true;
      })
    }
  }

  getUser = async () => {
    try {
      const user = await API.Users.getMe([EFieldGroup.UserBalance, EFieldGroup.UserRef, EFieldGroup.UserTelegram, EFieldGroup.UserYoutuberFlags]);
      runInAction(() => {
        this.user = user;
      });
      return user;
    } catch (e) {
      throw e;
    }
  }

  login = (user: IUser) => {
    clearInterval(this._ping);
    runInAction(() => {
      this.user = user;
      this.ready = false;
    });
    this.init(user);
  };

  logout = (): void => {
    API.setToken(null);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('email');
    clearInterval(this._ping);
    runInAction(() => {
      this.user = null;
    });
  };

  composeChannel = async (closable = true, channel?: IReactorChannel) => {
    try {
      const res = await openComposeChannelModal(closable, channel);
      if (res) {
        const channels = await API.ReactorChannels.getChannels([EFieldGroup.ReactorChannelCategories, EFieldGroup.ReactorChannelCountries]);
        runInAction(() => {
          this.channels = channels;
          this.activeChannel = res;
        });
      }
    } catch (e) {
      throw e;
    }
  }

  removeChannel = async (id: TIdentifier) => {
    try {
      await API.ReactorChannels.deleteChannel(id);
      runInAction(() => {
        this.channels = this.channels.filter(item => item.id !== id);
        if (this.activeChannel?.id === id) {
          this.activeChannel = this.channels?.[0];
        }
      })
    } catch (e) {
      throw e;
    }
  }

  updateChannel = async (id: TIdentifier, req: IUpdateReactionChannelRequest) => {
    try {
      const channel = await API.ReactorChannels.updateChannel(id, req, [EFieldGroup.ReactorChannelCategories, EFieldGroup.ReactorChannelCountries]);
      const channels = await API.ReactorChannels.getChannels([EFieldGroup.ReactorChannelCategories, EFieldGroup.ReactorChannelCountries]);
      runInAction(() => {
        this.activeChannel = channel;
        this.channels = channels;
      })
    } catch (e) {
      throw e;
    }
  }

}

export default new Session();
