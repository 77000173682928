import asyncModal from 'react-async-modal';
import React, {FC, FormEvent, useCallback, useState} from 'react';
import Loadable from '../components/Loadable';
import {useTranslation} from "react-i18next";
import Button from "../components/Button";

import Select, {components} from 'react-select'
import {Input} from "../components/FormControls";
import {API} from "../modules/api";
import session from "../modules/session";
import {Regions} from "../modules/directory";
import geos from "../translates/en/geos.json";
import {EFieldGroup, IReactorChannel} from "../modules/rest";
import {smallestScreen, thumb} from "../modules/utils";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import ModalHeader from "./ModalHeader";

interface Props {
  resolve(channelId: null|IReactorChannel): void;

  channel?: IReactorChannel;
  closable?: boolean;
}

export const CustomOption = ({children, ...props}: any) => {
  return (
    <components.Option {...props}>
      <div className='react-select__option-content'>
        {children}
      </div>
    </components.Option>
  );
};

const ComposeChannelModal: FC<Props> = ({resolve, closable = true, channel}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<{ url: string, categories: any[], countries: any[] }>({
    url: '',
    categories: channel?.categories || [],
    countries: channel?.countries?.length ? channel?.countries.map(ln => ({value: ln, label: ln})) : [],
  });

  const handleChange = (key: string, event?: boolean) => (e: any) => {
    const value = event ? e.target.value : e;
    setForm(prevState => ({...prevState, [key]: value}));
  };

  const handleClose = useCallback(() => {
    if (!closable) return;
    resolve(null);
  }, [resolve, closable]);


  const submit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const categories = form.categories.map(c => c.id);
      const countries = form.countries.map(c => c.value);
      let res;
      if (channel) {
        res = await API.ReactorChannels.updateChannel(channel.id, {...form, categories, countries}, [EFieldGroup.ReactorChannelCategories, EFieldGroup.ReactorChannelCountries]);
        toast(t('UPDATE_CHANNEL_SUCCESS'));
      } else {
        res = await API.ReactorChannels.addChannel({...form, categories, countries}, [EFieldGroup.ReactorChannelCategories, EFieldGroup.ReactorChannelCountries]);
        toast(t('ADD_CHANNEL_SUCCESS'));
      }
      resolve(res);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      <Loadable loading={loading}>
        <form onSubmit={submit}>
          <ModalHeader closable={closable} onClick={handleClose} title={channel ? 'UPDATE_CHANNEL' : 'ADDING_CHANNEL'} />
          <div>
            {channel
              ?
              <div>
                <div className='mb-2 text-muted'>{t('CHANNEL')}</div>
                <div className={`add-channel-modal_channel`}>
                  <img src={thumb(channel?.channel?.icon?.id!, 64)} alt=""/>
                  <div className='d-grid overflow-hidden flex-grow-1'>
                    <div className='text-truncate'>
                      {channel?.channel?.name}
                    </div>
                  </div>
                </div>
              </div>
              :
              <Input
                label='URL_PLACEHOLDER'
                placeholder={t('LINK') || ''}
                className='sm mb-4'
                value={form.url}
                onChange={handleChange('url', true)}
              />
            }
            <div className='text-muted mb-2 pb-1' style={{maxWidth: 390}}>{t('COUNTRIES_LABEL')}</div>
            <Select
              required
              placeholder={t('COUNTRIES')}
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              value={form.countries}
              onChange={handleChange('countries')}
              components={{Option: CustomOption}}
              options={Regions.map(ln => ({value: ln, label: ln}))}
              //@ts-ignore
              getOptionLabel={option => geos[option?.label?.toUpperCase()]?.name || option}
              isMulti
              noOptionsMessage={() => t('EMPTY_LIST')}

            />
          </div>
          <div className='text-muted mb-2 pb-1' style={{maxWidth: 390}}>{t('GENRES_LABEL')}</div>
          <Select
            required
            placeholder={t('GENRES')}
            className="react-select-container"
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={form.categories}
            onChange={handleChange('categories')}
            components={{Option: CustomOption}}
            options={session.categories}
            getOptionValue={option => option.id + ''}
            getOptionLabel={option => option.name}
            isMulti
            noOptionsMessage={() => t('EMPTY_LIST')}
          />

          <div className="modal-footer-btns">
            {closable
              ?
              <Button
                text='CANCEL'
                onClick={handleClose}
                color='outline-primary'
              />
              :
              <div className='flex-1'/>
            }
            <Button
              text={channel ? 'UPDATE' : 'ADD'}
              type='submit'
            />
          </div>
        </form>
      </Loadable>
    </>
  );
};

const openComposeChannelModal = (closable = true, channel?: IReactorChannel): Promise<null|IReactorChannel> =>
  asyncModal(observer(ComposeChannelModal), {
    closable,
    channel
  }, {
    classNames: {modal: 'add-channel-modal', modalContainer: smallestScreen() ? 'bottom-modal' : ''},
    blockScroll: false,
    showCloseIcon: false,
    center: true,
    closeOnOverlayClick: closable,
  });

export {openComposeChannelModal};
