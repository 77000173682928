import React, {FC } from 'react';
import {IVideoEmbed} from "../../modules/rest";
import VideosListItem from "./VideosListItem";
import {useTranslation} from "react-i18next";

interface Props {
  videos: IVideoEmbed[];
  fetch: () => void;
  activeVideo?: number|null;
  onChangeActiveVideo: (id: number|null) => void;
}

const VideosList: FC<Props> = ({videos,  fetch, activeVideo, onChangeActiveVideo}) => {
  const {t} = useTranslation();

  return (
    <div className='video-list'>
      {videos.map(ve => (
        <VideosListItem
          activeVideo={activeVideo}
          fetch={fetch}
          key={ve.id}
          ve={ve}
          onChangeActiveVideo={onChangeActiveVideo}
        />
      ))}
    </div>
  );
}

export default VideosList;