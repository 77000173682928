import React, {FC} from 'react';
import Loadable from "../../components/Loadable";
import logo from "../../assets/images/logo.png";

interface Props {
  loading: boolean;
  onSubmit: (e: any) => void;
  children: any;
}

const AuthWrap: FC<Props> = ({loading, onSubmit, children}) => {
  return (
    <Loadable loading={loading}>
      <div className="d-flex justify-content-center" style={{marginTop: 100}}>
        <form onSubmit={onSubmit} className="auth-form bg-gradient">
          <div className='auth-form-content'>
            <div className='text-center mb-4 pb-3'>
              <img src={logo} alt="logo"  className='auth-form-logo'/>
            </div>
            {children}
          </div>
        </form>
      </div>
    </Loadable>
  );
}

export default AuthWrap;