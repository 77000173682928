import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import Header from "../components/Header";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import VideosPage from "./videos";
import ReportPage from "./report/ReportPage";
import BillingPage from "./billing";
import HelpPage from "./help";
import ReferralsPage from "./referrals";
import {API} from "../modules/api";
import {IReferralRecord} from "../modules/rest";
import ApiKeyPage from "./ApiKeyPage";
import Aside from "../components/Aside";
import DashboardPage from "./dashboard";
import TrendsPage from "./trends";
import NoveltiesPage from "./novelties";
import ContentPlanPage from "./contentPlan";
import CommentsPage from "./comments";
import NoMonetizationPage from "./noMonetization";
import session from "../modules/session";
import {observer} from "mobx-react";
import fire from '../components/ContentType/assets/images/fire_white.svg';
import star from '../components/ContentType/assets/images/star_white.svg';
import question from '../components/ContentType/assets/images/question.svg';
import content_plan from '../assets/images/icons/content_plan.svg';
import dashboard from '../assets/images/icons/dashboard.svg';

import my_videos from '../assets/images/icons/my_videos.svg';
import reports from '../assets/images/icons/reports.svg';
import help from '../assets/images/icons/help.svg';
import billing from '../assets/images/icons/billing.svg';
import partners from '../assets/images/icons/partners.svg';

export const monetizationSectionPath = '/monetization/videos'

export const mainMenu = [
  {path: '/', title: 'DASHBOARD', icon: dashboard},
  {path: '/trending', title: 'TRENDS', icon: fire},
  {path: '/new-videos', title: 'NOVELTIES', icon: star},
  {path: '/comments', title: 'COMMENTS_', icon: question},
  {path: '/content-plan', title: 'CONTENT_PLAN', icon: content_plan},

]

export const monetizationMenu = [
  {path: monetizationSectionPath, title: 'MY_VIDEOS', icon: my_videos},
  {path: '/monetization/reports', title: 'REPORTS', icon: reports},
  {path: '/monetization/billing', title: 'BILLING', icon: billing},
  {path: '/monetization/help', title: 'HELP', icon: help},
  {path: '/monetization/referrals', title: 'PARTNERS', icon: partners},
]



export type TSection = 'scanner' | 'monetization'

interface Props {
}

const AppRouter: FC<Props> = observer(() => {
  const location = useLocation();
  const [referrals, setReferrals] = useState<IReferralRecord[]>();
  const [menuVisible, setMenuVisible] = useState(false);
  const [sectionName, setSectionName] = useState<TSection>();

  useEffect(() => {
    API.Referrals.getList().then(setReferrals);
  }, []);

  useLayoutEffect(() => {
    let section: TSection = 'scanner';
    const pathname = location.pathname;
    monetizationMenu.forEach(item => {
      if (pathname.includes(item.path)) section = 'monetization';
    })
    setSectionName(section);
  }, [location]);

  useEffect(() => {
    document.querySelector('body')?.classList[menuVisible ? 'add' : 'remove']('menuVisible')
  }, [menuVisible]);

  useEffect(() => {
    if (session.ready) {
      if (!session.channels?.length) {
        session.composeChannel(false);
      } else if (!session.activeChannel?.countries?.length || !session.activeChannel?.categories?.length) {
        session.composeChannel(false, session?.activeChannel);
      }
    }
  },[session?.activeChannel?.id]);

  return (
    <>
      <Header
        sectionName={sectionName}
        openMenu={() => setMenuVisible(true)}/>
      <main>
        <Routes>
          <Route path='/' element={<DashboardPage/>}/>
          <Route path={'/trending'} element={<TrendsPage/>}/>
          <Route path={'/new-videos'} element={<NoveltiesPage/>}/>
          <Route path={'/comments'} element={<CommentsPage/>}/>
          <Route path={'/content-plan/*'} element={<ContentPlanPage/>}/>
          <Route path={'/monetization/*'}>
            {!session.user?.flagYoutuberMonetizationEnabled
              ?
              <Route path={'*'} element={<NoMonetizationPage/>}/>
              :
              <>
                <Route path='videos' element={<VideosPage/>}/>
                <Route path='reports' element={<ReportPage/>}/>
                <Route path='billing' element={<BillingPage/>}/>
                <Route path='help' element={<HelpPage/>}/>
                <Route path={'help/:id'} element={<HelpPage/>}/>
                <Route path={'referrals'} element={<ReferralsPage setReferrals={setReferrals} referrals={referrals}/>}/>
              </>
            }
          </Route>
          <Route path={'/apikey'} element={<ApiKeyPage/>}/>
          <Route path='*' element={<Navigate to='/' replace/>}/>
        </Routes>
      </main>
      <Aside
        sectionName={sectionName}
        referrals={referrals}
        visible={menuVisible}
        closeMenu={() => setMenuVisible(false)}
      />
    </>
  );
})

export default AppRouter;