import React, {FC, useEffect, useRef, useState} from 'react';
import {ECountry, EReviewStatus, IVideoEmbed, IVideoEmbedViewsHistory} from "../../modules/rest";
import {download, getEmbedName} from "../../modules/utils";
import plus_svg from '../../assets/images/icons/add_white.svg';
import font_svg from '../../assets/images/icons/font.svg';
import edit_svg from '../../assets/images/icons/edit.svg';
import download_svg from '../../assets/images/icons/download.svg';
import more_svg from '../../assets/images/icons/more_white.svg';
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";
import {openHistoryViewsVideoModal, openTracksVideosModal, openVideoMoreModal} from "../../modals";
import {addVideoModal} from "../../modals/videos/AddVideoToTrackModal";
import Video from "../../components/Video";
import {confirmDialog} from "../../modules/confirm";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import trash_svg from "../../assets/images/icons/trash.svg";
import Loadable from "../../components/Loadable";
import langs from '../../translates/en/langs.json';
import CloseBtn from "../../components/CloseBtn";
import {openDownloadVideoModal} from "../../modals/videos/DownloadVideoModal";
import VideosListItemMonetizedCountries from "./VideosListItemMonetizedCountries";
import MonetizationHistoryGraph from "../../components/Aside/AsideMonetization/MonetizationHistoryGraph";

interface Props {
  ve: IVideoEmbed;
  onChangeActiveVideo: (id: number|null) => void;
  fetch: () => void;
  activeVideo?: number|null;
}


const VideosListItem: FC<Props> = ({
                                     ve,
                                     activeVideo,
                                     onChangeActiveVideo,
                                     fetch,
                                   }) => {
  const videoRef: any = useRef(null);
  const _nameInput: any = useRef(null);
  const {t} = useTranslation();
  const [history, setHistory] = useState<IVideoEmbedViewsHistory&{ max: number }>();
  const [loading, setLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [itemHover, setItemHover] = useState(false);

  const active = ve.id === activeVideo;

  useEffect(() => {
    getHistory()
  }, []);

  const getHistory = async () => {
    try {
      if (ve.status !== EReviewStatus.Approve) return;
      const res = await API.Stats.getVideoEmbedViewsHistory(ve.id);
      const max = Math.max(...res.history.map((item) => item.value));
      setHistory({...res, max});
    } catch (e) {
    }
  }

  const handleOpenVideosModal = () => {
    onChangeActiveVideo(null);
    openTracksVideosModal(ve).then(() => fetch());
  }


  const handleDownload = (isModal?: boolean) => (e: any) => {
    e.stopPropagation();
    if (isModal) {
      openDownloadVideoModal(ve).then()
    } else {
      download(ve.video!, ve.video?.name)
    }
  }

  const handleOpenAddVideoModal = (e: any) => {
    e.stopPropagation();
    onChangeActiveVideo(null);
    addVideoModal(ve).then((res) => {
      if (res) fetch()
    });
  }

  const handleRemoveRejectedVideo = () => {
    confirmDialog(t('DELETE_CLIP_CONFIRM'), {
      title: t('DELETING_CLIP') || '',
      danger: true,
      confirmText: t('REMOVE') || '',
    }).then(async (confirmed) => {
      if (!confirmed) return;
      setLoading(true)
      try {
        await API.VideoEmbeds.deleteVideoEmbed(ve.id);
        toast.success(t('CLIP_REMOVED'));
        fetch();
      } catch (e: any) {
        toast.error(e);
      } finally {
        setLoading(false);
      }
    });
  }

  const handleSubmitName = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    const name = _nameInput.current?.value;
    try {
      setLoading(true);
      await API.VideoEmbeds.renameVideoEmbed(ve.id, {name});
      await fetch();
      setEditOpen(false)
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }
  }

  const handleMoreClick = (e: any) => {
    e.stopPropagation();
    openVideoMoreModal(ve, history).then((needUpdate) => {
      if (needUpdate) fetch();
    });
  }

  //render
  if (ve.status === EReviewStatus.Review) return (
    <div className='tracks-list-item-wrap'>
      <div className='text-muted-14 tracks-list-item-id'>{ve.id}</div>
      <div className='tracks-list-item pending'>
        <div className="tracks-list-item-video"/>
        <div className='text-muted-14 text-pre-wrap tracks-list-item-right'>{t('VIDEO_PENDING')}</div>
      </div>
    </div>
  )

  if (ve.status === EReviewStatus.Reject) return (
    <div className='tracks-list-item-wrap'>
      <div className='text-muted-14 tracks-list-item-id'>{ve.id}</div>

      <Loadable loading={loading} className='tracks-list-item reject'>
        <div className="tracks-list-item-video"/>
        <div className='tracks-list-item-delete'>
          <div className='text-danger text-wrap'>{ve.rejectReason}</div>
          <button className='btn btn-danger btn-xs ps-2  mt-3' onClick={handleRemoveRejectedVideo}>
            <ReactSVG src={trash_svg} className='react-icon'/>
            <span className='ps-1 text-danger'>{t('REMOVE')}</span>
          </button>
        </div>

      </Loadable>
    </div>
  )

  return (
    <div className='tracks-list-item-wrap'
         onMouseEnter={() => setItemHover(true)}
         onMouseLeave={() => setItemHover(false)}
    >
      <div className='text-muted-14 tracks-list-item-id'>{ve.id}</div>
      <div className={`tracks-list-item${active ? ' active' : ''}`} onClick={handleOpenVideosModal}>
        <div className="tracks-list-item-video" onClick={(e) => e.stopPropagation()}>
          <Video
            active={active}
            onChangeActiveVideo={() => onChangeActiveVideo(ve.id)}
            ref={videoRef}
            className='catalog-list-item-video'
            ve={ve}
          />
          {ve.language ?
            <div className="tracks-list-item-language">
              <div className="tracks-list-item-language-ln">{ve.language}</div>
              <div className="tracks-list-item-language-lang">{langs[ve.language!]}</div>
            </div>
            :
            null
          }
        </div>
        <div className="tracks-list-item-right">
          <div className='tracks-list-item-title'>
            {editOpen
              ?
              <form onSubmit={handleSubmitName}>
                <Loadable loading={loading}>
                  <div className='tracks-list-item-title-edit' onClick={(e) => e.stopPropagation()}>
                    <input type="text" defaultValue={getEmbedName(ve)} ref={_nameInput}/>
                    <button type='submit' className='btn btn-primary btn-lg'>{t('DONE')}</button>
                    <CloseBtn onClick={() => setEditOpen(false)}/>
                  </div>
                </Loadable>
              </form>
              :
              <>
                <div className='d-grid overflow-hidden'>
                  <h4 className='text-truncate'>{getEmbedName(ve)}</h4>
                </div>
                <ReactSVG src={edit_svg} className='react-icon ms-2 tracks-list-item-title-edit-icon' onClick={(e) => {
                  e.stopPropagation();
                  setEditOpen(true);
                }}/>
              </>
            }

          </div>
          <div className='d-flex align-items-center mt-1 tracks-list-item-videos-count'>
            <div className='text-lowercase text-muted-14 text-nowrap'>{ve.videoCount} {t('VIDEO')}</div>
            <VideosListItemMonetizedCountries
              itemHover={itemHover}
              countries={[ECountry.US, ECountry.RU]}
              className='ms-2 tracks-list-item-hidden'
            />
          </div>
          <div className='tracks-list-item-controls-small'>
            <ReactSVG src={download_svg} className='react-icon' onClick={handleDownload()}/>
            <ReactSVG src={more_svg} className='react-icon' onClick={handleMoreClick}/>
          </div>

          <div className='tracks-list-item-controls'>
            <button type='button' className='btn btn-xs btn-third ps-2 me-1 mt-1' onClick={handleDownload()}>
              <ReactSVG src={download_svg} className='react-icon me-1'/>
              {t('DOWNLOAD')}
            </button>
            <button type='button' className='btn btn-xs btn-third ps-2 me-1 mt-1 tracks-list-item-hidden'
                    onClick={handleOpenAddVideoModal}>
              <ReactSVG src={plus_svg} className='react-icon me-1'/>
              {t('ADD_LINKS')}
            </button>
            <button type='button' className='btn btn-xs btn-third ps-2 mt-1 me-1 tracks-list-item-hidden'
                    onClick={handleDownload(true)}>
              <ReactSVG src={font_svg} className='react-icon me-1'/>
              {t('GET_TEXT')}
            </button>
            <button type='button'
                    className='btn btn-xs btn-third  mt-1 tracks-list-item-views-btn tracks-list-item-hidden'
                    onClick={(e) => {
                      e.stopPropagation();
                      openHistoryViewsVideoModal(history)
                    }}>
              {t('VIEWS_HISTORY')}
            </button>
          </div>

          {/*<div className='d-flex align-items-center flex-grow-1 justify-content-end'>*/}
          {/*  <ReactSVG src={views_svg} className='react-icon'/>*/}
          {/*  <span className='ps-2 text-muted-14'>{formatViews(ve.views!)}</span>*/}
          {/*</div>*/}
        </div>
        <MonetizationHistoryGraph history={history} className='video-list-item-history'/>
      </div>
    </div>
  );
}

export default VideosListItem;