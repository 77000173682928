import React, {FC} from 'react';
import {EContentPlanVideoOrigin, ITrendingItem} from "../../../modules/rest";
import {ReactSVG} from "react-svg";
import plan_svg from '../assets/images/plan.svg';
import {useTranslation} from "react-i18next";
import ContentTypeItemImg from "../ContentTypeItemImg";
import {openBestReactionsModal} from "../../../modals";
import { smallestScreen, thumb} from "../../../modules/utils";
import ListItemTrendsTag from "./ListItemTrendsTag";
import ContentTypeListItemViews from "../ContentTypeListItemViews";


interface Props {
  item: ITrendingItem;
  idx: number;
  onAddToContentPlan: (item: ITrendingItem) => () => void;
}

const ListItemTrends: FC<Props> = ({item, onAddToContentPlan, idx}) => {
  const {t} = useTranslation();
  return (
    <div className={`content-type-item${item.isPlanned ? ' planned' : ''}`}
         onClick={smallestScreen() ? onAddToContentPlan(item) : undefined}
    >
      <div className='content-type-item-id'>{idx}</div>
      <ContentTypeItemImg image={thumb(item.video?.preview?.id!, 180)} url={item.video?.url}/>
      <div className='content-type-item-info'>
        <div className='d-grid overflow-hidden'>
          <div className='text-medium-14 text-truncate text-muted'>{item.video?.title}</div>
        </div>
        <ContentTypeListItemViews item={item} />
      </div>
      <div className='content-type-item-right'>
        <div
          className="content-type-item-best-reaction text-tint-active"
          onClick={() => openBestReactionsModal(item, EContentPlanVideoOrigin.Trending)}
        >
          {t('BEST_REACTION')}
        </div>
        <div className="content-type-item-hidden">
          <button type='button' className='btn btn-outline-primary btn-xs'
                  onClick={onAddToContentPlan(item)}>
            {t('ADD_TO_CONTENT_PLAN')}
          </button>
        </div>
        {item.isPlanned &&
          <div className="content-type-item-planned">
            <ReactSVG src={plan_svg} className='react-icon content-type-item-planned-icon'/>
            <div className='text-muted-14 content-type-item-planned-text'>
              <span>{t('IN_CONTENT_PLAN')}</span>
            </div>
          </div>
        }
        <div className="content-type-tags-small">
          {item.countries?.length > 1 &&
          <div className={`custom-tag small my-1 px-2`}>
            +{item.countries?.length - 1}
          </div>
          }
          <ListItemTrendsTag
            country={item.countries[0]}
            positions={item.positions[item.countries[0]]}  />
        </div>
        <div className='content-type-tags'>
          {item.countries?.map(country => (
            <ListItemTrendsTag
              country={country}
              positions={item.positions[country]}
              key={country}
            />
          ))}
        </div>

      </div>
    </div>
  );
}

export default ListItemTrends;