import React, {FC, useEffect} from 'react';
import {
  EContentPlanVideoOrigin,
  EFieldGroup,
  IGetMonitoringChannelsRequest,
  IMonitoringVideo,
  IPagedData, ITrendingItem,
} from "../../../modules/rest";
import {API} from "../../../modules/api";
import {openComposeChannelToContentPlanModal} from "../../../modals";
import {observer, useLocalObservable} from "mobx-react";
import {runInAction} from "mobx";
import session from "../../../modules/session";
import ListItemNovelties from "./ListItemNovelties";
import ContentTypeWrapper from "../ContentTypeWrapper";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import empty_list from "../assets/images/empty_novelties.svg";


interface Props {
  onChangeActiveContentType?: () => void;
  updateContentPlan?: () => void;
  className?: string;
  active?: boolean;
  maxTagsLength?: number;
  seeAll?: boolean;
  onHeaderClick?: () => void;
}

interface State {
  request: IGetMonitoringChannelsRequest;
  pager?: IPagedData<IMonitoringVideo>;
  ready: boolean;
  loading: boolean;
}

const ContentTypeNovelties: FC<Props> = observer(({
                                                    className,
                                                    active,
                                                    onChangeActiveContentType,
                                                    updateContentPlan,
                                                    maxTagsLength,
                                                    seeAll,
                                                    onHeaderClick,
                                                  }) => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    ready: false,
    loading: false,
    request: {
      search: '',
      page: 1,
      limit: 10,
    },
  }));


  const channel = session.activeChannel;

  useEffect(() => {
    if (channel?.id) {
      runInAction(() => {
        st.request.page = 1;
      })
      fetch();
    }
  }, [channel?.id, channel?.categories]);

  const fetch = async () => {
    try {
      runInAction(() => {
        st.loading = true;
      })
      const res = await API.ReactorChannels.getNewVideos(channel?.id!, st.request, [EFieldGroup.MonitoringChannel, EFieldGroup.MonitoringCategories]);
      runInAction(() => {
        st.pager = res;
      })
    } catch (e: any) {
    } finally {
      runInAction(() => {
        st.loading = false;
        st.ready = true;
      })
    }
  }

  const handleAddToContentPlan = (item: IMonitoringVideo) => () => {
    openComposeChannelToContentPlanModal(item, EContentPlanVideoOrigin.NewVideos).then((res) => {
      if (res && updateContentPlan) {
        updateContentPlan();
        fetch();
      }
    })
  }

  return (
    <ContentTypeWrapper
      seeAll={seeAll}
      onHeaderClick={onHeaderClick}
      maxTagsLength={maxTagsLength}
      active={active}
      className={className}
      type={EContentPlanVideoOrigin.NewVideos}
      onChangeActiveContentType={onChangeActiveContentType}
      loading={st.loading || !st.ready}
      onPageChange={(page: number) => runInAction(() => {
        st.request.page = page;
        fetch();
      })}
      onLimitChange={(limit: number) => runInAction(() => {
        st.request.page = 1;
        st.request.limit = limit;
        fetch();
      })}
      pager={st.pager}
    >
      {st.pager?.data?.length
        ?
        st.pager.data.map((item, i) => (
          <ListItemNovelties
            idx={(st.pager?.page! - 1) * st.pager?.limit! + i + 1}
            key={item.id}
            item={item}
            onAddToContentPlan={handleAddToContentPlan}
          />
        ))
        :
        st.ready
          ?
          <div className='content-type-empty-list'>
            <ReactSVG src={empty_list} className='react-icon' />
            <div className='text-muted-14 mt-2'>{t('EMPTY_LIST_NOVELTIES')}</div>
          </div>
          :
          null
      }
    </ContentTypeWrapper>
  );
})

export default ContentTypeNovelties;