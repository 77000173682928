import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {EContentPlanVideoOrigin} from "../../modules/rest";

interface Props {
  type: EContentPlanVideoOrigin
}

const TrendsAsideHead: FC<Props> = ({type}) => {
  const {t} = useTranslation();

  return (
    <div className={`trends-aside-head ${type}`}>
      <h4>{t(`ASIDE_TITLE_${type}`)}</h4>
      <div className='mt-1 text-muted-14'>{t(`ASIDE_TEXT_${type}`)}</div>
    </div>
  );
}

export default TrendsAsideHead;