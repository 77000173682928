import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react';

import session from './modules/session';
import Loader from './components/Loader';
import {Login, Password} from './routes/auth';
import AppRouter from "./routes/AppRouter";

const App = observer(() => {
  if (!session.ready) return (
    <div className='app p-5 d-flex justify-content-center'>
      <Loader text='SESSION_RESTORE'/>
    </div>
  );
  if (!session.user) return (
    <Routes>
      <Route path='/login' element={<Login/>}/>
      <Route path='/password' element={<Password/>}/>
      <Route path='*' element={<Navigate to='/login' replace/>}/>
    </Routes>
  )
  return <AppRouter/>
});

export default App;
